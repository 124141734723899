import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const ChevronDown = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='m9 9.586 3 3 3-3L16.414 11l-3.707 3.707a1 1 0 0 1-1.414 0L7.586 11 9 9.586Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'chevron-down', icon: ChevronDown } as const;
