import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { IconProps } from '../types';
import { useSvgProps } from '../hooks/useSvgProps';

const Chicken = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M8.062 5.835c.421-.28.73-.69.954-1.023.118-.175.232-.358.336-.526l.024-.04c.098-.158.188-.304.28-.445.451-.688.686-.798.852-.798.353 0 .525.11.839.44.048.05.102.11.162.177.323.36.82.911 1.551 1.277.53.264 1.073.205 1.448.122a7.38 7.38 0 0 0 .997-.326l.069-.026c.331-.126.573-.213.778-.252a.586.586 0 0 1 .214-.015c.312.157.484.356.568.579.087.231.072.929-.127 1.524.167.166.334.277.5.388.334.223.667.445 1 1.112.302-.906.788-2.958.5-3.727-.292-.776-.868-1.326-1.552-1.668-.528-.264-1.069-.236-1.478-.158-.406.078-.809.231-1.116.348l-.011.004c-.34.13-.574.22-.777.264a.83.83 0 0 1-.155.023c-.344-.18-.55-.404-.85-.733-.082-.089-.171-.186-.272-.292-.48-.504-1.142-1.061-2.288-1.061-1.335 0-2.1 1.055-2.524 1.7a20.7 20.7 0 0 0-.308.49l-.026.042c-.105.17-.199.32-.292.458-.186.276-.311.41-.391.468a.856.856 0 0 1-.253-.004 5.385 5.385 0 0 1-.56-.103c-.129-.028-.276-.06-.456-.096-.395-.076-.929-.158-1.47-.055-.597.113-1.147.437-1.552 1.045-.537.805-.478 1.767-.31 2.454.17.691 1.226 2.185 1.641 2.6.151-.392.37-.737.566-1.043.235-.37.434-.683.434-.956-.084-.085-.588-.626-.698-1.077-.112-.456-.053-.744.03-.869.096-.142.17-.172.26-.19.147-.027.363-.014.718.055l.279.058c.271.06.61.133.892.165.42.047 1.026.055 1.574-.31Zm3.446.668c-2.223 0-3.858 1.433-4.992 2.968-1.145 1.549-1.934 3.403-2.437 4.66a66.147 66.147 0 0 0-1.088 2.977l-.24.69a64.124 64.124 0 0 1-1.663 4.31 1 1 0 0 0 1.839.788 66.138 66.138 0 0 0 1.712-4.438l.247-.71c.34-.98.654-1.885 1.05-2.874.497-1.243 1.208-2.889 2.188-4.215.991-1.34 2.106-2.156 3.384-2.156 1.906 0 3.14.794 3.744 1.447-1.153 1.648-1.184 3.848-.431 5.472-1.482 2.124-2.15 4.747-2.31 7.01a1 1 0 0 0 1.994.142c.151-2.115.793-4.452 2.108-6.22l5.71-1.903a1 1 0 0 0 .221-1.792l-5.288-3.365c-.799-1.306-2.793-2.791-5.748-2.791ZM16 15c-.756-1-.602-3.08.154-4.34l4.353 2.842L16 15Zm-5-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'chicken', icon: Chicken } as const;
