import { Silo } from '@/warehouse/entities/Silo';
import { useSiloPanel } from '@/warehouse/store/useSiloPanelStore';
import { useNavigation } from '@react-navigation/native';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';

export function useSiloReportNavigation() {
    const navigation = useNavigation();
    const isLargeLayout = useLargeLayout();
    const openReportTab = useSiloPanel((store) => store.onReportTabRequested);

    function openReportPage(silo?: Pick<Silo, 'id' | 'name'>) {
        if (isLargeLayout) {
            return openReportTab(silo?.id);
        }

        navigation.navigate('WarehouseReport', { silo });
    }

    return { openReportPage };
}
