import { useStatusBar } from '@/shared/hooks/useStatusBar';
import SiloFollowUp from '@/warehouse/components/SiloDetails/SiloFollowUp';
import { useSiloById } from '@/warehouse/hooks/silos/useSiloById';
import { Stack } from '@alltis/ui';
import { RouteProp, useRoute } from '@react-navigation/native';
import React from 'react';
import { ScrollView, Separator } from 'tamagui';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';
import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { useSheetCheck } from '@/alltis-ui-v2/components/Sheet/useSheetCheck';

const SiloDetails = () => {
    useStatusBar('light-content');

    const { params } = useRoute<RouteProp<UserStackParams, 'SiloDetails'>>();

    const { data: silo } = useSiloById(params?.id);

    const isSheet = useSheetCheck();

    const ScrollComponent = isSheet ? BottomSheetScrollView : ScrollView;

    if (!silo) return <></>;

    return (
        <Stack flex={1}>
            <ScrollComponent
                style={{ flex: 1 }}
                contentContainerStyle={{
                    padding: 24,
                }}
            >
                <SiloFollowUp silo={silo} />

                <Separator my={22} />

                <SiloFollowUp silo={silo} />

                <SafeBottom />
            </ScrollComponent>
        </Stack>
    );
};

export default SiloDetails;
