import { AuthenticationModule } from '@legacy/authentication/AuthenticationModule';
import { CameraVideoModule } from '@legacy/camera-video/CameraVideoModule';
import { ChickenHouseModule } from '@legacy/chicken-house/ChickenHouseModule';
import { WarehouseModule } from '@legacy/warehouse/WarehouseModule';
import { Container } from 'inversify';

export const container = new Container();

const modules = [CameraVideoModule, ChickenHouseModule, AuthenticationModule, WarehouseModule];

modules.forEach((module) => new module().bindDependencies(container));
