import { formatBigNumber } from '@/shared/utils/number';
import { Stack, Text } from '@alltis/ui';
import React from 'react';

type Size = 'sm' | 'md' | 'lg';

export type PageListCardInfoProps = {
    title: string;
    quantity: {
        value: number;
        suffix?: string;
    };
    caption?: string;
    color?: string;
    size?: Size;
    titleSpacing?: number;
};

const PageListCardInfo = ({
    title,
    quantity,
    caption,
    color = '#376A7B',
    size = 'sm',
    titleSpacing = 8,
}: PageListCardInfoProps) => {
    const sizeOptions = {
        sm: {
            titleSize: 12,
            quantitySize: 20,
            suffixSize: 16,
            captionSize: 14,
        },
        md: {
            titleSize: 14,
            quantitySize: 24,
            suffixSize: 20,
            captionSize: 16,
        },
        lg: {
            titleSize: 16,
            quantitySize: 28,
            suffixSize: 24,
            captionSize: 18,
        },
    };

    const { titleSize, quantitySize, captionSize, suffixSize } = sizeOptions[size];

    return (
        <Stack>
            <Text color='#8CA9B3' fontSize={titleSize} fontWeight={500} numberOfLines={1} overflow='visible'>
                {title}
            </Text>

            <Stack flexDirection='row' mt={titleSpacing}>
                <Text color={color} fontSize={quantitySize} fontWeight={700}>
                    {formatBigNumber(quantity.value)}
                </Text>

                <Text color={color} fontWeight={700} fontSize={suffixSize} mb={0.5} ml={5} alignSelf='flex-end'>
                    {quantity.suffix}
                </Text>
            </Stack>

            {caption && (
                <Text color={color} fontSize={captionSize} fontWeight={600} mt={4} opacity={0.6}>
                    {caption}
                </Text>
            )}
        </Stack>
    );
};

export default PageListCardInfo;
