import HeavyScreen from '@/shared/components/ui/HeavyScreen';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import PlaceInformation from '@legacy/chicken-house/components/PlaceInformation';
import PlaceRecordings from '@legacy/chicken-house/components/PlaceRecordings';
import TabBar from '@legacy/shared/components/ui/TabBar';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import React, { useCallback, useMemo } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { SceneMap, TabView } from 'react-native-tab-view';
import { Stack } from 'tamagui';
import useBus from 'use-bus';
import EggsReport from '../EggsReport';
import { useEggTabs } from './hooks/useEggTabs';

const PlaceEggs = () => {
    const { width } = useWindowDimensions();

    useStatusBar('light-content', '#171923');

    const renderScene = useMemo(() => {
        return SceneMap({
            eggs: () => <PlaceInformation />,
            recordings: () => <PlaceRecordings />,
            reports: () => <EggsReport />,
        });
    }, []);

    const { routes: routes, index, onIndexChange } = useEggTabs();

    useBus('@record/finish', () => onIndexChange(1), []);

    const getIcon = useCallback((key: string, color: string) => {
        return (
            <Stack mb={8}>
                <FontAwesome5 name={key === 'eggs' ? 'egg' : 'video'} color={color} size={16} />
            </Stack>
        );
    }, []);

    return (
        <Stack flex={1}>
            <HeavyScreen placeholder={null}>
                <TabView
                    navigationState={{
                        index,
                        routes,
                    }}
                    renderScene={renderScene}
                    onIndexChange={onIndexChange}
                    initialLayout={{ width: initialWindowMetrics?.frame.width || width, height: 69 }}
                    renderTabBar={(props) => (
                        <TabBar {...props} renderIcon={({ route, color }) => getIcon(route.key, color)} colorMode='dark' />
                    )}
                    swipeEnabled={Platform.OS !== 'web'}
                />
            </HeavyScreen>
        </Stack>
    );
};

export default PlaceEggs;
