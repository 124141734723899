import SiloFigure from '@/warehouse/components/SiloFigure';
import { Silo } from '@/warehouse/entities/Silo';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import { Stack, Text } from '@alltis/ui';
import React from 'react';

type Props = {
    silo: Silo;
};

const SiloWithInfo = ({ silo }: Props) => {
    const quantityUnits = useQuantityUnits(true, { hideSuffix: true });

    return (
        <Stack mb={6} flexDirection='row'>
            <SiloFigure silo={silo} />

            <Stack py={10} ml={12}>
                <Text color='#376A7B' fontWeight={500} fontSize={12} flex={1} width={100}>
                    {silo.name}
                </Text>

                <Stack flex={1}>
                    <Text color='#376A7B' fontSize={32} fontWeight={700}>
                        {quantityUnits.format({
                            perc: silo?.level.current.percentage,
                            tons: silo?.level.current.tons,
                        })}
                    </Text>
                    <Text color='#376A7B' fontSize={12} fontWeight={500}>
                        toneladas
                    </Text>
                </Stack>

                <Text color='#8CA9B3' fontSize={14} fontWeight={600}>
                    {silo.temperature} °C
                </Text>
            </Stack>
        </Stack>
    );
};

export default SiloWithInfo;
