import { minBuildVersion } from '@legacy/shared/utils/version';
import RNCheckbox, { CheckBoxProps } from '@react-native-community/checkbox';
import React from 'react';
import { Checkbox as TCheckbox, useTheme } from 'tamagui';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { Platform } from 'react-native';

type Props = CheckBoxProps;

const Checkbox = (props: Props) => {
    const { primary500 } = useTheme();

    if (!minBuildVersion('1.21.0') || Platform.OS === 'ios' || Platform.OS === 'web') {
        return (
            <TCheckbox checked={props.value}>
                <TCheckbox.Indicator>
                    <FontAwesome5 name='check' color={primary500.get()} size={10} />
                </TCheckbox.Indicator>
            </TCheckbox>
        );
    }

    return (
        <RNCheckbox
            {...props}
            tintColors={{
                true: primary500.get() as string,
            }}
        />
    );
};

export default Checkbox;
