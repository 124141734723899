import { ServerConfigRepository } from '@legacy/chicken-house/db/server-config/ServerConfigRepository';
import * as NetInfo from '@react-native-community/netinfo';
import { useCallback } from 'react';
import { container } from '../../../../config/di';
import { CameraServerNetwork } from '@legacy/chicken-house/entities/CameraServerNetwork';

export function useCurrentServerConfig() {
    const serverConfigRepository = container.get(ServerConfigRepository);

    const getCurrentConfig = useCallback(async (): Promise<CameraServerNetwork | null> => {
        const network = await NetInfo.fetch();

        if (network.type === NetInfo.NetInfoStateType.wifi && network.details.bssid) {
            const savedConfig = await serverConfigRepository.findByBSSID(network.details.bssid);

            if (!savedConfig) return null;

            return {
                ip: savedConfig.ip,
                port: savedConfig.port,
            };
        }

        return null;
    }, []);

    return { getCurrentConfig };
}
