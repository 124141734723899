import { formatChartValue } from '@/shared/utils/charts';
import { toFixedOrNot } from '@/shared/utils/number';
import React, { ReactElement, useMemo } from 'react';
import { Stack, Text } from 'tamagui';
import { generateIndicatorNumers } from './helpers';

type Props = {
    title: string;
    unit: string;
    value: number;
    range: number[];
    expectedRange: number[];
    icon: ReactElement;
    isDanger?: boolean;
    decimals?: number;
};

type RangeIndicator = {
    position: string;
    value: number;
};

const BAR_WIDTH = 18;

const VerticalBarIndicator = ({ isDanger, ...props }: Props) => {
    const getPercentage = () => {
        return (props.value * 100) / props.range[1];
    };

    const rangeIndicators = useMemo(() => {
        const numbers = generateIndicatorNumers(props.range[1]);

        const indicators: RangeIndicator[] = numbers.map((value) => ({
            position: (value * 100) / props.range[1] + '%',
            value,
        }));

        return indicators;
    }, [props.range]);

    const expectedRangeBar = useMemo(() => {
        const start = (props.expectedRange[0] * 100) / props.range[1];
        const end = (props.expectedRange[1] * 100) / props.range[1];

        return {
            bottom: start + '%',
            height: end - start + '%',
        };
    }, [props.range, props.expectedRange]);

    return (
        <Stack>
            <Stack gap='$1.5' flexDirection='row' mb='$4' alignItems='center'>
                <Text color={isDanger ? '#A34742' : '#376A7B'} fontSize={12}>
                    {props.title}
                </Text>
            </Stack>

            <Stack alignSelf='flex-start' ml={25}>
                <Stack flexDirection='row'>
                    <Stack>
                        <Stack
                            width={BAR_WIDTH}
                            height={140}
                            backgroundColor='transparent'
                            borderWidth={1.5}
                            borderColor={isDanger ? '#CA9592' : '#8CA9B3'}
                            justifyContent='flex-end'
                            alignItems='center'
                            overflow='hidden'
                            br={4}
                        >
                            <Stack
                                width='100%'
                                bg='#DCEBC5'
                                position='absolute'
                                bottom={expectedRangeBar.bottom}
                                height={expectedRangeBar.height}
                            />

                            <Stack
                                width='85%'
                                transform={[{ translateY: 4 }]}
                                height={4}
                                br={1.5}
                                bg={isDanger ? '#8C1913' : '#05455A'}
                                position='absolute'
                                bottom={getPercentage() + '%'}
                            />
                        </Stack>
                    </Stack>

                    {rangeIndicators.map((indicator, index) => (
                        <Stack
                            position='absolute'
                            bottom={indicator.position}
                            key={indicator.position}
                            flexDirection='row'
                            transform={[{ translateX: -3 }]}
                        >
                            <Text
                                position='absolute'
                                color='#B2C5CC'
                                fontSize={12}
                                bottom={index === 0 ? -5 : -9}
                                right={0}
                                textAlign='right'
                                whiteSpace='nowrap'
                            >
                                {formatChartValue(indicator.value, { abbr: true })} -
                            </Text>
                        </Stack>
                    ))}

                    <Stack
                        ml={8}
                        position='absolute'
                        left={BAR_WIDTH}
                        //  bottom={getPercentage() - 6 + '%'}
                        bottom='20%'
                    >
                        <Text color={isDanger ? '#8C1913' : '#376A7B'} fontWeight={700} fontSize={14} alignSelf='flex-start'>
                            {toFixedOrNot(props.value, props.decimals ?? 1).replace('.', ',')}
                        </Text>

                        <Text color={isDanger ? '#8C1913' : '#376A7B'} fontWeight={500} fontSize={12} alignSelf='flex-start'>
                            {props.unit}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default VerticalBarIndicator;
