import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Password = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 20 10'>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M0.932901 4.41643C0.634113 4.76191 0.587436 5.24743 0.923568 5.58356L3.18313 7.84313C3.50993 8.16993 4.02347 8.15127 4.34093 7.8338L5.39601 6.77871L6.97399 8.35668C7.29144 8.67415 7.80499 8.6648 8.13179 8.34735L9.56036 6.92811C10.5501 8.8889 12.2027 10 14.1449 10C16.9086 10 19.1309 7.75911 19.1309 4.99534C19.1309 2.22222 16.918 -9.67287e-08 14.1449 -2.17945e-07C12.1747 -3.04061e-07 10.4007 1.12045 9.58838 2.90384L2.72562 2.90384C2.45484 2.90384 2.14672 2.98786 1.95064 3.22129L0.932901 4.41643ZM2.24009 4.99534L2.98706 4.24837L10.5501 4.24837C10.9236 2.53034 12.3988 1.33519 14.1449 1.33519C16.1617 1.33519 17.7863 2.9692 17.7863 4.99534C17.7863 7.02149 16.1617 8.64614 14.1449 8.64614C12.4175 8.64614 10.9236 7.44164 10.5407 5.71428L8.85073 5.71428L7.54355 7.02149L6.09629 5.55557L4.71441 5.55557L3.77137 6.50794L2.24009 4.99534ZM14.3223 4.99534C14.3223 5.66761 14.8638 6.20915 15.5361 6.20915C16.199 6.20915 16.7406 5.66761 16.7406 4.99534C16.7406 4.32306 16.199 3.79085 15.5361 3.79085C14.8638 3.79085 14.3223 4.33241 14.3223 4.99534Z'
            />
        </BaseSvg>
    );
};

export default { name: 'password', icon: Password } as const;
