import { Stack } from '@alltis/ui';
import React from 'react';
import AquaMonitor from './Monitors/AquaMonitor';
import BirdNumberMonitor from './Monitors/BirdNumberMonitor';
import EggsProductionMonitor from './Monitors/EggsProductionMonitor';
import RationMonitor from './Monitors/RationMonitor';

const MonitoringEntities = () => {
    return (
        <Stack gap={24}>
            <BirdNumberMonitor />
            <EggsProductionMonitor />
            <RationMonitor />
            <AquaMonitor />
        </Stack>
    );
};

export default MonitoringEntities;
