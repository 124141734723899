import Checkbox from '@/shared/components/ui/Checkbox';
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Stack } from 'tamagui';
import { SelectProps } from './SelectProps';

const SelectV2 = (props: SelectProps) => {
    const getValues = () => {
        return props.items?.filter((item) => props.value.includes(item.value));
    };

    return (
        <Autocomplete
            size='small'
            options={props.items || []}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Stack mr={6}>
                        <Checkbox style={{ marginRight: 8 }} value={selected} />
                    </Stack>

                    {option.label}
                </li>
            )}
            limitTags={2}
            multiple={props.multiple}
            disableCloseOnSelect={props.multiple}
            loadingText='Carregando...'
            loading={props.isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{
                        style: { color: '#376A7B' },
                    }}
                    variant='outlined'
                    style={{ minWidth: 200 }}
                    fullWidth
                    label={props.label}
                    placeholder={props.sheetTitle}
                    size='small'
                />
            )}
            fullWidth
            onChange={(e, items) => {
                if (Array.isArray(items)) {
                    return props.onChange?.(
                        items.map((i) => i.value),
                        items
                    );
                }

                return props.onChange?.(items ? [items.value] : [], items ? [items] : []);
            }}
            value={getValues()}
            disabled={props.isDisabled}
        />
    );
};

export default SelectV2;
