import { BirdSpeciesTable } from '@/birds';
import React from 'react';
import ConfigurationSection from '../../ConfigurationSection';
import { Icon, Stack } from '@alltis/ui';

const SpeciesOfBirds = () => {
    return (
        <ConfigurationSection title='Espécies de Aves'>
            <Stack flexDirection='row'>
                <Stack mr={11} display='none' $sm={{ display: 'flex' }}>
                    <Icon name='chicken' size={24} color='#588290' />
                </Stack>

                <Stack flex={1}>
                    <BirdSpeciesTable />
                </Stack>
            </Stack>
        </ConfigurationSection>
    );
};

export default SpeciesOfBirds;
