import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Aviary = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='m12 3.861 11.479 6.261a1 1 0 1 1-.958 1.756l-.52-.284V20h-7v-5H9v5H2v-8.406l-.522.284a1 1 0 1 1-.958-1.756l11.48-6.261Zm-8 6.642V18h3v-5h10v5h3v-7.497l-8-4.364-8 4.364Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'aviary', icon: Aviary } as const;
