import React, { memo, useEffect } from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';

import { useSharedSkeletonTransition } from './contexts/SharedSkeletonTransitionProvider';

type SkeletonViewProps = Pick<ViewStyle, 'height' | 'width' | 'flex' | 'flexGrow'>;

const SkeletonView = ({ height, width, flex, ...props }: SkeletonViewProps) => {
    const { animatedStyle, onMount, onUnmount } = useSharedSkeletonTransition();

    useEffect(() => {
        onMount?.();

        return () => onUnmount?.();
    }, []);

    const defaultStyle: StyleProp<ViewStyle> = {
        ...props,
        backgroundColor: '#d7d7d7',
        height,
        width,
        borderRadius: 8,
        flex,
    };

    if (Platform.OS === 'android') {
        return <View style={[defaultStyle, { opacity: 0.7 }]} />;
    }

    return <Animated.View style={[defaultStyle, animatedStyle]} />;
};

export default memo(SkeletonView);
