import dayjs from 'dayjs';
import { ExportMetricsInput } from '../hooks/useMetricResults';
import { ReportMetricResultParams } from '../screens/ReportMetricResult/params';

export function metricFormToPayload(params: ReportMetricResultParams): ExportMetricsInput {
    return {
        all_aviaries: !params.aviaryIds?.length,
        aviary_id: params.aviaryIds || [],
        format: 'json',
        initial_period: dayjs(params.startDate, 'YYYY-MM-DD').tz('America/Sao_Paulo').toDate(),
        final_period: dayjs(params.endDate, 'YYYY-MM-DD').tz('America/Sao_Paulo').toDate(),
        types: ['egg_production'],
    };
}
