import { useSelectedFarm } from '@legacy/user/store/SelectedFarmStore';
import { InternalAxiosRequestConfig } from 'axios';

export function createFarmInterceptor(config: InternalAxiosRequestConfig) {
    const selectedFarm = useSelectedFarm.getState();

    if (selectedFarm.farm?.id) {
        config.headers['x-farm-id'] = selectedFarm.farm?.id;
    }

    if (selectedFarm.aviary?.id) {
        config.headers['x-aviary-id'] = selectedFarm.aviary?.id;
    }

    return config;
}
