import HeavyScreen from '@legacy/shared/components/ui/HeavyScreen';
import LoadingScreen from '@legacy/shared/components/ui/LoadingScreen';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import SiloViewState from '@legacy/warehouse/components/SiloViewState';
import WarehouseActions from '@legacy/warehouse/components/WarehouseCard/WarehouseActions';
import WarehouseFeedDetails from '@legacy/warehouse/components/WarehouseDetails/WarehouseFeedDetails';
import WarehouseHeader from '@legacy/warehouse/components/WarehouseDetails/WarehouseHeader';
import { useSiloById } from '@legacy/warehouse/hooks/silos/useSiloById';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useCurrentSiloID } from './hooks/useCurrentSiloID';

const WarehouseDetails = () => {
    useStatusBar('dark-content');

    const navigation = useNavigation();

    const siloId = useCurrentSiloID();

    const { data: silo, isLoading } = useSiloById(siloId);

    useEffect(() => {
        if (!silo) return;

        navigation.setOptions({
            headerRight: () => <WarehouseActions silo={silo} context='details' />,
        });
    }, [navigation, silo]);

    if (isLoading || !silo) {
        return <SiloViewState hasSilo={!!siloId} loading={isLoading} />;
    }

    return (
        <HeavyScreen placeholder={<LoadingScreen />}>
            <ScrollView>
                <WarehouseHeader silo={silo} />
                <WarehouseFeedDetails silo={silo} />
            </ScrollView>
        </HeavyScreen>
    );
};

export default WarehouseDetails;
