import React, { PropsWithChildren } from 'react';
import Animated, { LinearTransition, useAnimatedStyle, withSpring, withTiming } from 'react-native-reanimated';
import { Stack } from 'tamagui';
import { useDrawerCollapsed } from '../../../hooks/useDrawerCollapsed';
import { useDrawerConstants } from '../../../hooks/useDrawerConstants';
import { useDrawerStore } from '../../../store/DrawerStore';
import DrawerOverlay from '../DrawerOverlay';

type Props = PropsWithChildren<{
    onOverlayPress?: () => void;
}>;

const DrawerAnimatedContainer = (props: Props) => {
    const { width, isCollapsable } = useDrawerConstants();

    const isCollapsed = useDrawerCollapsed();

    const toggleCollapsed = useDrawerStore((store) => store.toggleCollapsed);

    const animatedStyle = useAnimatedStyle(
        () => ({
            width: withSpring(isCollapsed ? width.collapsed : width.expanded, {
                damping: 200,
                stiffness: 300,
            }),
        }),
        [isCollapsed]
    );

    if (!isCollapsable) {
        return (
            <Stack flex={1}>
                <DrawerOverlay
                    onPress={() => {
                        toggleCollapsed(true);
                        props.onOverlayPress?.();
                    }}
                />

                {props.children}
            </Stack>
        );
    }

    return (
        <>
            <DrawerOverlay
                onPress={() => {
                    toggleCollapsed(true);
                    props.onOverlayPress?.();
                }}
            />

            <Stack flex={1} position='relative' onPress={() => toggleCollapsed?.(false)} zIndex={9999}>
                <Animated.View
                    style={[
                        animatedStyle,
                        {
                            overflow: 'hidden',
                            position: 'absolute',
                            height: '100%',
                        },
                    ]}
                    pointerEvents={isCollapsed ? 'none' : 'auto'}
                >
                    {props.children}
                </Animated.View>
            </Stack>
        </>
    );
};

export default DrawerAnimatedContainer;
