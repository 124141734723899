import Button from '@legacy/shared/components/ui/Button';
import { SafeBottom } from '@legacy/shared/components/ui/SafeBottom';
import React from 'react';
import { Stack } from 'tamagui';

type Props = {
    onConfirm: () => void;
};

const FarmSelectorFooter = ({ onConfirm }: Props) => {
    return (
        <Stack bg='#ffffff' px={16}>
            <Button color='secondary' onPress={onConfirm}>
                Confirmar
            </Button>

            <SafeBottom />
        </Stack>
    );
};

export default FarmSelectorFooter;
