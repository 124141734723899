import { Badge, Stack, Text } from '@alltis/ui';
import { LinearGradient } from 'expo-linear-gradient';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';
import { useDrawerConstants } from '../../../hooks/useDrawerConstants';
import { DrawerRoute } from '../../../types';
import AlertBadge from './AlertBadge';

type Props = {
    item: DrawerRoute;
    onPress: (item: DrawerRoute) => void;
    isActive?: boolean;
    isCollapsed?: boolean;
};

const DrawerItem = ({ item, isActive, onPress, isCollapsed }: Props) => {
    const { paddingX } = useDrawerConstants();

    const backgroundColor = isActive ? ['#376A7B', '#376A7B'] : ['transparent', 'transparent'];
    const iconColor = isActive ? '#8DC044' : '#588290';
    const borderColor = isActive ? '#8DC044' : 'transparent';

    const textColor = isActive ? '#FFFFFF' : '#B2C5CC';

    const isDisabled = item.soon || item.disabled;

    return (
        <TouchableOpacity
            activeOpacity={0.8}
            onPress={() => onPress(item)}
            style={{ opacity: isDisabled ? 0.6 : 1, pointerEvents: isCollapsed ? 'none' : 'auto' }}
            disabled={isCollapsed}
        >
            <LinearGradient start={[0, 1]} end={[1, 0]} style={{ height: 50 }} colors={backgroundColor}>
                <Stack
                    flexDirection='row'
                    alignItems='center'
                    flex={1}
                    px={paddingX}
                    hoverStyle={{ background: '#657e8320' }}
                    transition='.2s'
                    borderRightColor={borderColor}
                    borderRightWidth={4}
                    style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
                >
                    <Stack width={20} position='relative'>
                        {item.icon({ color: iconColor, size: 10, focused: false })}

                        {isCollapsed && !!item.countBadge && (
                            <Stack position='absolute' right={-13} top={-5}>
                                <Badge size='$0.4' />
                            </Stack>
                        )}
                    </Stack>

                    {!isCollapsed && (
                        <>
                            <Text color={textColor} fontSize={14} ml={18} fontWeight='400' numberOfLines={1}>
                                {item.title}
                            </Text>

                            {!!item.countBadge && (
                                <Stack flex={1} alignItems='flex-end'>
                                    <AlertBadge value={item.countBadge} />
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </LinearGradient>
        </TouchableOpacity>
    );
};

export default memo(DrawerItem);
