import { useScreenLoading } from '@legacy/shared/hooks/useScreenLoading';
import React, { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren & {
    placeholder: ReactNode;
};

const HeavyScreen = (props: Props) => {
    const { isLoading } = useScreenLoading();

    return (
        <>
            {isLoading && props.placeholder}
            {!isLoading && props.children}
        </>
    );
};

export default HeavyScreen;
