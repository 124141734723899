import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Silo = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M16 4.828V5H8v-.172L10.828 2h2.344L16 4.828ZM8 6h8v8H8V6Zm0 9v.172l4 4 4-4V15H8ZM6 4l4-4h4l4 4v8h2v12h-2v-8l-4.586 4.586L12 22l-1.414-1.414L6 16v8H4V12h2V4Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'silo', icon: Silo } as const;
