import CardIcon from '@/alltis-ui-v2/components/PageList/components/PageListCard/CardIcon';
import { formatBigNumber } from '@/shared/utils/number';
import { Stack, Text } from '@alltis/ui';
import React from 'react';

export type PageListCardInfoProps = {
    title: string;
    quantity: {
        value: number;
        suffix?: string;
    };
    caption?: string;
    color?: string;
};

const CardLabel = ({ title, quantity, caption, color = '#376A7B' }: PageListCardInfoProps) => {
    return (
        <Stack>
            <Text color={color} fontSize={12} fontWeight={400} numberOfLines={1} overflow='visible' $md={{ textAlign: 'right' }}>
                {title}
            </Text>

            <Text color={color} fontSize={24} fontWeight={500} $md={{ textAlign: 'right' }} mt={4}>
                {formatBigNumber(quantity.value)} {quantity.suffix}
            </Text>

            {caption && (
                <Text color={color} fontSize={14} fontWeight={500} mt={7} opacity={0.7} $md={{ textAlign: 'right' }}>
                    {caption}
                </Text>
            )}
        </Stack>
    );
};

export default CardLabel;
