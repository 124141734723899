import React from 'react';
import { Icon, Badge, Stack, Text, XStack, YStack, IconNames } from '@alltis/ui';
import { NotificationType, UserNotification } from '@/notifications/types/UserNotification';
import dayjs from 'dayjs';

type Props = {
    notification: UserNotification;
};

const NotificationCard = ({ notification }: Props) => {
    const read = true;

    const textColor = read ? '#376A7B' : '#A34742';
    const iconColor = read ? '#588290' : '#A34742';

    const notificationIconMap: Record<NotificationType, IconNames> = {
        ambience: 'ambience',
        any: 'notifications',
        aqua: 'aqua',
        chickens: 'chicken',
        eggs: 'eggs',
        silo: 'silo',
    };

    return (
        <XStack px={16} py={12}>
            <Stack jc='center' ai='center' pr={16}>
                {!read && (
                    <Stack position='absolute' top={0} left={-8}>
                        <Badge size='$0.4' color='#A34742' />
                    </Stack>
                )}

                <Icon name={notificationIconMap[notification.type] || notification.type} size={24} color={iconColor} />
            </Stack>

            <YStack flex={1} gap={2}>
                <Text color={textColor} fontWeight={600} fontSize={14} pr={38}>
                    {notification.title}
                </Text>

                <Text color={textColor} fontWeight={400} fontSize={14}>
                    {notification.description}
                </Text>

                <Text color={textColor} fontWeight={500} fontSize={13} opacity={0.6}>
                    {notification.short_message}
                </Text>
            </YStack>

            <Text
                color={textColor}
                opacity={0.6}
                fontWeight={600}
                fontSize={12}
                numberOfLines={1}
                position='absolute'
                right={16}
                top={12}
            >
                {dayjs(notification.created_at).format('DD MMM, HH:mm')}
            </Text>
        </XStack>
    );
};

export default NotificationCard;
