import { Icon, Stack, Text } from '@alltis/ui';
import React from 'react';
import { TableButtonProps } from '../../DataTableProps';

const TableButton = (props: TableButtonProps) => {
    return (
        <Stack
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            px={24}
            py={12}
            cursor='pointer'
            hoverStyle={{
                backgroundColor: '#f7f7f7',
            }}
            pressStyle={{
                backgroundColor: '#f7f7f7',
            }}
            onPress={props.onPress}
        >
            <Text color='#8CA9B3' fontWeight='500' fontSize={14}>
                {props.title}
            </Text>

            {props.icon && <Icon name={props.icon} size={14} color='#8CA9B3' />}
        </Stack>
    );
};

export default TableButton;
