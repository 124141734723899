import { useErrorHandler } from '@legacy/shared/hooks/useErrorHandler';
import * as Sharing from 'expo-sharing';

export function useShareVideo() {
    const errorHandler = useErrorHandler();

    async function share(uri: string) {
        try {
            await Sharing.shareAsync(uri, {
                dialogTitle: 'Gravação de câmera',
                mimeType: 'video/mpg',
            });
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    return { share };
}
