import { Farm } from '@/user/entities/Farm';
import { useFarms } from '@/user/hooks/useFarms';
import { useSelectedFarm } from '@/user/store/SelectedFarmStore';
import React, { memo, useEffect } from 'react';
import { Stack } from 'tamagui';
import DrawerFarmSelectorButton from '../DrawerFarmSelectorButton';
import FarmSelectionList from '../FarmSelectionList';

type Props = {
    onBack?: () => void;
    onFarmSelected?: () => void;
};

const DrawerFarmSelector = ({ onBack, ...props }: Props) => {
    const [farm, setSelectedFarm] = useSelectedFarm((store) => [store.farm, store.updateSelectedFarm]);

    const farms = useFarms();

    useEffect(() => {
        if (!farm && !!farms.data?.length) {
            setSelectedFarm(farms.data[0]);
        }
    }, [farms.data]);

    function onFarmSelected(farm: Farm) {
        setSelectedFarm(farm);
        onBack?.();

        props.onFarmSelected?.();
    }

    return (
        <Stack position='relative' flex={1}>
            <DrawerFarmSelectorButton title='Granjas' onPress={onBack} isOpen />

            <FarmSelectionList onFarmSelected={onFarmSelected} farms={farms.data || []} />
        </Stack>
    );
};

export default memo(DrawerFarmSelector);
