import { FAB } from '@legacy/shared/components/ui/FAB';
import { useErrorHandler } from '@legacy/shared/hooks/useErrorHandler';
import FontAwesome6 from '@expo/vector-icons/FontAwesome6';
import { memo, useCallback, useMemo } from 'react';
import { useOpenReport } from '../hooks/useOpenReport';
import { ExportReportFileType, UseReportExportProps, useReportExport } from '../hooks/useReportExport';
import { Platform } from 'react-native';

type Props = {
    filter: UseReportExportProps;
};

const ExportReportButton = ({ filter }: Props) => {
    const errorHandler = useErrorHandler();

    const exportReport = useReportExport(filter);
    const shareReport = useOpenReport();

    const isLoading = useMemo(
        () => exportReport.isLoading || shareReport.isLoading,
        [exportReport.isLoading, shareReport.isLoading]
    );

    const onExportPress = useCallback(
        async (type: ExportReportFileType) => {
            try {
                if (isLoading) return;

                const { uri } = await exportReport.mutateAsync({ type });

                await shareReport.share(uri);
            } catch (err) {
                errorHandler.handle(err);
            }
        },
        [filter, exportReport.mutate, shareReport.share, isLoading]
    );

    const hasFilter = useMemo(() => {
        return filter.startDate && filter.endDate && filter.entityType && !!filter.entityIds?.length;
    }, [filter]);

    if (hasFilter) {
        return (
            <FAB
                icon='download'
                label='Exportar'
                isLoading={isLoading}
                onActionPress={(action) => onExportPress(action.key as ExportReportFileType)}
                actions={[
                    {
                        icon: <FontAwesome6 name='file-pdf' size={18} />,
                        key: 'pdf',
                        label: 'PDF',
                    },
                    ...(Platform.OS === 'web' && __DEV__
                        ? [
                              {
                                  icon: <FontAwesome6 name='window-maximize' size={18} />,
                                  key: 'page',
                                  label: 'Página',
                              },
                          ]
                        : []),
                    ...(filter.entityType === 'egg-vision'
                        ? [
                              {
                                  icon: <FontAwesome6 name='file-excel' size={18} />,
                                  key: 'xlsx',
                                  label: 'XLSX',
                              },
                              {
                                  icon: <FontAwesome6 name='file-csv' size={18} />,
                                  key: 'csv',
                                  label: 'CSV',
                              },
                          ]
                        : []),
                ]}
            />
        );
    }

    return null;
};

export default memo(ExportReportButton);
