import { BottomSheetBackdrop, BottomSheetBackdropProps } from '@gorhom/bottom-sheet';
import { Portal } from '@gorhom/portal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { Stack, Text } from 'tamagui';
import { Button } from '@alltis/ui';
import Select from '../Select';
import { Sheet, SheetProps } from '../Sheet';

export type SelectSheetProps = Required<Pick<SheetProps, 'onClose'>> & {
    title: string;
    description: string;
    value?: string;
    items: {
        label: string;
        value: string;
    }[];
    onConfirm: (value?: string) => Promise<void> | void;
    isOpen: boolean;
};

const SelectSheet = ({ onClose, title, value, items, onConfirm, isOpen, description }: SelectSheetProps) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const bottomSheetRef = useRef<Sheet>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => setSelectedValue(value), [value]);

    const backdropComponent = useCallback(
        (props: BottomSheetBackdropProps) => <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />,
        []
    );

    if (!isOpen) return null;

    return (
        <Portal>
            <Sheet
                index={0}
                animateOnMount
                snapPoints={[Platform.OS === 'android' ? 280 : 390]}
                enablePanDownToClose
                backdropComponent={backdropComponent}
                onClose={onClose}
                ref={bottomSheetRef}
                style={{ overflow: 'hidden' }}
            >
                <Stack p={24} py={20}>
                    <Text color='$secondary500' fontWeight='600' fontSize={20} numberOfLines={1}>
                        {title}
                    </Text>

                    <Text color='$secondary900' mt={10} numberOfLines={1}>
                        {description}
                    </Text>

                    <Stack
                        py={Platform.select({
                            android: 16,
                            ios: 0,
                        })}
                    >
                        <Stack
                            borderWidth={Platform.select({
                                android: 1,
                                ios: 0,
                            })}
                            borderRadius={10}
                            borderColor='#c5c5c5'
                            px={Platform.select({
                                android: 8,
                                ios: 0,
                            })}
                            $platform-web={{ py: 24 }}
                        >
                            <Select label='Selecione um item' items={items} onChange={setSelectedValue} value={selectedValue} />
                        </Stack>
                    </Stack>

                    <Button
                        onPress={async () => {
                            try {
                                setIsLoading(true);

                                await onConfirm(selectedValue);

                                bottomSheetRef.current?.close();
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                        loading={isLoading}
                    >
                        Confirmar
                    </Button>
                </Stack>
            </Sheet>
        </Portal>
    );
};

export default SelectSheet;
