import React, { ReactNode } from 'react';
import { Stack, Text } from '@alltis/ui';
import { formatBigNumber } from '@/shared/utils/number';

type Props = {
    title: string;
    label: string;
    quantity: {
        value: number;
        suffix: string;
    };
    header?: ReactNode;
};

const MonitoringCardData = ({ label, quantity, title, header }: Props) => {
    return (
        <Stack>
            {header && (
                <Stack mb={16} $md={{ alignItems: 'flex-end' }}>
                    {header}
                </Stack>
            )}

            <Text color='#8DC044' fontWeight='500' fontSize={14} mb={15} $md={{ textAlign: 'right' }}>
                {title}
            </Text>

            <Text color='#8CA9B3' fontWeight='400' fontSize={12} mb={4} $md={{ textAlign: 'right' }}>
                {label}
            </Text>

            <Text color='#376A7B' fontWeight='500' fontSize={20} $md={{ textAlign: 'right' }}>
                {formatBigNumber(quantity.value)} {quantity.suffix}
            </Text>
        </Stack>
    );
};

export default MonitoringCardData;
