import AppProvidersLegacy from '../legacy/config/providers';
import { AppStackNavigator as LegacyAppStack } from '../legacy/navigation/AppStack';
import AppProviders from './config/providers';
import { useNewUIFlag } from './config/theme/hooks/useNewUIEnabled';
import { AppStackNavigator } from './navigation/AppStack';

function AppSwitch() {
    const isFlagActive = useNewUIFlag();

    if (!isFlagActive) {
        return (
            <AppProvidersLegacy>
                <LegacyAppStack />
            </AppProvidersLegacy>
        );
    }

    return (
        <AppProviders>
            <AppStackNavigator />
        </AppProviders>
    );
}

export default AppSwitch;
