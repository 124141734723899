import { GetSilosByGroupResponse } from '@legacy/warehouse/api/types/SiloResponse';
import { container } from '../../../../config/di';
import { SiloApi } from '../../api/SiloApi';
import { useQuery } from '../../../../config/cache/useQuery';

export function useSilosByGroup(groupId: string | null | undefined) {
    const siloApi = container.resolve(SiloApi);

    return useQuery<GetSilosByGroupResponse>({
        queryKey: ['silos-by-group', groupId],
        queryFn: () => siloApi.getSilosByGroup(groupId!),
        enabled: !!groupId,
    });
}
