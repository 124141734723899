import Section from '@legacy/shared/components/ui/Section';
import React from 'react';
import { usePushNotificationToken } from '../../../../../../../config/notifications/usePushNotificationToken';
import { Text } from 'tamagui';

const NotificationSettings = () => {
    const { data: pushToken } = usePushNotificationToken();

    return (
        <Section name='Notificações'>
            <Section.Item
                title='Expo Push Token'
                icon='code'
                isText
                rightContent={
                    <Text fontSize='$2' userSelect='all' color='$secondary900'>
                        {pushToken}
                    </Text>
                }
            />
        </Section>
    );
};

export default NotificationSettings;
