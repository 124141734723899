import { LoginFormData } from '@/authentication/utils/validators/loginSchema';
import { Icon, Stack, TextField } from '@alltis/ui';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
    onSubmit: () => void;
};

const LoginFormFields = ({ onSubmit }: Props) => {
    const { control, setFocus } = useFormContext<LoginFormData>();

    return (
        <Stack>
            <Controller
                control={control}
                name='email'
                render={({ field: { onChange, ...field } }) => (
                    <TextField
                        placeholder='E-mail'
                        endAdornment={<Icon name='mail' size={20} color='#376A7B' />}
                        onChangeText={onChange}
                        autoCapitalize='none'
                        returnKeyType='next'
                        onSubmitEditing={() => setFocus('password')}
                        blurOnSubmit={false}
                        keyboardType='email-address'
                        mb={8}
                        {...field}
                    />
                )}
            />

            <Controller
                control={control}
                name='password'
                render={({ field: { onChange, ...field } }) => (
                    <TextField
                        placeholder='Senha'
                        endAdornment={<Icon name='password' size={24} color='#376A7B' />}
                        secureTextEntry
                        onChangeText={onChange}
                        autoCapitalize='none'
                        onSubmitEditing={onSubmit}
                        {...field}
                    />
                )}
            />
        </Stack>
    );
};

export default LoginFormFields;
