import { CameraRecord } from '@legacy/camera-video/entities/CameraRecord';
import { useOpenSystemVideo } from '@legacy/chicken-house/hooks/recordings/useOpenSystemVideo';
import { useVideoThumbnail } from '@legacy/chicken-house/hooks/recordings/useVideoThumbnail';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import dayjs from 'dayjs';
import { Image } from 'expo-image';
import formatDuration from 'format-duration';
import React from 'react';
import Animated from 'react-native-reanimated';
import { Stack, Text, XStack, YStack } from 'tamagui';
import PlaceRecordingMenu from '../PlaceRecordingMenu';
import { TouchableOpacity } from 'react-native';

export const AnimatedContainer = Animated.createAnimatedComponent(XStack);

type Props = {
    recording: CameraRecord;
};

const PlaceRecordingItem = ({ recording }: Props) => {
    const { thumbnail } = useVideoThumbnail(recording.path);
    const { open: openVideo } = useOpenSystemVideo();

    return (
        <AnimatedContainer p={24} borderBottomColor='#f5f5f5' borderBottomWidth={1}>
            <XStack flex={1}>
                <TouchableOpacity
                    accessibilityLabel='Abrir gravação'
                    style={{ flex: 1, flexDirection: 'row' }}
                    onPress={() => openVideo(recording.path)}
                    activeOpacity={0.8}
                >
                    <Image
                        source={{ uri: thumbnail?.uri }}
                        style={{ width: 100, height: 60, borderRadius: 10, backgroundColor: '#eeeeee' }}
                    />

                    <XStack ai='center' ml={8} flex={1}>
                        <YStack marginHorizontal={12}>
                            <Text color='#A0AEC0'>Duração</Text>

                            <Text color='$secondary500' fontWeight='500' fontSize={14} mt={2}>
                                {formatDuration(recording.duration * 10)}
                            </Text>
                        </YStack>

                        <YStack marginHorizontal={12}>
                            <Text color='#A0AEC0'>Gravado em</Text>

                            <Text color='$secondary500' fontWeight='500' fontSize={14} mt={2}>
                                {dayjs(recording.createdAt).fromNow()}
                            </Text>
                        </YStack>
                    </XStack>
                </TouchableOpacity>

                <Stack alignSelf='center' ai='flex-end' jc='center' ml={10}>
                    <PlaceRecordingMenu recording={recording}>
                        <MaterialIcons name='more-vert' size={24} />
                    </PlaceRecordingMenu>
                </Stack>
            </XStack>
        </AnimatedContainer>
    );
};

export default PlaceRecordingItem;
