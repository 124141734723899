import { Portal } from '@gorhom/portal';
import React, { ReactNode } from 'react';
import { Stack, useMedia } from 'tamagui';
import { useNewUIEnabled } from '../../../../../../../config/theme/hooks/useNewUIEnabled';
import EndContent from './EndContent';
import StartContent from './StartContent';

type Props = {
    start?: ReactNode;
    end?: ReactNode;
    onReportPress?: () => void;
    hideReport?: boolean;
};

const AppBar = ({ end, start, onReportPress, hideReport }: Props) => {
    const isNewUIEnabled = useNewUIEnabled();
    const media = useMedia();

    if (!isNewUIEnabled) return null;

    if (media.md) {
        return (
            <>
                <Portal hostName='app_bar_left'>
                    <Stack alignItems='center' jc='center'>
                        <StartContent />
                    </Stack>

                    {start}
                </Portal>
                <Portal hostName='app_bar_right'>
                    {end}

                    <EndContent onReportPress={onReportPress} hideReport={hideReport} />
                </Portal>
            </>
        );
    }

    return (
        <Stack
            h={60}
            width='100%'
            backgroundColor='#ffffff'
            elevationAndroid={5}
            shadowColor='#000000'
            shadowOpacity={0.1}
            shadowOffset={{ width: 0, height: 4 }}
            shadowRadius={16}
            px={14}
            pt={6}
        >
            <Stack flex={1} jc='space-between' ai='center' flexDirection='row' gap={12}>
                <StartContent />

                {start}

                {end}

                <EndContent onReportPress={onReportPress} hideReport={hideReport} />
            </Stack>
        </Stack>
    );
};

export default AppBar;
