import FeedMenu from '@/warehouse/components/FeedCard/FeedMenu';
import { useFeedTypes } from '@/warehouse/hooks/feed/useFeedTypes';
import { DataTable, TableButton, Text } from '@alltis/ui';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useMedia } from 'tamagui';

const RationTable = () => {
    const rations = useFeedTypes();
    const navigation = useNavigation();
    const media = useMedia();

    return (
        <DataTable
            data={rations.data?.items || []}
            columns={[
                {
                    header: 'Nome',
                    accessorKey: 'name',
                },
                {
                    header: 'Densidade',
                    accessorKey: 'density',
                    cell: ({ row }) => (
                        <Text color='#376A7B' fontWeight='500' fontSize={16}>
                            {row.original.density} g/cm³
                        </Text>
                    ),
                    size: 145,
                },
                {
                    header: '',
                    accessorKey: 'actions',
                    cell: ({ row }) => <FeedMenu feed={row.original} />,
                    size: media.md ? undefined : 10,
                },
            ]}
            renderFooter={() => (
                <TableButton title='Adicionar Tipo de Ração' icon='plus' onPress={() => navigation.navigate('FeedRegister')} />
            )}
        />
    );
};

export default RationTable;
