import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { IconProps } from '../types';
import { useSvgProps } from '../hooks/useSvgProps';

const Plus = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 14 14'>
            <Path d='M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z' fill={svg.color} />
        </BaseSvg>
    );
};

export default { name: 'plus', icon: Plus } as const;
