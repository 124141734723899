import React, { useRef, useState } from 'react';
import { Platform, TextInput } from 'react-native';
import { Stack, Text, XStack } from 'tamagui';
import { Icon, IconNames } from '../Icon';
import { NumericInputProps } from './NumericInputProps';

const NumericInput = ({ width = 135, ...props }: NumericInputProps) => {
    const inputRef = useRef<TextInput>(null);

    const [value, setValue] = useState<number>(props.value || 0);

    return (
        <XStack height={34} width={width || 135} br={8} bg='#F5F5F5' jc='center' ai='center' p={2}>
            <NumericInputArrow icon='chevron-left' onPress={() => setValue(value - 1)} />

            <XStack flex={1} onPress={() => inputRef.current?.focus()} jc='center'>
                <Stack mb={-1.5}>
                    <TextInput
                        {...props}
                        onChangeText={(text) => {
                            props.onChangeValue?.(parseFloat(text as string));
                            setValue(parseFloat(text as string));
                        }}
                        keyboardType='numeric'
                        // value={props.value ? String(props.value) : '0'}
                        value={isNaN(value) ? '' : String(value)}
                        ref={inputRef}
                        style={{
                            width: Platform.OS === 'web' ? width / 4 : undefined,
                            // marginLeft: 12,
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'right',
                            color: '#376A7B',
                            fontWeight: 'bold',
                            fontFamily: 'Inter',
                            fontSize: 16,
                        }}
                        maxLength={4}
                        onBlur={() => {
                            if (!value || isNaN(value)) {
                                setValue(0);
                            }
                        }}
                    />
                </Stack>

                <Stack width={25} alignSelf='flex-end'>
                    {props.prefix && (
                        <Text color='#376A7B' fontSize={10} fontWeight='600' ml={3}>
                            {props.prefix}
                        </Text>
                    )}
                </Stack>
            </XStack>

            <NumericInputArrow icon='chevron-right' onPress={() => setValue(value + 1)} />
        </XStack>
    );
};

const NumericInputArrow = (props: { icon: IconNames; onPress: () => void }) => {
    return (
        <Stack
            w={28}
            h='100%'
            br={6}
            bg='white'
            jc='center'
            ai='center'
            onPress={props.onPress}
            cursor='pointer'
            hoverStyle={{
                bg: '#e6e6e6',
            }}
            pressStyle={{
                bg: '#e6e6e6',
            }}
            shadowColor='#000'
            shadowOffset={{
                width: 0,
                height: 1,
            }}
            shadowOpacity={0.1}
            shadowRadius={2}
            elevationAndroid={5}
        >
            <Icon name={props.icon} size={28} color='#588290' />
        </Stack>
    );
};

export default NumericInput;
