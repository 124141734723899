import CameraNetworkConfigForm from '@legacy/chicken-house/components/PlaceScan/CameraNetworkConfigForm';
import StepAlerts from '@legacy/chicken-house/components/PlaceScan/StepAlerts';
import { useCameraScan } from '@legacy/chicken-house/hooks/camera/useCameraScan';
import { useServerScan } from '@legacy/chicken-house/hooks/server-scan/useServerScan';
import KeyboardView from '@legacy/shared/components/ui/KeyboardView';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import Animated, { Layout } from 'react-native-reanimated';
import { Stack } from 'tamagui';

const PlaceScan = () => {
    useStatusBar('dark-content');
    useServerScan({ scanOnMount: true });

    const { data: scanResult } = useCameraScan();

    return (
        <KeyboardView>
            <Stack flex={1}>
                <Animated.View style={{ flex: 1 }} layout={Layout.springify()}>
                    {scanResult?.status === 'DONE' ? (
                        <ScrollView>
                            <CameraNetworkConfigForm />
                        </ScrollView>
                    ) : (
                        <StepAlerts />
                    )}
                </Animated.View>
            </Stack>
        </KeyboardView>
    );
};

export default PlaceScan;
