import { WarehouseStateIcon } from '@legacy/warehouse/components/WarehouseStateIcon';
import { Silo } from '@legacy/warehouse/entities/Silo';
import { useQuantityUnits } from '@legacy/warehouse/hooks/useQuantityUnits';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import React from 'react';
import { Stack, Text, XStack } from 'tamagui';

type Props = {
    silo: Pick<Silo, 'prefer_tons' | 'name' | 'level' | 'temperature' | 'proportions' | 'cloud_points'>;
    temperaturePosition?: 'right' | 'bottom';
};

const WarehouseHeaderInfo = ({ silo, temperaturePosition = 'right' }: Props) => {
    const quantityUnits = useQuantityUnits(silo?.prefer_tons);

    const toPercent = (value: number) => {
        return (value || 0) * 100;
    };

    return (
        <Stack flexDirection={temperaturePosition === 'right' ? 'row' : 'column'}>
            <Stack ai='center'>
                <Stack>
                    <Text fontWeight='600' color='$secondary900' mb={10} fontSize={14}>
                        {silo.name}
                    </Text>
                </Stack>

                <WarehouseStateIcon
                    label={quantityUnits.format({
                        perc: silo?.level.current.percentage,
                        tons: silo?.level.current.tons,
                    })}
                    percentage={toPercent(silo?.level.current.linear || 0)}
                    status={silo?.level.status}
                    numberOfPanels={silo.proportions.plates}
                    levelPoints={
                        silo.cloud_points?.map((point) => ({
                            x: toPercent(point.pos_x) * 2, // multiply per 2 because x range is from 0.0 to 0.5
                            y: toPercent(point.pos_y),
                        })) || []
                    }
                    proportions={
                        silo.proportions && {
                            base: toPercent(silo.proportions.base_height),
                            body: toPercent(silo.proportions.body_height),
                            ceil: toPercent(silo.proportions.ceil_height),
                            diameter: toPercent(silo.proportions.diameter),
                        }
                    }
                />
            </Stack>

            <XStack
                justifyContent='center'
                alignItems='center'
                ml={temperaturePosition === 'right' ? 14 : 0}
                mt={temperaturePosition === 'right' ? 0 : 12}
            >
                <FontAwesome5 name='temperature-high' size={14} color='#a1a5ab' />

                <Text color='#a1a5ab' fontWeight='600' ml={4} fontSize={14}>
                    {silo?.temperature || 0} ºC
                </Text>
            </XStack>
        </Stack>
    );
};

export default WarehouseHeaderInfo;
