import React, { useState } from 'react';
import { RefreshControl as RNRefreshControl } from 'react-native';
import { RefreshControlProps } from './RefreshControlProps';

export const RefreshControl = ({ onRefresh, ...props }: RefreshControlProps) => {
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefresh = async () => {
        setIsRefreshing(true);

        try {
            await onRefresh();
        } finally {
            setIsRefreshing(false);
        }
    };

    return (
        <RNRefreshControl
            {...props}
            tintColor='#05355a'
            colors={['#05455A']}
            refreshing={isRefreshing}
            onRefresh={handleRefresh}
        />
    );
};
