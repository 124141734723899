import { Aviary } from '@/user/entities/Aviary';
import { useFarmAviaries } from '@/user/hooks/useFarmAviaries';
import { useSelectedFarm } from '@/user/store/SelectedFarmStore';
import { Select } from '@alltis/ui';
import React from 'react';

const FarmAviarySelector = () => {
    const farm = useSelectedFarm((store) => store.farm);

    const [selectedAviaries, setSelectedAviaries] = useSelectedFarm((store) => [store.aviaries, store.updateSelectedAviaries]);

    const aviaries = useFarmAviaries(farm?.id);

    const aviariesItems = aviaries.data?.map((aviary) => ({
        label: aviary.name,
        value: aviary.id,
    }));

    return (
        <Select
            items={[{ label: 'Todos', value: null }, ...(aviariesItems || [])]}
            value={selectedAviaries?.map((aviary) => aviary?.id) || []}
            isLoading={aviaries.isLoading}
            label='Aviários'
            style={{ flex: 1, justifyContent: 'center' }}
            onChange={(newValue) => {
                if (newValue.includes(null)) {
                    setSelectedAviaries([]);
                } else {
                    setSelectedAviaries(newValue.map((id) => aviaries.data?.find((aviary) => aviary.id === id)) as Aviary[]);
                }
            }}
            multiple
        />
    );
};

export default FarmAviarySelector;
