import React, { useState } from 'react';
import { Switch as RNSwitch } from 'react-native';
import { SwitchProps } from './SwitchProps';

const Switch = ({ ...props }: SwitchProps) => {
    const [value, setValue] = useState(false);

    return (
        <RNSwitch
            {...props}
            value={value}
            onChange={() => setValue(!value)}
            trackColor={{
                false: '#F5F5F5',
                true: '#8DC044',
            }}
            thumbColor='#ffffff'
            // @ts-ignore
            activeThumbColor='#ffffff'
        />
    );
};

export default Switch;
