import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Ration = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 10C2 10 2.67767 10.5083 3.96326 11.1064C4.13281 11.1852 4.31292 11.2657 4.50345 11.3467C4.85028 10.9305 5.18277 10.4846 5.5 9.99993C5.91551 9.36513 6.47252 8.94238 7.0765 8.73166C9.14463 6.97346 11.0949 4.94238 12.7665 2.79261C12.7992 2.75056 12.8717 2.69836 13 2.69836C13.1283 2.69836 13.2008 2.75056 13.2335 2.79261C15.2328 5.36384 17.6308 7.76528 20.1522 9.73312C18.4823 10.3731 16.0658 11 13 11C12.3566 11 11.7418 10.9724 11.1565 10.9229C11.7582 11.7023 12.4064 12.3777 13.096 12.9998C17.1667 12.9839 20.2132 11.9547 22.0367 11.1064C23.3223 10.5083 24 10 24 10C23.3985 9.62205 22.797 9.21229 22.1998 8.77465C19.524 6.81375 16.9337 4.29313 14.8124 1.56496C13.914 0.4095 12.086 0.4095 11.1876 1.56496C9.06632 4.29313 6.47603 6.81375 3.80016 8.77465C3.20295 9.21229 2.60148 9.62205 2 10ZM7.12047 11.397C7.53188 10.7683 8.46812 10.7683 8.87953 11.397C10.8254 14.3706 13.4127 17.5757 16 18.9999C16 18.9999 13 20.9999 8 20.9999C3 20.9999 0 18.9999 0 18.9999C2.58732 17.5757 5.17464 14.3706 7.12047 11.397ZM18 16.5C18 16.7761 17.7761 17 17.5 17C17.2239 17 17 16.7761 17 16.5C17 16.2238 17.2239 16 17.5 16C17.7761 16 18 16.2238 18 16.5ZM22 17.5C22 17.7761 21.7761 18 21.5 18C21.2239 18 21 17.7761 21 17.5C21 17.2238 21.2239 17 21.5 17C21.7761 17 22 17.2238 22 17.5ZM18.5 20C18.7761 20 19 19.7761 19 19.5C19 19.2238 18.7761 19 18.5 19C18.2239 19 18 19.2238 18 19.5C18 19.7761 18.2239 20 18.5 20ZM22.5 20.75C22.5 21.1642 22.1642 21.5 21.75 21.5C21.3358 21.5 21 21.1642 21 20.75C21 20.3358 21.3358 20 21.75 20C22.1642 20 22.5 20.3358 22.5 20.75ZM13.75 22.5C14.1642 22.5 14.5 22.1642 14.5 21.75C14.5 21.3358 14.1642 21 13.75 21C13.3358 21 13 21.3358 13 21.75C13 22.1642 13.3358 22.5 13.75 22.5ZM19 23C19 23.5523 18.5523 24 18 24C17.4477 24 17 23.5523 17 23C17 22.4477 17.4477 22 18 22C18.5523 22 19 22.4477 19 23Z'
            />
        </BaseSvg>
    );
};

export default { name: 'ration', icon: Ration } as const;
