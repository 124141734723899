import { Aqua } from '@/aqua/entities/Aqua';
import { useAquaPanel } from '@/aqua/store /useAquaPanelStore';
import { usePanel } from '@/shared/components/ui/Panel/hooks/usePanel';
import { SiloSite } from '@/warehouse/entities/Silo';
import { LineChart, PageList } from '@alltis/ui';
import { useNavigation } from '@react-navigation/native';
import React from 'react';

type Props = {
    aqua: Aqua;
    site?: SiloSite;
};

const AquaCard = ({ aqua }: Props) => {
    const navigation = useNavigation();
    const { isPanel } = usePanel();

    const onSelectAqua = useAquaPanel((state) => state.onSelectAqua);

    function handleOpenDetails() {
        if (isPanel) {
            return onSelectAqua(aqua.id);
        }

        return navigation.navigate('AquaDetails', { aqua });
    }

    return (
        <PageList.Card onPress={handleOpenDetails}>
            <PageList.CardHeader
                title={aqua.name}
                startContent={{
                    title: `Média últimos ${(aqua.consumption.historic.length || 1) - 1} dias`,
                    quantity: {
                        value: aqua.consumption.daily_average,
                        suffix: 'litros',
                    },
                }}
                endContent={{
                    title: `Consumidos hoje`,
                    quantity: {
                        value: aqua.consumption.today || 0,
                    },
                    caption: 'litros',
                }}
                icon='aqua'
                isDanger={aqua.alert}
            />

            <PageList.CardContent>
                <LineChart data={aqua.consumption} isDanger={aqua.alert} />
            </PageList.CardContent>
        </PageList.Card>
    );
};

export default AquaCard;
