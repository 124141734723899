import { useLogout } from '@/authentication/hooks/useLogout';
import { useUser } from '@/authentication/store/UserStore';
import AppVersion from '@/shared/components/ui/AppVersion';
import { UserAvatar } from '@/user/components/UserAvatar';
import { Icon, Popover, Separator, Stack, Text, XStack } from '@alltis/ui';
import { useNavigation } from '@react-navigation/native';
import React from 'react';

const UserProfileMenu = () => {
    const loggedUser = useUser((store) => store.user);
    const logout = useLogout();

    const navigation = useNavigation();

    return (
        <Popover>
            <Popover.Trigger>
                <Stack h={38} w={38} jc='center' ai='center'>
                    <UserAvatar />
                </Stack>
            </Popover.Trigger>

            <Popover.Content width={300} sheetHeight={500}>
                <Popover.Header title='Usuário' />

                {/* Profile */}
                <XStack ai='center' p={16}>
                    <Stack width={42}>
                        <Icon name='account-circle' size={22} color='#588290' />
                    </Stack>

                    <Stack jc='center' gap={2}>
                        <Text color='#376A7B' fontSize={14} fontWeight={500}>
                            Perfil
                        </Text>

                        <Text color='#376A7B' fontSize={14} fontWeight={300}>
                            {loggedUser?.name}
                        </Text>

                        <Text color='#8CA9B3' fontSize={13} fontWeight={400}>
                            {loggedUser?.email}
                        </Text>
                    </Stack>
                </XStack>

                <Separator />

                {/* Notifications */}
                {/* <XStack ai='center' p={16}>
                    <Stack width={42}>
                        <Icon name='notifications' size={24} color='#588290' />
                    </Stack>

                    <Stack jc='center' flex={1}>
                        <Text color='#376A7B' fontSize={14} fontWeight={500} mb={14}>
                            Notificações
                        </Text>

                        <XStack jc='space-between' ai='center'>
                            <Text color='#8CA9B3' fontSize={13} fontWeight={400}>
                                E-mail
                            </Text>

                            <Switch />
                        </XStack>

                        <XStack jc='space-between' ai='center' mt={8}>
                            <Text color='#8CA9B3' fontSize={13} fontWeight={400}>
                                Aplicativo iOS / Android
                            </Text>

                            <Switch />
                        </XStack>
                    </Stack>
                </XStack>

                <Separator /> */}

                {/* Privacy Policy */}
                <XStack
                    ai='center'
                    p={16}
                    cursor='pointer'
                    hoverStyle={{ bg: '#f5f5f5' }}
                    pressStyle={{ bg: '#f5f5f5' }}
                    onPress={() => navigation.navigate('PrivacyPolicy')}
                >
                    <Stack width={42}>
                        <Icon name='file' size={24} color='#376A7B' />
                    </Stack>

                    <Stack jc='center' gap={2}>
                        <Text color='#376A7B' fontSize={14} fontWeight={500}>
                            Política de Privacidade
                        </Text>
                    </Stack>
                </XStack>

                <Separator />

                {/* Logout */}
                <XStack
                    ai='center'
                    p={16}
                    cursor='pointer'
                    hoverStyle={{ bg: '#f5f5f5' }}
                    pressStyle={{ bg: '#f5f5f5' }}
                    onPress={() => logout.handleLogout()}
                >
                    <Stack width={42} pl={6}>
                        <Icon name='logout' size={16} color='#376A7B' />
                    </Stack>

                    <Stack jc='center' gap={2}>
                        <Text color='#376A7B' fontSize={14} fontWeight={500}>
                            Sair
                        </Text>
                    </Stack>
                </XStack>

                <Separator borderColor='#EBEBEA' />

                {/* App Version */}
                <Stack p={16} ai='center'>
                    <AppVersion />
                </Stack>
            </Popover.Content>
        </Popover>
    );
};

export default UserProfileMenu;
