import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Eggs = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M21.44 13.243a5.001 5.001 0 0 1-5.272 3.687 9.02 9.02 0 0 1-.675 1.93 7.001 7.001 0 0 0 7.878-5.1c1-3.734-.439-10.47-4.173-11.47-2.447-.656-5.273 1.314-7.211 3.801.484.46.947.962 1.378 1.492.438-.593.942-1.168 1.494-1.68 1.604-1.488 2.986-1.905 3.821-1.682.835.224 1.824 1.277 2.469 3.368.613 1.99.675 4.217.29 5.654Zm-9.223.412a5 5 0 0 1-9.66 2.588c-.385-1.436-.322-3.665.29-5.654.646-2.091 1.634-3.144 2.47-3.368.835-.223 2.217.194 3.821 1.683 1.526 1.416 2.694 3.314 3.079 4.75Zm1.931-.518A7 7 0 0 1 .627 16.761C-.375 13.026 1.065 6.29 4.799 5.29c3.734-1.001 8.349 4.113 9.35 7.847Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'eggs', icon: Eggs } as const;
