import Section from '@/shared/components/ui/Section';
import { useTesterSettings } from '@/user/store/TesterSettingsStore';
import React from 'react';
import { Switch } from 'react-native';
import ApiUrlSetting from './ApiUrlSetting';
import { useNewUIFlag } from '../../../../../../config/theme/hooks/useNewUIEnabled';

const TesterSettings = () => {
    const { settings, onUpdateSetting } = useTesterSettings();

    const isUIFlagActive = useNewUIFlag();

    return (
        <Section name='Opções do testador'>
            <ApiUrlSetting />

            <Section.Item
                title='Usar aviários mockados'
                icon='settings'
                onPress={() => {
                    onUpdateSetting({
                        shouldUseMockedChickenHouses: !settings.shouldUseMockedChickenHouses,
                    });
                }}
                rightContent={
                    <Switch
                        value={settings.shouldUseMockedChickenHouses}
                        onChange={() => {
                            onUpdateSetting({
                                shouldUseMockedChickenHouses: !settings.shouldUseMockedChickenHouses,
                            });
                        }}
                    />
                }
            />
        </Section>
    );
};

export default TesterSettings;
