import { ReportMetricsButton } from '@/metrics';
import { NotificationsButton } from '@/notifications';
import React from 'react';
import { XStack } from 'tamagui';

type Props = {
    onReportPress?: () => void;
    hideReport?: boolean;
};

const EndContent = ({ hideReport }: Props) => {
    return (
        <XStack jc='center' ai='flex-end' gap={12}>
            {!hideReport && <ReportMetricsButton />}

            <NotificationsButton />
        </XStack>
    );
};

export default EndContent;
