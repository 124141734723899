import EggManagerAppBar from '@/chicken-house/components/EggManagerAppBar';
import PlaceInformation from '@/chicken-house/components/PlaceInformation';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import React from 'react';
import { Stack } from 'tamagui';
import { isWebOrTablet } from '../../../../config/constants/webOrTablet';

const PlaceEggs = () => {
    useStatusBar(isWebOrTablet ? 'dark-content' : 'light-content', '#171923');

    return (
        <Stack flex={1}>
            <EggManagerAppBar />

            <PlaceInformation />
        </Stack>
    );
};

export default PlaceEggs;
