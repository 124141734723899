export function generateIndicatorNumers(max: number, quantity = 5): number[] {
    const step = max / quantity;
    const numbers: number[] = [];

    for (let i = 0; i <= quantity; i++) {
        numbers.push(i * step);
    }

    return numbers;
}
