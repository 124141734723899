import Aqua from '@/aqua/screens/Aqua';
import { Birds } from '@/birds';
import { Configurations } from '@/configurations';

import { Aviaries, Monitoring } from '@/aviary';
import PlaceEggs from '@/chicken-house/screens/PlaceEggs';
import { RationConsumption } from '@/ration';
import { Sense } from '@/sense';
import WarehouseTabs from '@/warehouse/screens/WarehousesTabs';
import { Icon } from '@alltis/ui';
import { useMemo } from 'react';
import { DrawerSection } from '../types';

import { useMenuInfo } from '@/authentication/hooks/useMenuInfo';
import ambienceImage from '../../../../../assets/images/drawer/ambience.png';
import aquaImage from '../../../../../assets/images/drawer/aqua.png';
import aviaryImage from '../../../../../assets/images/drawer/aviary.png';
import chickenImage from '../../../../../assets/images/drawer/chicken.png';
import eggsImage from '../../../../../assets/images/drawer/eggs.png';
import monitoringImage from '../../../../../assets/images/drawer/monitoring.png';
import rationImage from '../../../../../assets/images/drawer/ration.png';
import settingsImage from '../../../../../assets/images/drawer/settings.png';
import silosImage from '../../../../../assets/images/drawer/silos.png';
import { config } from '../../../../../config/env';
import SoonMessage from '@/shared/components/ui/SoonMessage';

export function useMainRoutes(): DrawerSection[] {
    const { data: menuInfo } = useMenuInfo();

    function shouldDisable(disabled: boolean) {
        if (__DEV__) return false;

        return disabled;
    }

    const mainRoutes = useMemo(
        (): DrawerSection[] => [
            {
                routes: [
                    {
                        title: 'Monitoramento',
                        key: 'Monitoring',
                        component: config.mode === 'production' ? SoonMessage : Monitoring,
                        icon: ({ color }) => <Icon name='monitoring' color={color} size={24} />,
                        illustration: monitoringImage,
                        disabled: shouldDisable(true),
                    },
                    {
                        title: 'Aviários',
                        key: 'Aviaries',
                        component: config.mode === 'production' ? SoonMessage : Aviaries,
                        icon: ({ color }) => <Icon name='aviary' color={color} size={24} />,
                        illustration: aviaryImage,
                        disabled: shouldDisable(true),
                    },
                ],
            },
            {
                routes: [
                    {
                        title: 'Aves',
                        key: 'Birds',
                        component: Birds,
                        icon: ({ color }) => <Icon name='chicken' color={color} size={24} />,
                        illustration: chickenImage,
                        disabled: shouldDisable(!menuInfo?.chickens.available),
                        countBadge: menuInfo?.chickens.alert_count,
                    },
                    {
                        title: 'Produção de Ovos',
                        key: 'Places',
                        component: PlaceEggs,
                        icon: ({ color }) => <Icon name='eggs' color={color} size={24} />,
                        illustration: eggsImage,
                        disabled: shouldDisable(!menuInfo?.eggs.available),
                        countBadge: menuInfo?.eggs.alert_count,
                    },
                    {
                        title: 'Consumo de Ração',
                        key: 'RationConsumption',
                        component: RationConsumption,
                        icon: ({ color }) => <Icon name='ration' color={color} size={24} />,
                        illustration: rationImage,
                        disabled: shouldDisable(!menuInfo?.rations.available),
                        countBadge: menuInfo?.rations.alert_count,
                    },

                    {
                        title: 'Consumo de Água',
                        key: 'Aqua',
                        component: Aqua,
                        icon: ({ color }) => <Icon name='aqua' color={color} size={24} />,
                        illustration: aquaImage,
                        disabled: shouldDisable(!menuInfo?.aqua.available),
                        countBadge: menuInfo?.aqua.alert_count,
                    },
                    {
                        title: 'Ambiência',
                        key: 'Sense',
                        component: Sense,
                        icon: ({ color }) => <Icon name='ambience' color={color} size={24} />,
                        illustration: ambienceImage,
                        disabled: shouldDisable(!menuInfo?.ambience.available),
                        countBadge: menuInfo?.ambience.alert_count,
                    },
                ],
            },
            {
                routes: [
                    {
                        title: 'Armazenamento Silos',
                        key: 'Warehouses',
                        component: WarehouseTabs,
                        icon: ({ color }) => <Icon name='silo' color={color} size={24} />,
                        illustration: silosImage,
                        disabled: shouldDisable(!menuInfo?.silos.available),
                        countBadge: menuInfo?.silos.alert_count,
                    },
                ],
            },
            {
                routes: [
                    {
                        title: 'Configurações',
                        key: 'Configurations',
                        component: config.mode === 'production' ? SoonMessage : Configurations,
                        icon: ({ color }) => <Icon name='configurations' color={color} size={24} />,
                        illustration: settingsImage,
                        hide: shouldDisable(true),
                    },
                ],
            },
        ],
        [menuInfo]
    );

    return mainRoutes;
}
