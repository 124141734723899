import { useMemo } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

const isReportsPage = window?.location && Platform.OS === 'web' && window.location.href?.includes('/report/export');

export function useLargeLayout() {
    const dimensions = useWindowDimensions();

    return useMemo(() => {
        if (isReportsPage) return true;

        return dimensions.width >= 768;
    }, [dimensions.width]);
}

export function useIsTablet() {
    const dimensions = useWindowDimensions();

    return useMemo(() => {
        if (isReportsPage) return true;

        return dimensions.width >= 768 && dimensions.width <= 953;
    }, [dimensions.width]);
}
