import { IconNames } from '@alltis/ui';
import { MetricType } from '../types/GenerateReportForm';

export function getMetricIcon(type: MetricType): IconNames {
    const iconMap = {
        ambience: 'ambience',
        bird_count: 'chicken',
        egg_production: 'eggs',
        ration_consumption: 'ration',
        silo_status: 'silo',
        water_consumption: 'aqua',
    } satisfies Record<MetricType, IconNames>;

    return iconMap[type];
}
