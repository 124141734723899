import { Silo } from '@/warehouse/entities/Silo';
import { PageList, Stack, XStack } from '@alltis/ui';
import React from 'react';
import SiloWithInfo from './SiloWithInfo';

type Props = {
    silo: Silo;
};

const SiloCardHeader = ({ silo }: Props) => {
    return (
        <Stack mb={6}>
            <PageList.CardHeader title={silo.name} />

            <PageList.CardContent>
                <XStack justifyContent='space-around'>
                    <SiloWithInfo silo={silo} />
                </XStack>
            </PageList.CardContent>
        </Stack>
    );
};

export default SiloCardHeader;
