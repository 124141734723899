import SiloCard from '@/warehouse/components/SiloCard';
import { useFilterSilos } from '@/warehouse/hooks/silos/useFilterSilos';
import { useSilosRefetch } from '@/warehouse/hooks/useSilosRefetch';
import { PageList } from '@alltis/ui';
import React from 'react';
import { Stack } from 'tamagui';

const Warehouses = () => {
    useSilosRefetch();

    const {
        results: { data, refetch, dataUpdatedAt, isLoading, isError },
    } = useFilterSilos();

    return (
        <Stack flex={1}>
            <PageList
                data={data}
                renderItem={({ item }) => <SiloCard silo={item} />}
                estimatedItemSize={220}
                onRefresh={refetch}
                keyboardShouldPersistTaps='always'
                extraData={dataUpdatedAt}
                isError={isError}
                isLoading={isLoading}
            />
        </Stack>
    );
};

export default Warehouses;
