import React, { PropsWithChildren } from 'react';
import { Svg } from 'react-native-svg';
import { useSvgProps } from '../../hooks/useSvgProps';
import { IconProps } from '../../types';

type Props = PropsWithChildren<IconProps> & {
    viewBox?: string;
    fill?: string;
};

const BaseSvg = (props: Props) => {
    const svgProps = useSvgProps(props);

    return (
        <Svg viewBox={props.viewBox || '0 0 24 24'} fill={props.fill} {...svgProps}>
            {props.children}
        </Svg>
    );
};

export default BaseSvg;
