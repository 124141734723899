import { ParamListBase } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform } from 'react-native';
import { createWebStackNavigator } from '../stacks/User/navigators/createWebNavigator';

export function createAppStackNavigator<T extends ParamListBase>() {
    return Platform.OS === 'web' ? createWebStackNavigator<T>() : createNativeStackNavigator<T>();
}

export function createUserNavigator<T extends ParamListBase>() {
    if (Platform.OS === 'web') {
        return createAppStackNavigator<T>();
    }

    return createNativeStackNavigator<T>();
}
