import * as React from 'react';
import { Path, Rect } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Metrics = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 24 24' fill='none'>
            <Path
                d='M5 4C5 3.44772 5.44772 3 6 3H12.1716C12.4368 3 12.6911 3.10536 12.8787 3.29289L18.7071 9.12132C18.8946 9.30886 19 9.56321 19 9.82843V20C19 20.5523 18.5523 21 18 21H6C5.44771 21 5 20.5523 5 20V4Z'
                stroke={props.color}
                strokeWidth='2'
            />
            <Path d='M12 3L19 10H14C12.8954 10 12 9.10457 12 8V3Z' fill={svg.color} />
            <Rect x='8' y='17' width='6' height='1' fill={svg.color} />
            <Rect x='8' y='15' width='8' height='1' fill={svg.color} />
            <Rect x='8' y='13' width='8' height='1' fill={svg.color} />
        </BaseSvg>
    );
};

export default { name: 'file', icon: Metrics } as const;
