import { GenerateReportFormData } from '@/metrics/types/GenerateReportForm';
import { DatePicker, Stack } from '@alltis/ui';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ReportPopoverOption from '../../ReportPopoverOption';
import dayjs from 'dayjs';

const ReportPopoverPeriodOptions = () => {
    const { control } = useFormContext<GenerateReportFormData>();

    const minDate = useWatch({ control, name: 'period.startDate' });
    const maxDate = useWatch({ control, name: 'period.endDate' });

    return (
        <ReportPopoverOption icon='calendar' title='Período'>
            <Stack gap={8}>
                <Controller
                    control={control}
                    name='period.startDate'
                    render={({ field }) => (
                        <DatePicker suffix='De: ' onChange={field.onChange} value={field.value} maxDate={maxDate || new Date()} />
                    )}
                />

                <Controller
                    control={control}
                    name='period.endDate'
                    render={({ field }) => (
                        <DatePicker
                            suffix='Até: '
                            onChange={field.onChange}
                            value={field.value}
                            minDate={minDate}
                            maxDate={dayjs().subtract(1, 'day').toDate()}
                        />
                    )}
                />
            </Stack>
        </ReportPopoverOption>
    );
};

export default ReportPopoverPeriodOptions;
