import React from 'react';
import { formatBigNumber } from '@legacy/shared/utils/number';
import { Stack, Text } from 'tamagui';
import FontAwesomeIcon from '@expo/vector-icons/FontAwesome5';

type Props = {
    value: number;
};

const BoxCount = ({ value }: Props) => {
    const isEmpty = value === 0;

    return (
        <Stack flexDirection='row' ai='center'>
            <FontAwesomeIcon name='box' color={!isEmpty ? '#A0AEC0' : '#d0d7df'} size={16} />

            {!isEmpty ? (
                <Text color='#7d8794' fontSize={12} ml={10}>
                    Total de{' '}
                    <Text color='$secondary500' fontWeight='500'>
                        {formatBigNumber(value || 0)} caixas
                    </Text>{' '}
                    hoje
                </Text>
            ) : (
                <Text color='#d0d7df' fontSize={12} ml={10}>
                    Nenhuma caixa hoje
                </Text>
            )}
        </Stack>
    );
};

export default BoxCount;
