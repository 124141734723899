import { useQuery } from '@tanstack/react-query';
import { container } from '../../../config/di';
import { AuthApi } from '../api/AuthApi';
import { UserMenu } from '../entities/UserMenu';

export function useMenuInfo() {
    const authApi = container.resolve(AuthApi);

    return useQuery<UserMenu>({
        queryFn: () => authApi.getMenuInfo(),
    });
}
