import { useGetUser } from '@legacy/authentication/hooks/useGetUser';
import EggReportContent from '@legacy/chicken-house/screens/EggsReport/EggReportContent';
import { UseReportExportProps } from '@legacy/reports/hooks/useReportExport';
import { SenseReport } from '@legacy/sense/screens/SenseReport';
import CompanyLogo from '@legacy/user/components/CompanyLogo';
import ConsumptionReport from '@legacy/warehouse/components/SiloReport/Reports/ConsumptionReport';
import ResupplyReport from '@legacy/warehouse/components/SiloReport/Reports/ResupplyReport';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Stack, Text } from 'tamagui';
import { CacheProvider } from '../../../../config/cache';
import { ThemeProvider } from '../../../../config/theme/ThemeProvider';

export type ReportExportScreenProps = {
    type: UseReportExportProps['entityType'];
    startDate: string;
    endDate: string;
    startPeriod: string;
    endPeriod: string;
    ids: string[] | string;
    token: string;
};

const ReportExport = () => {
    const { data: user } = useGetUser();

    const params = new URLSearchParams(document.location.search);

    const parsedParams = useMemo(() => {
        const startDate = dayjs(`${params.get('startDate')} ${params.get('startPeriod')}`, 'YYYY-MM-DD HH:mm')
            .tz('America/Sao_Paulo')
            .toDate();
        const endDate = dayjs(`${params.get('endDate')} ${params.get('endPeriod')}`, 'YYYY-MM-DD HH:mm')
            .tz('America/Sao_Paulo')
            .toDate();

        const ids = typeof params.get('ids') === 'string' ? params.get('ids')?.split(',') : params.get('ids');

        return {
            startDate,
            endDate,
            type: params.get('type'),
            ids: ids,
        };
    }, [params]);

    const reportContent = useMemo(() => {
        const type = params.get('type');

        if (type === 'egg-vision') {
            return (
                <EggReportContent
                    filter={{
                        endDate: parsedParams.endDate,
                        startDate: parsedParams.startDate,
                        chickenHouseIds: parsedParams.ids as string[],
                    }}
                />
            );
        }

        if (type === 'silo-consumption') {
            return (
                <ConsumptionReport
                    enabled
                    filter={{
                        data: {
                            endDate: parsedParams.endDate,
                            entityIds: parsedParams.ids as string[],
                            startDate: parsedParams.startDate,
                        },
                    }}
                />
            );
        }

        if (type === 'silo-resupply') {
            return (
                <ResupplyReport
                    enabled
                    filter={{
                        data: {
                            endDate: parsedParams.endDate,
                            entityIds: parsedParams.ids as string[],
                            startDate: parsedParams.startDate,
                        },
                    }}
                />
            );
        }

        if (type === 'sense') {
            <SenseReport
                initialFilter={{
                    entityIds: parsedParams.ids as string[],
                    startDate: parsedParams.startDate,
                    endDate: parsedParams.endDate,
                }}
                options={{ hideExport: true, hideFilters: true }}
            />;
        }
    }, [params]);

    return (
        <Stack>
            <Stack
                px='$10'
                bg={user?.organization?.header_color || '$primary500'}
                jc='space-between'
                ai='center'
                flexDirection='row'
                minHeight={100}
            >
                <Stack width={200} justifyContent='flex-start'>
                    <Text color={user?.organization.header_font_color || 'white'} whiteSpace='nowrap'>
                        {user?.organization.name || 'Relatório'}
                    </Text>

                    <Text color={user?.organization.header_font_color || 'white'} whiteSpace='nowrap' mt={8}>
                        {dayjs(parsedParams.startDate).format('DD/MM/YYYY HH:mm')} até{' '}
                        {dayjs(parsedParams.endDate).format('DD/MM/YYYY HH:mm')}
                    </Text>
                </Stack>

                <Stack flex={1} jc='center' ai='center'>
                    <CompanyLogo user={user} />
                </Stack>

                <Stack width={300} justifyContent='flex-end'>
                    <Text color={user?.organization.header_font_color || 'white'} textAlign='right' fontSize={12}>
                        Emitido em {dayjs().tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}
                    </Text>
                </Stack>
            </Stack>

            {reportContent}
        </Stack>
    );
};

const StandaloneReportExport = () => (
    <CacheProvider>
        <ThemeProvider>
            <ReportExport />
        </ThemeProvider>
    </CacheProvider>
);

export default StandaloneReportExport;
