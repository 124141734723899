import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { IconProps } from '../types';
import { useSvgProps } from '../hooks/useSvgProps';

const Ambience = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M9 3a3 3 0 1 1 6 0v9.803a6 6 0 1 1-6 0V3Zm2 0a1 1 0 1 1 2 0v11.126A4.002 4.002 0 0 1 12 22a4 4 0 0 1-1-7.874V3Zm1 12a.5.5 0 0 0-.5.5v.563a2 2 0 1 0 1 0V15.5a.5.5 0 0 0-.5-.5Zm4-10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm.5 2.5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'ambience', icon: Ambience } as const;
