import React, { ReactNode } from 'react';
import { useMedia, XStack } from 'tamagui';

type Props = {
    Start: ReactNode;
    Middle: ReactNode;
    End: ReactNode;
};

const SettingGridLayout = (props: Props) => {
    const media = useMedia();

    if (media.sm) {
        return (
            <XStack fw='wrap'>
                <XStack ai='center' gap={16}>
                    {props.Start}
                    {props.Middle}
                </XStack>

                <XStack ai='center' ml='auto'>
                    {props.End}
                </XStack>
            </XStack>
        );
    }

    return (
        <XStack fd='column'>
            <XStack ai='center' mb={10}>
                {props.Middle}
            </XStack>

            <XStack ai='center' jc='space-between'>
                <XStack>{props.Start}</XStack>

                <XStack ml='auto'>{props.End}</XStack>
            </XStack>
        </XStack>
    );
};

export default SettingGridLayout;
