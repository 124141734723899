import React, { useMemo } from 'react';
import { iconSet } from './iconSet';
import { IconProps } from './types';

const Icon = (props: IconProps) => {
    const Icon = useMemo(() => {
        return iconSet.find((icon) => icon.name === props.name)?.icon;
    }, [props.name]);

    if (!Icon) return;

    return <Icon {...props} />;
};

export default Icon;
