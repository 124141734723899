import { CameraRecord } from '@legacy/camera-video/entities/CameraRecord';
import { useCameraRecordings } from '@legacy/camera-video/hooks/useCameraRecordings';
import { FlashList } from '@shopify/flash-list';
import { Image } from 'expo-image';
import React, { useEffect, useRef } from 'react';
import { LayoutAnimation } from 'react-native';
import { Stack } from 'tamagui';
import PlaceRecordingItem from './PlaceRecordingItem';
import StateMessage from '@legacy/shared/components/ui/StateMessage';

const PlaceRecordings = () => {
    const { data: recordings, isFetching } = useCameraRecordings();

    const listRef = useRef<FlashList<CameraRecord>>(null);

    useEffect(() => {
        if (!isFetching) {
            listRef.current?.prepareForLayoutAnimationRender();
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordings]);

    return (
        <Stack flex={1}>
            {!recordings?.length ? (
                <StateMessage
                    flex={1}
                    image={
                        <Image
                            style={{ width: '100%', height: 100 }}
                            source={require('../../../../assets/illustrations/video_illustration.png')}
                            contentFit='contain'
                        />
                    }
                    title='Nenhuma gravação'
                    description='As gravações realizadas pelas câmeras do núcleo irão aparecer aqui'
                />
            ) : (
                <FlashList
                    keyExtractor={(item) => item.id || item.path}
                    ref={listRef}
                    data={recordings}
                    renderItem={({ item: recording }) => <PlaceRecordingItem recording={recording} />}
                    estimatedItemSize={110}
                />
            )}
        </Stack>
    );
};

export default PlaceRecordings;
