import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { interpolate, useAnimatedStyle, useSharedValue, withRepeat, withSequence, withTiming } from 'react-native-reanimated';

type SkeletonTransitionContextType = {
    animatedStyle: {
        opacity: number;
    };

    onMount: () => void;
    onUnmount: () => void;
};

const SharedSkeletonTransitionContext = createContext<SkeletonTransitionContextType>({} as SkeletonTransitionContextType);

export const SharedSkeletonTransitionProvider = ({ children }: PropsWithChildren) => {
    const transitionValue = useSharedValue(1);

    const [enabledSkeletons, setEnabledSkeletons] = useState(0);

    useEffect(() => {
        if (enabledSkeletons > 0) {
            transitionValue.value = withRepeat(
                withSequence(
                    withTiming(0.3, {
                        duration: 1000,
                    }),
                    withTiming(0.7, {
                        duration: 1000,
                    })
                ),
                999,
                true
            );
        }
    }, [enabledSkeletons]);

    const animatedStyle = useAnimatedStyle(
        () => ({
            opacity: interpolate(transitionValue.value, [0, 1], [0, 1]),
        }),
        [transitionValue]
    );

    function onMount() {
        setEnabledSkeletons((count) => count + 1);
    }

    function onUnmount() {
        setEnabledSkeletons((count) => count - 1);
    }

    return (
        <SharedSkeletonTransitionContext.Provider value={{ animatedStyle, onMount, onUnmount }}>
            {children}
        </SharedSkeletonTransitionContext.Provider>
    );
};

export const useSharedSkeletonTransition = () => useContext(SharedSkeletonTransitionContext);
