import * as React from 'react';
import { Path, Rect } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Calendar = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 24 24' fill='none'>
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 6H4C3.44772 6 3 6.44772 3 7V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V7C21 6.44771 20.5523 6 20 6ZM4 2C2.34315 2 1 3.34315 1 5V19C1 20.6569 2.34315 22 4 22H20C21.6569 22 23 20.6569 23 19V5C23 3.34315 21.6569 2 20 2H4Z'
                fill={svg.color}
            />

            <Rect x='5' y='16' width='2' height='2' fill={svg.color} />
            <Rect x='5' y='12' width='2' height='2' fill={svg.color} />
            <Rect x='9' y='16' width='2' height='2' fill={svg.color} />
            <Rect x='9' y='12' width='2' height='2' fill={svg.color} />
            <Rect x='9' y='8' width='2' height='2' fill={svg.color} />
            <Rect x='13' y='16' width='2' height='2' fill={svg.color} />
            <Rect x='13' y='12' width='2' height='2' fill={svg.color} />
            <Rect x='13' y='8' width='2' height='2' fill={svg.color} />
            <Rect x='17' y='12' width='2' height='2' fill={svg.color} />
            <Rect x='17' y='8' width='2' height='2' fill={svg.color} />
        </BaseSvg>
    );
};

export default { name: 'calendar', icon: Calendar } as const;
