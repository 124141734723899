import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { ImageBackground } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import React, { PropsWithChildren } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, TouchableWithoutFeedback } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import LoginBackgroundImage from '../../assets/login-background-desktop.png';
import LoginBackgroundImageMobile from '../../assets/login-background-mobile.png';
import { useMedia } from 'tamagui';

const LoginBackground = (props: PropsWithChildren) => {
    useStatusBar('dark-content');

    const media = useMedia();

    return (
        <LinearGradient style={{ flex: 1 }} colors={['#054256', '#054256', '#054256']}>
            <ImageBackground
                style={{ flex: 1 }}
                source={media.md ? LoginBackgroundImage : LoginBackgroundImageMobile}
                contentFit={media.md ? 'cover' : 'contain'}
                contentPosition='bottom'
            >
                <TouchableWithoutFeedback
                    style={{ flex: 1 }}
                    onPress={() => {
                        if (Platform.OS !== 'web') {
                            Keyboard.dismiss();
                        }
                    }}
                >
                    <SafeAreaView style={{ flex: 1 }}>
                        <KeyboardAvoidingView behavior='padding' enabled style={{ flex: 1 }}>
                            {props.children}
                        </KeyboardAvoidingView>
                    </SafeAreaView>
                </TouchableWithoutFeedback>
            </ImageBackground>
        </LinearGradient>
    );
};

export default LoginBackground;
