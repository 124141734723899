import { Stack } from 'tamagui';
import { FieldBaseProps } from './FieldBaseProps';

export const FieldBase = (props: FieldBaseProps) => {
    return (
        <Stack height={38} bg='#F5F5F5' borderRadius={8} flexDirection='row' {...props}>
            {props.children}

            {props.endAdornment && (
                <Stack h='100%' jc='center' ai='center' pr={18}>
                    {props.endAdornment}
                </Stack>
            )}
        </Stack>
    );
};

export default FieldBase;
