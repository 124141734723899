import { PageList } from '@/alltis-ui-v2';
import { Silo } from '@/warehouse/entities/Silo';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import SiloCardHeader from './SiloCardHeader';

type Props = {
    silo: Silo;
};

const SiloCard = ({ silo }: Props) => {
    const navigation = useNavigation();

    function openSilo() {
        return navigation.navigate('SiloDetails', { id: silo.id });
    }

    return (
        <PageList.Card onPress={openSilo}>
            <SiloCardHeader silo={silo} />
        </PageList.Card>
    );
};

export default SiloCard;
