import NotificationsSettings from '@/configurations/components/Configurations/NotificationsSettings';
import RationTypes from '@/configurations/components/Configurations/RationTypes';
import SpeciesOfBirds from '@/configurations/components/Configurations/SpeciesOfBirds';
import UnitOfMeasure from '@/configurations/components/Configurations/UnitOfMeasure';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';
import { Stack } from '@alltis/ui';
import React from 'react';
import { ScrollView } from 'react-native';
import { useMedia, XStack } from 'tamagui';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';

const Configurations = () => {
    const media = useMedia();

    const scrollStyle = {
        paddingHorizontal: media.md ? 32 : 16,
        paddingVertical: media.md ? 40 : 24,
        backgroundColor: '#EBEBEA',
    };

    if (media.lg) {
        return (
            <ScrollView style={scrollStyle}>
                <AppBar />

                <XStack gap={24}>
                    <Stack flex={1} gap={24}>
                        <UnitOfMeasure />
                        <RationTypes />
                        <SpeciesOfBirds />
                    </Stack>
                    <Stack flex={1} gap={24}>
                        <NotificationsSettings />
                    </Stack>
                </XStack>

                <SafeBottom />
            </ScrollView>
        );
    }

    return (
        <Stack flex={1}>
            <AppBar />

            <ScrollView style={[scrollStyle]}>
                <Stack gap={24}>
                    <UnitOfMeasure />
                    <RationTypes />
                    <SpeciesOfBirds />
                    <NotificationsSettings />

                    <SafeBottom />
                </Stack>
            </ScrollView>
        </Stack>
    );
};

export default Configurations;
