import LabelAndValue from '@/shared/components/ui/LabelAndValue';
import { SiloDeviceStatus, SiloLevelStatus } from '@/warehouse/entities/WarehouseLevel';
import { getDeviceStatusName, getLevelStatusName } from '@/warehouse/utils/silos';
import React, { useMemo } from 'react';
import { Stack, Text, XStack } from 'tamagui';
import SiloStatusIcon from './SiloStatusIcon';

type Props = {
    levelStatus: SiloLevelStatus;
    deviceStatus: SiloDeviceStatus;
};

const WarehouseStatus = ({ deviceStatus, levelStatus }: Props) => {
    const statusName = useMemo(() => {
        if (deviceStatus === 'ACTIVE') {
            return getLevelStatusName(levelStatus);
        }

        return getDeviceStatusName(deviceStatus);
    }, [deviceStatus, levelStatus]);

    return (
        <LabelAndValue
            label='Status'
            value={
                <XStack alignItems='center'>
                    <Stack mr={6}>
                        <SiloStatusIcon deviceStatus={deviceStatus} levelStatus={levelStatus} />
                    </Stack>
                    <Text color='#05455A' fontWeight='600' fontSize={16}>
                        {statusName}
                    </Text>
                </XStack>
            }
            titleProps={{ mb: 10 }}
        />
    );
};

export default WarehouseStatus;
