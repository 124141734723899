import { IconButton, Popover, Stack } from '@alltis/ui';
import React, { useRef } from 'react';
import { useMedia } from 'tamagui';
import ReportPopoverContent from './ReportPopoverContent';

const ReportMetricsButton = () => {
    const media = useMedia();
    const popoverRef = useRef<Popover>(null);

    return (
        <Popover popoverRef={popoverRef}>
            <Popover.Trigger>
                <Stack pointerEvents='none'>
                    <IconButton icon='report' label={media.sm ? 'Relatório' : undefined} />
                </Stack>
            </Popover.Trigger>

            <Popover.Content width={336} height={null}>
                <Popover.Header title='Relatório' />

                <ReportPopoverContent />
            </Popover.Content>
        </Popover>
    );
};

export default ReportMetricsButton;
