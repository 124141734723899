import { MenuView } from '@/shared/components/ui/MenuView';
import { minBuildVersion } from '@/shared/utils/version';
import { Silo } from '@/warehouse/entities/Silo';
import { useViewMapEvent } from '@/warehouse/hooks/events/useViewMapEvent';
import { useUpdateTonsVisibility } from '@/warehouse/hooks/useUpdateTonsVisibility';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Platform } from 'react-native';
import { Stack, StackProps } from 'tamagui';
import { useIsOffline } from '../../../../../hooks/useIsOffline';
import UpdateFeedTypeSheet from './sheets/UpdateFeedTypeSheet';
import { useSiloReportNavigation } from '@/warehouse/hooks/navigation/useSiloReportNavigation';

enum WarehouseAction {
    ToggleTons = 'ToggleTons',
    ChangeFoodType = 'ChangeFoodType',
    ManageFoodTypes = 'ManageFoodTypes',
    ViewInMap = 'ViewInMap',
    ConjugateWarehouse = 'ConjugateWarehouse',
    GenerateReport = 'GenerateReport',
}

type Props = {
    silo: Pick<Silo, 'id' | 'prefer_tons' | 'position' | 'ration' | 'name'>;
    stackProps?: StackProps;
    context: 'list' | 'details';
};

const WarehouseActions = ({ silo, stackProps, context }: Props) => {
    const navigation = useNavigation();
    const isOffline = useIsOffline();

    const { openReportPage } = useSiloReportNavigation();

    const toggleTons = useUpdateTonsVisibility();
    const viewMapEvent = useViewMapEvent();

    const [isFeedSheetOpen, setIsFeedSheetOpen] = useState(false);
    // const [isConjugateSheetOpen, setIsConjugateSheetOpen] = useState(false);

    async function toggleTonsVisibility() {
        toggleTons.mutateAsync({
            siloId: silo.id,
            showTons: !silo.prefer_tons,
        });
    }

    function handleViewInMap() {
        if (context === 'details') {
            navigation.navigate('Warehouses');
        }

        return viewMapEvent.dispatch(silo);
    }

    function handleGenerateReport() {
        openReportPage(silo);
    }

    function handleActionPress(event: WarehouseAction) {
        if (event === WarehouseAction.ToggleTons) {
            return toggleTonsVisibility();
        }

        if (event === WarehouseAction.ChangeFoodType) {
            return setIsFeedSheetOpen(true);
        }

        if (event === WarehouseAction.ConjugateWarehouse) {
            // return setIsConjugateSheetOpen(true);
        }

        if (event === WarehouseAction.ViewInMap) {
            return handleViewInMap();
        }

        if (event === WarehouseAction.ManageFoodTypes) {
            return navigation.navigate('FeedManager');
        }

        if (event === WarehouseAction.GenerateReport) {
            return handleGenerateReport();
        }
    }

    return (
        <Stack {...stackProps}>
            <MenuView
                style={{ alignSelf: 'flex-start' }}
                actions={[
                    {
                        id: WarehouseAction.ViewInMap,
                        title: 'Ver no mapa',
                        attributes: {
                            disabled: !silo.position?.latitude || !silo.position?.longitude,
                        },
                    },
                    {
                        id: WarehouseAction.ToggleTons,
                        title: silo.prefer_tons && Platform.OS === 'android' ? 'Mostrar porcentagem' : 'Mostrar toneladas',
                        attributes: {
                            hidden: !silo.ration?.id,
                        },
                        state: silo.prefer_tons ? 'on' : 'off',
                    },
                    {
                        id: WarehouseAction.ChangeFoodType,
                        title: 'Alterar tipo de ração',
                        attributes: {
                            disabled: isOffline,
                        },
                    },
                    // TODO: conjugate
                    // {
                    //     id: WarehouseAction.ConjugateWarehouse,
                    //     title: 'Conjugar silo',
                    //     attributes: {
                    //         disabled: isOffline,
                    //     },
                    // },
                    {
                        id: WarehouseAction.ManageFoodTypes,
                        title: 'Gerenciar tipos de ração',
                        attributes: {
                            disabled: isOffline,
                        },
                    },
                    {
                        id: WarehouseAction.GenerateReport,
                        title: 'Gerar relatório',
                        attributes: {
                            hidden: !minBuildVersion('1.17.0'),
                        },
                    },
                ]}
                isAnchoredToRight
                title='Opções do Silo'
                onPressAction={({ nativeEvent: { event } }) => handleActionPress(event as WarehouseAction)}
            >
                <Stack p={context === 'list' ? 12 : 0}>
                    <MaterialIcons name='more-vert' size={24} color='#05455a' />
                </Stack>
            </MenuView>

            <UpdateFeedTypeSheet silo={silo} isOpen={isFeedSheetOpen} onClose={() => setIsFeedSheetOpen(false)} />

            {/* TODO: conjugate */}
            {/* <ConjugateWarehouseSheet
                isOpen={isConjugateSheetOpen}
                warehouse={silo}
                onClose={() => setIsConjugateSheetOpen(false)}
            /> */}
        </Stack>
    );
};

export default WarehouseActions;
