import React, { useImperativeHandle, useState } from 'react';
import { PopoverProps, PopoverRef } from './PopoverProps';
import PopoverContent from './components/Content';
import PopoverTrigger from './components/Trigger';
import { PopoverContext } from './context/PopoverContext';
import PopoverHeader from './components/Header';

function Popover(props: PopoverProps) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    useImperativeHandle(props.popoverRef, () => ({
        close: () => setAnchor(null),
    }));

    return (
        <PopoverContext.Provider value={{ anchor: anchor, setAnchor: setAnchor, props }}>
            {props.children}
        </PopoverContext.Provider>
    );
}

Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
Popover.Header = PopoverHeader;

type Popover = PopoverRef;

export default Popover;
