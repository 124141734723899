import { EggVisionRemoteResponse } from '@/chicken-house/api/types/EggVisionResponse';
import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';

export function useEggVision() {
    return useQuery({
        queryKey: ['egg-vision'],
        queryFn: async () => (await alltisApiV2.get<EggVisionRemoteResponse>('/egg-vision/list')).data,
    });
}
