import AppLogo from '@/shared/components/ui/AppLogo';
import { Stack } from '@alltis/ui';
import { useKeyboard } from '@react-native-community/hooks';
import React from 'react';
import { Platform } from 'react-native';
import Animated, { FadeInUp, FadeOutUp } from 'react-native-reanimated';

const LoginAppLogo = () => {
    const { keyboardShown } = useKeyboard();

    const hideLogo = keyboardShown && Platform.OS !== 'web';

    return (
        <>
            {!hideLogo && (
                <Animated.View exiting={FadeOutUp} entering={FadeInUp}>
                    <Stack mb={64}>
                        <AppLogo w='100%' h={40} mode='white' />
                    </Stack>
                </Animated.View>
            )}
        </>
    );
};

export default LoginAppLogo;
