import { Button, Stack, XStack, Text } from '@alltis/ui';
import LoadingScreen from '@/shared/components/ui/LoadingScreen';
import TextField from '@/shared/components/ui/TextField';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { toFixedOrNot } from '@/shared/utils/number';
import { useRationById } from '@/warehouse/hooks/feed/useRationById';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import Slider from '@react-native-community/slider';
import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Platform, TextInput } from 'react-native';
import { useTheme } from 'tamagui';
import { useRationForm } from './hooks/useRationForm';
import { useSubmitRation } from './hooks/useSubmitRation';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';

const FeedRegister = () => {
    useStatusBar('dark-content');

    const textInputRef = useRef<TextInput>(null);

    const { params } = useRoute<RouteProp<UserStackParams, 'FeedRegister'>>();

    const theme = useTheme();
    const ration = useRationById(params?.rationId);

    const { control, formState, handleSubmit } = useRationForm(ration.data);
    const { onSubmitRation, isSaving } = useSubmitRation(params?.rationId);

    function handleSliderChange(value: number) {
        if (!textInputRef.current) return;

        const newText = toFixedOrNot(value, 2);

        if ('value' in textInputRef.current && Platform.OS === 'web') {
            textInputRef.current.value = newText;

            return;
        }

        textInputRef.current?.setNativeProps({ text: newText });
    }

    if (ration.isLoading) {
        return <LoadingScreen />;
    }

    return (
        <Stack p={22}>
            <Controller
                control={control}
                name='name'
                render={({ field: { onChange, ...field } }) => (
                    <TextField
                        icon={<FontAwesome5 name='pen' solid />}
                        placeholder='Digite o nome da ração'
                        onChangeText={onChange}
                        {...field}
                    />
                )}
            />

            <Text color='#A0AEC0' fontSize={12} mt={24}>
                Densidade
            </Text>

            <Controller
                control={control}
                name='density'
                render={({ field: { onChange, value } }) => (
                    <XStack mt={12} alignItems='center'>
                        <Slider
                            style={{ flex: 1, height: 30, marginRight: 12 }}
                            minimumValue={0}
                            maximumValue={3}
                            step={0.1}
                            onValueChange={(value) => {
                                handleSliderChange(value);
                                onChange(value);
                            }}
                            onLayout={() => handleSliderChange(value || 0)}
                            value={value}
                            thumbTintColor={theme.primary500.get() as string}
                            minimumTrackTintColor={theme.primary500.get() as string}
                        />

                        <TextField
                            containerProps={{ w: 100 }}
                            onChangeText={(newValue) => {
                                const numberValue = Number(newValue);

                                if (isNaN(numberValue)) {
                                    return onChange(0);
                                }

                                onChange(numberValue);
                            }}
                            keyboardType='numeric'
                            style={{ fontSize: 22, textAlign: 'center' }}
                            ref={textInputRef}
                            maxLength={4}
                        />
                    </XStack>
                )}
            />

            <Button mt={32} onPress={() => handleSubmit(onSubmitRation)()} loading={isSaving} disabled={!formState.isValid}>
                Salvar Ração
            </Button>
        </Stack>
    );
};

export default FeedRegister;
