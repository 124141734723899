import { Image } from 'expo-image';
import React, { memo } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Stack } from 'tamagui';

import WhiteLogo from '../../../../../../../assets/logo/alltis_logo_white.png';
import SimpleLogo from '../../../../../../../assets/logo/alltis_logo_simple.png';

import { useDrawerConstants } from '../../../hooks/useDrawerConstants';
import { useDrawerCollapsed } from '../../../hooks/useDrawerCollapsed';

const DrawerHeader = () => {
    const topInsets = useSafeAreaInsets();
    const { paddingX } = useDrawerConstants();

    const isCollapsed = useDrawerCollapsed();

    return (
        <Stack flexDirection='row' justifyContent='center' alignItems='center' mt={(topInsets.top || 24) + 8} px={paddingX}>
            <Image source={isCollapsed ? SimpleLogo : WhiteLogo} style={{ width: 120, height: 20 }} contentFit='contain' />
        </Stack>
    );
};

export default memo(DrawerHeader);
