import AviaryEntityCard from '@/aviary/components/Aviaries/AviaryEntityCard';
import { SegmentedControl, Stack, useMedia, Spacer, Text } from '@alltis/ui';
import React from 'react';
import { ScrollView } from 'react-native';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';
import AviaryHeader from '@/aviary/components/Aviaries/AviaryHeader';
import CardLabel from '@/aviary/components/Aviaries/AviaryEntityCard/CardLabel';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';

export const Monitoring = () => {
    const media = useMedia();

    const scrollStyle = {
        paddingHorizontal: media.md ? 32 : 16,
        paddingVertical: media.md ? 40 : 24,
        backgroundColor: '#EBEBEA',
    };

    return (
        <Stack flex={1} bg='#EBEBEA'>
            <Stack flex={1}>
                <AppBar />
                <ScrollView contentContainerStyle={scrollStyle}>
                    <Stack>
                        <AviaryHeader title='Aviário P3' />

                        <Stack gap={26}>
                            {/* chicken */}
                            <AviaryEntityCard
                                title='Número de Aves'
                                icon='chicken'
                                suffix='aves'
                                total={{
                                    label: 'Mortes no Período',
                                    value: 504,
                                }}
                                avg={{
                                    label: 'Média de Mortes no Período',
                                    value: 16,
                                }}
                                isDanger={false}
                            >
                                <Text color='#8CA9B3' mb={18} right={0} top={0} fontWeight={500} $md={{ pos: 'absolute' }}>
                                    85 semanas de vida
                                </Text>

                                <CardLabel
                                    color='#376A7B'
                                    title='Total de Hoje'
                                    quantity={{
                                        value: 39893,
                                        suffix: 'aves',
                                    }}
                                />
                            </AviaryEntityCard>

                            {/* eggs */}
                            <AviaryEntityCard
                                title='Produção de Ovos'
                                icon='eggs'
                                suffix='ovos'
                                total={{
                                    label: 'Total no Período',
                                    value: 1105020,
                                    caption: '3.069 caixas',
                                }}
                                avg={{
                                    label: 'Média no Período',
                                    value: 36834,
                                    caption: '3.069 caixas',
                                }}
                                isDanger
                            >
                                <SegmentedControl
                                    items={[
                                        { label: 'Unidade', value: 'unit' },
                                        { label: '% Postura', value: 'percent' },
                                    ]}
                                    style={{ width: '100%' }}
                                />

                                <Spacer />

                                <CardLabel
                                    color='#376A7B'
                                    title='Total de Hoje'
                                    quantity={{
                                        value: 22679,
                                        suffix: 'ovos',
                                    }}
                                    caption='62 caixas'
                                />
                            </AviaryEntityCard>

                            {/* ration */}
                            <AviaryEntityCard
                                title='Consumo de Ração'
                                icon='ration'
                                suffix='aves'
                                total={{
                                    label: 'Total no Período',
                                    value: 126,
                                }}
                                avg={{
                                    label: 'Média no Período',
                                    value: 4.2,
                                }}
                                isDanger
                            >
                                <SegmentedControl
                                    items={[
                                        { label: 'Dia', value: 'day' },
                                        { label: 'Ave', value: 'bird' },
                                        { label: 'Ovo', value: 'egg' },
                                    ]}
                                    style={{ width: '100%' }}
                                />

                                <Spacer />

                                <CardLabel
                                    title='Total de Hoje'
                                    quantity={{
                                        value: 5.5,
                                        suffix: 't',
                                    }}
                                />
                            </AviaryEntityCard>

                            {/* aqua */}
                            <AviaryEntityCard
                                title='Consumo de Água'
                                icon='aqua'
                                suffix='litros'
                                total={{
                                    label: 'Total no Período',
                                    value: 157290,
                                }}
                                avg={{
                                    label: 'Média no Período',
                                    value: 5243,
                                }}
                                isDanger={false}
                            >
                                <SegmentedControl
                                    items={[
                                        { label: 'Dia', value: 'day' },
                                        { label: 'Ave', value: 'bird' },
                                        { label: 'Ovo', value: 'egg' },
                                    ]}
                                    style={{ width: '100%' }}
                                />

                                <Spacer />

                                <CardLabel
                                    title='Total de Hoje'
                                    quantity={{
                                        value: 5212,
                                        suffix: 'litros',
                                    }}
                                />
                            </AviaryEntityCard>
                        </Stack>
                    </Stack>

                    <SafeBottom />
                </ScrollView>
            </Stack>
        </Stack>
    );
};

export default Monitoring;
