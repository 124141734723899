import { useTesterSettings } from '@/user/store/TesterSettingsStore';
import { useFlag } from '../../feature-flag';

export function useNewUIEnabled() {
    const isFlagActive = useNewUIFlag();

    const useNewUiSetting = useTesterSettings(({ settings }) => settings.shouldUseNewUI);

    return useNewUiSetting || isFlagActive;
}

export function useNewUIFlag() {
    const flag = useFlag('NEW_UI_ENABLED', true);

    if (window?.location?.href?.includes('/metrics/pdf')) return true;

    if (window?.location?.href?.includes('/login')) return false;

    return flag;
}
