import { GetSilosResponse } from '@legacy/warehouse/api/types/SiloResponse';
import { container } from '../../../../config/di';
import { SiloApi } from '../../api/SiloApi';
import { useQuery } from '../../../../config/cache/useQuery';

type Props = {
    refetchOnMount?: boolean;
};

export function useSilos(props?: Props) {
    const warehouseApi = container.resolve(SiloApi);

    return useQuery<GetSilosResponse>({
        queryKey: ['silos'],
        queryFn: () => warehouseApi.getSilos(),
        refetchOnMount: props?.refetchOnMount,
        cacheTime: Infinity,
    });
}
