import { useUserNotifications } from '@/notifications/hooks/useUserNotifications';
import { List } from '@/shared/components/ui/List';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';
import ViewState from '@/shared/components/ui/ViewState';
import { Popover, Separator, Stack } from '@alltis/ui';
import React from 'react';
import NotificationCard from '../NotificationCard';

const NotificationsList = () => {
    const userNotifications = useUserNotifications();

    return (
        <Stack flex={1}>
            <Popover.Header title='Notificações' />

            <List
                data={userNotifications.data || []}
                renderItem={({ item }) => <NotificationCard notification={item} />}
                ItemSeparatorComponent={() => <Separator borderColor='#EBEBEA' />}
                ListFooterComponent={<SafeBottom />}
                estimatedItemSize={79}
                ListEmptyComponent={
                    <Stack height={300}>
                        <ViewState
                            loading={userNotifications.isLoading}
                            empty={{
                                enabled: userNotifications.data?.length === 0,
                                title: 'Nenhuma notificação',
                                message: 'Nenhuma notificação foi encontrada.',
                            }}
                            error={userNotifications.isError}
                        />
                    </Stack>
                }
            />
        </Stack>
    );
};

export default NotificationsList;
