import { Report } from '@legacy/reports/components';
import { SiloReport } from '@legacy/warehouse/entities/WarehouseReportData';
import { useQuantityUnits } from '@legacy/warehouse/hooks/useQuantityUnits';
import React from 'react';
import { Stack } from 'tamagui';

type Props = {
    report: SiloReport;
};

const ResupplyReportContent = ({ report }: Props) => {
    const units = useQuantityUnits(report?.unit === 'tons');

    const averageResupply = units.getValue(report.data?.averageValueInPeriod || 0);

    return (
        <Stack>
            <Report.Card
                title={report.warehouseName}
                items={[
                    {
                        label: 'Média do período',
                        value: units.format(report.data.averageValueInPeriod),
                    },
                    {
                        label: 'Menor reabastecimento no período',
                        value: units.format(report.data.minimumFromPeriod?.value || 0),
                    },
                    {
                        label: 'Maior reabastecimento no período',
                        value: units.format(report.data.averageValueInPeriod),
                    },
                    {
                        label: 'Total no período',
                        value: units.format(report.data.sumFromPeriod?.value || 0),
                    },
                ]}
            />

            <Report.Chart
                averageValue={averageResupply}
                data={report.data.historic.map((item) => {
                    const value = units.getValue(item.value);

                    return { date: item.date, value };
                })}
                legend={[
                    { label: 'Reabastecimento no Período', color: '#8ce142' },
                    { label: 'Média no Período', color: '#E9AD5B' },
                ]}
                colors={{
                    bars: 'rgba(140, 225, 66, 0.3)',
                }}
                maximumFromPeriod={report.data.maximumFromPeriod}
                minimumFromPeriod={report.data.minimumFromPeriod}
                valuePrefix={report.unit === 'perc' ? '%' : 't'}
            />
        </Stack>
    );
};

export default ResupplyReportContent;
