import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { Stack } from 'tamagui';
import { useDrawerCollapsed } from '../../../hooks/useDrawerCollapsed';
import { useDrawerConstants } from '../../../hooks/useDrawerConstants';
import { useDrawerStore } from '../../../store/DrawerStore';

type Props = {
    onPress: () => void;
};

const DrawerOverlay = (props: Props) => {
    const constants = useDrawerConstants();
    const isOverlayVisible = useDrawerStore((store) => store.isOverlayVisible);

    const [shouldRender, setShouldRender] = useState(false);

    const opacity = useSharedValue(0);

    const isCollapsed = useDrawerCollapsed();

    const isVisible = useMemo(() => {
        if (constants.isCollapsable) return !isCollapsed;

        return isOverlayVisible;
    }, [isOverlayVisible, constants.isCollapsable, isCollapsed]);

    useEffect(() => {
        setShouldRender(true);

        opacity.value = withTiming(isVisible ? 0.7 : 0, { duration: 300 }, () => {
            runOnJS(setShouldRender)(isVisible);
        });
    }, [isVisible]);

    const animatedStyle = useAnimatedStyle(
        () => ({
            opacity: opacity.value,
        }),
        [opacity]
    );

    if (!shouldRender || !constants.enableCustomOverlay) return null;

    return (
        <Animated.View
            style={[
                animatedStyle,
                {
                    width: Dimensions.get('screen').width,
                    height: '100%',
                    backgroundColor: '#000000',
                    position: 'absolute',
                },
            ]}
        >
            <Stack flex={1} onPress={() => props.onPress?.()} />
        </Animated.View>
    );
};

export default DrawerOverlay;
