import Login from '@/authentication/screens/Login';
import Menu from '@/user/components/Profile/Menu';
import React from 'react';
import { createAppStackNavigator } from '../../helpers/createAppStackNavigator';

export type GuestStackParams = {
    Login: undefined;
    Settings: undefined;
};

const Guest = createAppStackNavigator<GuestStackParams>();

const GuestStack = () => {
    return (
        <Guest.Navigator
            screenOptions={{
                headerTintColor: '#05455A',
            }}
        >
            <Guest.Screen
                name='Login'
                component={Login}
                options={{
                    headerShown: false,
                    headerTitleAlign: 'center',
                }}
            />

            <Guest.Screen
                name='Settings'
                component={Menu}
                options={{
                    headerTitle: 'Configurações',
                }}
            />
        </Guest.Navigator>
    );
};

export default GuestStack;
