import { useChartWidth } from '@legacy/shared/components/ui/ScrollableChart/useChartWidth';
import { toFixedOrNot } from '@legacy/shared/utils/number';
import { SiloResupply } from '@legacy/warehouse/entities/Silo';
import { useQuantityUnits } from '@legacy/warehouse/hooks/useQuantityUnits';
import dayjs from 'dayjs';
import React from 'react';
import { View, useWindowDimensions } from 'react-native';
import { BarChart } from 'react-native-chart-kit';

type Props = {
    consumption: SiloResupply[];
    isTons?: boolean;
};

const FeedConsumptionChart = ({ consumption, isTons = false }: Props) => {
    const quantityUnits = useQuantityUnits(isTons);

    const chartWidth = useChartWidth();

    const consumptionData = consumption.map((item) => {
        return quantityUnits.getValue({
            perc: item.percentage,
            tons: item.tons,
        });
    });

    const consumptionLabels = consumption.map((item) => dayjs(item.timestamp).format('DD/MM').toString());

    function getMaxPercentage() {
        const maxNumber = Math.max(...consumptionData);

        return Math.ceil(maxNumber / 5) * 5;
    }

    const suffix = isTons ? 't' : '%';

    return (
        <View>
            <BarChart
                fromZero
                fromNumber={isTons ? 25 : getMaxPercentage()}
                showValuesOnTopOfBars
                segments={isTons ? undefined : 6}
                data={{
                    labels: consumptionLabels,
                    datasets: [
                        {
                            data: consumptionData,
                        },
                    ],
                }}
                chartConfig={{
                    backgroundGradientFrom: '#ffffff',
                    backgroundGradientTo: '#ffffff',
                    decimalPlaces: 1,
                    color: (opacity = 1) => `rgba(94, 94, 94, ${opacity})`,
                    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                    formatTopBarValue: (value) => `${toFixedOrNot(value, 1)}${suffix}`,
                }}
                height={200}
                width={chartWidth - 48}
                yAxisLabel=''
                yAxisSuffix={isTons ? 't' : '%'}
            />
        </View>
    );
};

export default FeedConsumptionChart;
