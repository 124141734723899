import { useEffect } from 'react';
import { useSetupPushNotifications } from '../../../config/notifications/useSetupPushNotifications';
import { HomeTabs } from '../HomeTabs';

export const MainScreen = () => {
    const notifications = useSetupPushNotifications();

    useEffect(() => {
        notifications.registerForPushNotificationsAsync();
    }, []);

    return <HomeTabs />;
};
