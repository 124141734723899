import DatePicker from '@legacy/shared/components/ui/DatePicker';
import MultiSelect from '@legacy/shared/components/ui/MultiSelect';
import SectionContainer from '@legacy/shared/components/ui/SectionContainer';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import { Stack } from 'tamagui';
import { ReportProps } from '../screens/Report/ReportProps';

type Props = Pick<ReportProps<unknown>, 'filter' | 'selectProps' | 'items'>;

const ReportControlledForm = ({ filter, ...props }: Props) => {
    useStatusBar('dark-content');

    return (
        <SectionContainer title='Parâmetros do Relatório' containerProps={{ p: 0, px: 16, mb: 8 }}>
            <Stack ai='center' jc='center' mt={12} flexDirection='row'>
                <Stack flex={1}>
                    <DatePicker
                        label='Data inicial'
                        placeholder='Insira a data inicial'
                        value={filter.data.startDate || new Date()}
                        onChange={(startDate) => filter.onFilterChange?.({ ...filter.data, startDate: startDate || new Date() })}
                    />
                </Stack>

                <Stack flex={1} ml={12}>
                    <DatePicker
                        label='Data final'
                        placeholder='Insira a data final'
                        value={filter.data.endDate || new Date()}
                        onChange={(endDate) => filter.onFilterChange?.({ ...filter.data, endDate: endDate || new Date() })}
                        minDate={filter.data.startDate}
                    />
                </Stack>
            </Stack>

            <Stack mt={12}>
                <MultiSelect
                    items={props.items || []}
                    icon={props.selectProps?.icon || <></>}
                    label={props.selectProps?.placeholder || 'Selecione os items'}
                    onChange={(items) => filter.onFilterChange?.({ ...filter.data, entityIds: items as string[] })}
                    value={filter.data.entityIds || []}
                />
            </Stack>
        </SectionContainer>
    );
};

export default ReportControlledForm;
