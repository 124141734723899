// CustomStackNavigator.tsx
import WebDialogPage from '@/alltis-ui-v2/navigation/components/WebDialogPage';
import {
    createNavigatorFactory,
    DefaultNavigatorOptions,
    EventMapBase,
    NavigationState,
    ParamListBase,
    StackRouter,
    StackRouterOptions,
    useNavigationBuilder,
} from '@react-navigation/native';
import React from 'react';
import { Platform } from 'react-native';

type Props = DefaultNavigatorOptions<ParamListBase, NavigationState, Record<string, unknown>, EventMapBase> & StackRouterOptions;

function WebNavigator(props: Props) {
    const { initialRouteName, children, screenOptions } = props;

    const { state, descriptors } = useNavigationBuilder(StackRouter, {
        initialRouteName,
        children,
        screenOptions,
    });

    return (
        <>
            {state.routes.map((route) => {
                const descriptor = descriptors[route.key];
                const { options } = descriptor;

                const isModal = options.presentation === 'modal';

                if (Platform.OS === 'web' && isModal) {
                    return (
                        <WebDialogPage key={route.key} descriptor={descriptor}>
                            {descriptor.render()}
                        </WebDialogPage>
                    );
                }

                return descriptor.render();
            })}
        </>
    );
}

export const createWebStackNavigator = createNavigatorFactory(WebNavigator);
