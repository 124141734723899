import { PortalProvider } from '@gorhom/portal';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context';
import AppSwitch from './src/AppSwitch';
import { FeatureFlagProvider } from './src/config/feature-flag';
import { ErrorHandlerProvider } from './src/config/logging/ErrorHandlerProvider';

import './src/config/locale';
import './src/config/logging';
import './src/config/orientation';
import { NavigationContainer } from './src/navigation/NavigationContainer';
import { useInitialAppLoading } from './src/hooks/useInitialAppLoading';
import ReportExport from '@legacy/reports/screens/ReportExport';

function App() {
    const { onNavigationReady } = useInitialAppLoading();

    return (
        <FeatureFlagProvider>
            <ErrorHandlerProvider>
                <GestureHandlerRootView style={{ flex: 1 }}>
                    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
                        <PortalProvider>
                            {window?.location?.href?.includes('/report/export') ? (
                                <ReportExport />
                            ) : (
                                <NavigationContainer onReady={onNavigationReady}>
                                    <AppSwitch />
                                </NavigationContainer>
                            )}
                        </PortalProvider>
                    </SafeAreaProvider>
                </GestureHandlerRootView>
            </ErrorHandlerProvider>
        </FeatureFlagProvider>
    );
}

export default App;
