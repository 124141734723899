import React from 'react';
import RNSegmentedControl from 'react-native-segmented-control-2';
import { SegmentedControlProps } from './SegmentedControlProps';

const UnitOfMeasure = (props: SegmentedControlProps) => {
    const selectedIndex = props.items?.findIndex((item) => item.value === props.value);

    return (
        <RNSegmentedControl
            tabs={props.items?.map((item) => item.label) || []}
            activeTextColor='#376A7B'
            {...props}
            onChange={(index: number) => {
                props.onChange?.(props.items?.[index]);
            }}
            value={selectedIndex === -1 ? undefined : selectedIndex}
            textStyle={[
                props.textStyle,
                {
                    color: '#8CA9B3',
                },
            ]}
            style={[
                props.style,
                {
                    backgroundColor: '#F5F5F5',
                },
            ]}
        />
    );
};

export default UnitOfMeasure;
