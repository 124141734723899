import SectionContainer from '@legacy/shared/components/ui/SectionContainer';
import ViewState from '@legacy/shared/components/ui/ViewState';
import React, { PropsWithChildren } from 'react';
import { Stack } from 'tamagui';

type Props = PropsWithChildren<{
    title: string;
    options: {
        loading: boolean;
        empty: boolean;
        error: boolean;
    };
}>;

const ReportContent = (props: Props) => {
    return (
        <Stack flex={1}>
            <SectionContainer title={props.title} containerProps={{ p: 0, px: 0, flex: 1 }}>
                {props.options.loading || props.options.empty || props.options.error ? (
                    <ViewState {...props.options} />
                ) : (
                    props.children
                )}
            </SectionContainer>
        </Stack>
    );
};

export default ReportContent;
