import { useUser } from '@legacy/authentication/store/UserStore';
import { User as ConfigCatUser, useFeatureFlag } from 'configcat-react';
import { useMemo } from 'react';
import { Platform } from 'react-native';

export function useFlag(flag: string, defaultValue = false) {
    const user = useUser((store) => store.user);

    const flagUser = useMemo((): ConfigCatUser | undefined => {
        if (!user) return undefined;

        return {
            identifier: user.id,
            email: user.email,
            custom: {
                platform: Platform.OS,
            },
        };
    }, [user]);

    const { value } = useFeatureFlag(flag, defaultValue, flagUser);

    return value;
}
