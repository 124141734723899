import { useMedia } from 'tamagui';

export function useResponsiveChart(width?: number) {
    const media = useMedia();

    if (width) return width;

    if (media.xl) return 1400;

    if (media.lg) return 900;

    if (media.md) return 550;

    if (media.sm) return 580;

    return 400;
}
