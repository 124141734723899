import { useEggReportNavigation } from '@/chicken-house/hooks/navigation/useEggReportNavigation';
import React from 'react';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';

const EggManagerAppBar = () => {
    const eggReportNavigation = useEggReportNavigation();

    return <AppBar onReportPress={() => eggReportNavigation.openReportPage()} />;
};

export default EggManagerAppBar;
