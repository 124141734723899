import { useMenuInfo } from '@/authentication/hooks/useMenuInfo';
import { Image } from 'expo-image';
import React from 'react';
import { Stack, Text } from 'tamagui';
import Spinner from '../Spinner';
import soonIcon from './assets/soon-icon.png';

const SoonMessage = () => {
    const { data } = useMenuInfo();

    if (!data) {
        return (
            <Stack flex={1} jc='center' ai='center' px={20} bg='#EBEBEA'>
                <Spinner />
            </Stack>
        );
    }

    return (
        <Stack flex={1} jc='center' ai='center' px={20} bg='#EBEBEA'>
            <Image source={soonIcon} style={{ width: 197, height: 200 }} />

            <Text color='#376A7B' fontWeight={600} fontSize={18} $md={{ fontSize: 24 }} mt={40} mb={8} textAlign='center'>
                Sistema em Desenvolvimento...
            </Text>
            <Text color='#8CA9B3' fontWeight={400} fontSize={14} $md={{ fontSize: 16 }} textAlign='center'>
                Em breve, mais uma solução com tecnologia ALLTIS.
            </Text>
        </Stack>
    );
};

export default SoonMessage;
