import { useCameraSetupEnabled } from '@legacy/chicken-house/hooks/permissions/useCameraSetupEnabled';
import { useCameraServer } from '@legacy/chicken-house/store/CameraServerStore';
import {
    CameraNetworkConfig,
    cameraNetworkConfigSchema,
} from '@legacy/chicken-house/utils/validators/cameraNetworkConfigValidator';
import Button from '@legacy/shared/components/ui/Button';
import TextField from '@legacy/shared/components/ui/TextField';
import { useScreenLoading } from '@legacy/shared/hooks/useScreenLoading';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Platform, Switch } from 'react-native';
import Animated, { FadeIn, FadeInRight, FadeOut, Layout } from 'react-native-reanimated';
import { Stack, Text, useTheme } from 'tamagui';

const CameraNetworkConfigForm = () => {
    const theme = useTheme();
    const isCameraSetupEnabled = useCameraSetupEnabled();

    const { onSetup, networkConfig } = useCameraServer(({ onSetup, networkConfig }) => ({ onSetup, networkConfig }));

    const networkConfigForm = useForm<CameraNetworkConfig>({
        defaultValues: {
            networkPath: networkConfig?.networkPath ?? '/cam/realmonitor?channel=1&subtype=0',
            auth: {
                username: networkConfig?.auth?.username ?? '',
                password: networkConfig?.auth?.password ?? '',
                enableAuth: networkConfig?.auth?.enableAuth ?? true,
            },
        },
        mode: 'all',
        resolver: yupResolver(cameraNetworkConfigSchema),
    });

    const shouldUseAuth = useWatch({
        name: 'auth.enableAuth',
        control: networkConfigForm.control,
    });

    const navigation = useNavigation();

    const isLoading = useScreenLoading();

    function onNextPress(config: CameraNetworkConfig) {
        onSetup(config);

        if (isCameraSetupEnabled) {
            return navigation.navigate('PlaceEditor');
        }

        return navigation.navigate('Home');
    }

    return (
        <Animated.View style={{ flex: 1 }} entering={isLoading ? undefined : FadeInRight}>
            <Stack p={22}>
                <Text color='#A0AEC0' fontSize={14} mb='$3'>
                    Configure o caminho padrão da URL para acesso às câmeras
                </Text>

                <Controller
                    control={networkConfigForm.control}
                    name='networkPath'
                    render={({ field: { onChange, ...field } }) => (
                        <TextField
                            icon={<FontAwesome5 name='globe' solid />}
                            placeholder='URL Padrão'
                            onChangeText={onChange}
                            helperText='Exemplo: /cam/realmonitor'
                            containerProps={{ mb: '$7' }}
                            {...field}
                        />
                    )}
                />

                <Text color='#A0AEC0' fontSize={14} mb='$5'>
                    Para realizar a autenticação nas câmeras, você precisa configurar as credenciais do servidor, se necessário.
                    Se não for necessário, desmarque a caixa abaixo
                </Text>

                <Stack mb='$5' fd='row' ai='center'>
                    <Switch
                        value={shouldUseAuth}
                        trackColor={{ true: theme.primary500.get() as string, false: '#eeeeee' }}
                        thumbColor={Platform.OS === 'android' ? '#ffffff' : undefined}
                        onChange={() =>
                            networkConfigForm.setValue('auth.enableAuth', !shouldUseAuth, {
                                shouldValidate: true,
                            })
                        }
                    />

                    <Text color='$color.secondary500' ml={8}>
                        Usar autenticação
                    </Text>
                </Stack>

                <Animated.View>
                    {shouldUseAuth && (
                        <Animated.View entering={FadeIn} exiting={FadeOut}>
                            <Controller
                                control={networkConfigForm.control}
                                name='auth.username'
                                render={({ field: { onChange, ...field } }) => (
                                    <TextField
                                        icon={<FontAwesome5 name='user' solid />}
                                        placeholder='Usuário'
                                        containerProps={{ mb: 10 }}
                                        onChangeText={onChange}
                                        autoCapitalize='none'
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                control={networkConfigForm.control}
                                name='auth.password'
                                render={({ field: { onChange, ...field } }) => (
                                    <TextField
                                        icon={<FontAwesome5 name='lock' solid />}
                                        placeholder='Senha'
                                        onChangeText={onChange}
                                        containerProps={{ mb: '$7' }}
                                        {...field}
                                    />
                                )}
                            />
                        </Animated.View>
                    )}

                    <Animated.View layout={Layout.springify().damping(1000).mass(0.4).duration(1)}>
                        <Button
                            iconAfter={<FontAwesome5 name='arrow-right' solid />}
                            disabled={!networkConfigForm.formState.isValid}
                            onPress={networkConfigForm.handleSubmit(onNextPress)}
                        >
                            Avançar
                        </Button>
                    </Animated.View>
                </Animated.View>
            </Stack>
        </Animated.View>
    );
};

export default CameraNetworkConfigForm;
