import { useExportMetricsFile } from '@/metrics/hooks/useExportMetricsFile';
import { ReportMetricResultParams } from '@/metrics/screens/ReportMetricResult/params';
import { HeaderRight, IconButton, Text } from '@alltis/ui';
import React, { useState } from 'react';

type Props = {
    params: ReportMetricResultParams;
};

const ReportMetricResultHeader = ({ params }: Props) => {
    const [exportingFormat, setExportingFormat] = useState<'csv' | 'xlsx' | 'pdf' | null>(null);

    const { mutateAsync: exportMetricsFile } = useExportMetricsFile();

    function handleExport(format: 'xlsx' | 'pdf') {
        setExportingFormat(format);
        exportMetricsFile({ params, format }).finally(() => setExportingFormat(null));
    }

    return (
        <HeaderRight>
            <Text color='#698F9C' fontSize={12} mr={4}>
                Exportar
            </Text>

            <IconButton
                icon='file'
                label='XLSX'
                onPress={() => handleExport('xlsx')}
                loading={exportingFormat === 'xlsx'}
                disabled={!!exportingFormat}
            />

            <IconButton
                icon='file'
                label='PDF'
                onPress={() => handleExport('pdf')}
                loading={exportingFormat === 'pdf'}
                disabled={!!exportingFormat}
            />
        </HeaderRight>
    );
};

export default ReportMetricResultHeader;
