import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { useQuery } from '../../../config/cache/useQuery';
import { UserNotification } from '../types/UserNotification';

export function useUserNotifications() {
    return useQuery<UserNotification[]>({
        queryKey: ['user-notifications'],
        queryFn: async () => (await alltisApiV2.get('/user/notifications')).data,
    });
}
