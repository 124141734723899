import React from 'react';
import EggProductionSummaryCard from '../EggProductionSummaryCard';
import { useEggVision } from '@/chicken-house/hooks/egg-production/useEggVision';
import { Skeleton, SkeletonView, Stack } from '@alltis/ui';

const EggProductionTotalSummary = () => {
    const eggVision = useEggVision();

    if (eggVision?.isLoading) {
        return (
            <Skeleton>
                <SkeletonView height={200} />
            </Skeleton>
        );
    }

    return (
        <Stack gap={24}>
            {eggVision?.data?.extras?.map((result) => (
                <EggProductionSummaryCard key={result.egg_vision.id} eggVision={result} />
            ))}
        </Stack>
    );
};

export default EggProductionTotalSummary;
