import React from 'react';
import { Text } from 'react-native-svg';

type Props = {
    label: string;
    x: number;
    y: number;
};

const SiloPercentageText = ({ label, x, y }: Props) => {
    return (
        <Text fill='#2d3336' fontSize={20} x={x} y={y} textAnchor='middle' fontWeight={700}>
            {label}
        </Text>
    );
};

export default SiloPercentageText;
