import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Logout = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 16 16'>
            <Path
                fill={svg.color}
                d='M4 7L12.15 7L9.55 4.4L11 3L16 8L11 13L9.55 11.6L12.15 9L4 9L4 7ZM-8.74228e-08 2C-6.33815e-08 1.45 0.195833 0.979166 0.5875 0.587499C0.979166 0.195833 1.45 -6.36001e-07 2 -6.11959e-07L5 -4.80825e-07L5 2L2 2L2 14L5 14L5 16L2 16C1.45 16 0.979165 15.8042 0.587499 15.4125C0.195833 15.0208 -6.36001e-07 14.55 -6.11959e-07 14L-8.74228e-08 2Z'
            />
        </BaseSvg>
    );
};

export default { name: 'logout', icon: Logout } as const;
