import { Stack, Text } from '@alltis/ui';
import dayjs from 'dayjs';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    title: string;
    period: {
        startDate: Date;
        endDate: Date;
    };
}>;

export const ReportResultContainer = (props: Props) => {
    return (
        <>
            <Stack
                height={40}
                borderTopLeftRadius={8}
                borderTopRightRadius={8}
                bg='#588290'
                jc='center'
                px={12}
                $md={{ px: 34, ai: 'center' }}
            >
                <Text fontWeight={500} fontSize={18} color='#ffffff'>
                    {props.title}
                </Text>

                <Text fontWeight={400} fontSize={14} color='#E6ECEF' position='absolute' right={12} $md={{ right: 34 }}>
                    {dayjs(props.period.startDate).format('DD/MM/YYYY')} - {dayjs(props.period.endDate).format('DD/MM/YYYY')}
                </Text>
            </Stack>

            {props.children}
        </>
    );
};
