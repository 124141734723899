import CardIcon from '@/alltis-ui-v2/components/PageList/components/PageListCard/CardIcon';
import { Icon, IconNames, LineChart, Stack, Text, useMedia, XStack } from '@alltis/ui';
import React, { PropsWithChildren } from 'react';
import CardLabel from './CardLabel';

type Props = PropsWithChildren<{
    title: string;
    icon: IconNames;
    isDanger?: boolean;
    suffix: string;
    total: {
        label: string;
        value: number;
        caption?: string;
    };
    avg: {
        label: string;
        value: number;
        caption?: string;
    };
}>;

const AviaryEntityCard = ({ icon, isDanger, title, total, avg, suffix, children }: Props) => {
    const media = useMedia();

    return (
        <Stack br={8} backgroundColor='#fff' padding={16} px={18} py={24} $md={{ h: 180, px: 32, py: 24 }}>
            <Stack gap={12} ai='center' flexDirection='row' $md={{ position: 'absolute' }}>
                <Icon name={icon} size={24} color='#588290' />

                <Text color='#8CA9B3' fontWeight='500' fontSize={18}>
                    {title}
                </Text>
            </Stack>

            <Stack gap={32} flex={1} $md={{ fd: 'row' }}>
                <Stack
                    gap={16}
                    position='relative'
                    width='100%'
                    mt={16}
                    $md={{ ai: 'flex-end', jc: 'flex-end', width: '40%', gap: 32, fd: 'row', mt: 0 }}
                >
                    <CardLabel
                        color='#376A7B'
                        title={total.label}
                        quantity={{
                            value: total.value,
                            suffix: `${suffix}/dia`,
                        }}
                        caption={total.caption}
                    />

                    <CardLabel
                        color={isDanger ? '#8DC044' : '#8C1913'}
                        title={avg.label}
                        quantity={{
                            value: avg.value,
                            suffix: `${suffix}/dia`,
                        }}
                        caption={avg.caption}
                    />

                    {isDanger && (
                        <Stack position='absolute' top={0} right={0}>
                            <CardIcon icon={icon} isDanger />
                        </Stack>
                    )}
                </Stack>

                <Stack $md={{ fd: 'row', flex: 1 }}>
                    <Stack flex={1} transform='translateY(-28px)' height={130}>
                        <LineChart data={{}} height={160} isAutoWidth={media.md} />
                    </Stack>

                    <Stack
                        mt={24}
                        jc='flex-end'
                        width='100%'
                        flex={1}
                        position='relative'
                        $md={{ width: '30%', ai: 'flex-end', mt: 0 }}
                    >
                        {children}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AviaryEntityCard;
