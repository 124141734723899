import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { MetricType } from '../types/GenerateReportForm';
import { MetricExportResponse } from '../types/MetricExportResponse';

export type ExportMetricsInput = {
    all_aviaries?: boolean;
    aviary_id?: string[];
    format?: 'json' | 'csv' | 'xlsx' | 'pdf';
    final_period?: Date;
    initial_period?: Date;
    types: MetricType[];
};

export function useMetricResults(input: ExportMetricsInput) {
    return useQuery({
        queryKey: ['export-metrics', input],
        queryFn: async () => {
            const response = await alltisApiV2.get<ExportMetricsInput, AxiosResponse<MetricExportResponse>>('/metrics/export', {
                params: input,
            });

            return response.data?.filter((res) => !!res.aviary_name);
        },
    });
}
