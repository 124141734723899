import { Stack } from '@alltis/ui';
import React from 'react';
import MapView from 'react-native-maps';

import { expo } from '../../../../../../app.json';

type Props = {
    borderless?: boolean;
};

const MonitoringMap = ({ borderless }: Props) => {
    return (
        <Stack flex={1} bg='white' position='relative' br={borderless ? 0 : 8} overflow='hidden'>
            <MapView
                style={{ flex: 1, backgroundColor: '#eeeeee' }}
                mapType='satellite'
                provider='google'
                // @ts-ignore required for react-native-maps-web
                googleMapsApiKey={expo?.android?.config?.googleMaps?.apiKey}
                customMapStyle={[
                    {
                        stylers: [{ saturation: -80 }, { lightness: -30 }, { gamma: 0.9 }],
                    },
                ]}
            />
        </Stack>
    );
};

export default MonitoringMap;
