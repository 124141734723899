import { Icon, IconNames, Stack, Text, XStack } from '@alltis/ui';
import React, { PropsWithChildren, ReactNode } from 'react';

type Props = {
    children: ReactNode;
    title: string;
    icon: IconNames;
};

const MonitoringBaseCard = (props: Props) => {
    return (
        <Stack p={16} $md={{ p: 32 }} py={24} br={8} backgroundColor='#ffffff' position='relative'>
            <Stack gap={12} ai='center' position='relative' $md={{ position: 'absolute' }} flexDirection='row'>
                <Icon name={props.icon} size={24} color='#588290' />
                <Text color='#8CA9B3' fontWeight='500' fontSize={18}>
                    {props.title}
                </Text>
            </Stack>

            <Stack flexDirection='column' $md={{ flexDirection: 'row' }}>
                {props.children}
            </Stack>
        </Stack>
    );
};

export const MonitoringBaseCardChart = (props: PropsWithChildren) => {
    return (
        <Stack w='50%' mt={12}>
            {props.children}
        </Stack>
    );
};

export const MonitoringBaseCardInfo = (props: PropsWithChildren) => {
    return (
        <Stack mt={24} $md={{ mt: 0, ml: 'auto', alignSelf: 'flex-end' }}>
            {props.children}
        </Stack>
    );
};

export default MonitoringBaseCard;
