import { Stack, Text } from '@alltis/ui';
import { PropsWithChildren } from 'react';
import { Platform } from 'react-native';
import Animated, { LinearTransition } from 'react-native-reanimated';
import { View } from 'tamagui';

const Container = Platform.OS === 'web' ? View : Animated.View;

const LoginFormWrapper = (props: PropsWithChildren) => {
    return (
        <Container layout={LinearTransition}>
            <Stack backgroundColor='#4d798770' p={32} borderRadius={16}>
                <Text color='#8DC044' fontWeight={600} fontSize={18} textAlign='center' mb={32}>
                    Plataforma IRIS
                </Text>

                {props.children}
            </Stack>
        </Container>
    );
};

export default LoginFormWrapper;
