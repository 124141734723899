import HeavyScreen from '@/shared/components/ui/HeavyScreen';
import LoadingScreen from '@/shared/components/ui/LoadingScreen';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import SiloViewState from '@/warehouse/components/SiloViewState';
import WarehouseActions from '@/warehouse/components/SiloCard/WarehouseActions';
import WarehouseFeedDetails from '@/warehouse/components/WarehouseDetails/WarehouseFeedDetails';
import WarehouseHeader from '@/warehouse/components/WarehouseDetails/WarehouseHeader';
import { useSiloById } from '@/warehouse/hooks/silos/useSiloById';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useCurrentSiloID } from './hooks/useCurrentSiloID';

const WarehouseDetails = () => {
    useStatusBar('dark-content');

    const navigation = useNavigation();

    const siloId = useCurrentSiloID();

    const { data: silo, isLoading } = useSiloById(siloId);

    useEffect(() => {
        if (!silo) return;

        navigation.setOptions({
            headerRight: () => <WarehouseActions silo={silo} context='details' />,
        });
    }, [navigation, silo]);

    if (isLoading || !silo) {
        return <SiloViewState hasSilo={!!siloId} loading={isLoading} />;
    }

    return (
        <HeavyScreen placeholder={<LoadingScreen />}>
            <ScrollView>
                <WarehouseHeader silo={silo} />
                <WarehouseFeedDetails silo={silo} />
            </ScrollView>
        </HeavyScreen>
    );
};

export default WarehouseDetails;
