import React from 'react';
import { G, Path, Rect } from 'react-native-svg';
import { SiloPartProps } from './props';
import { constants } from './constants';

export const SiloBase = (props: SiloPartProps) => {
    const legsWidth = 5;
    const legsOffsetY = 20;

    const height = props.height || 0;
    const width = props.width || 103;

    const legsHeight = height + legsOffsetY;

    const getCenterPoints = () => {
        return `M0,0 L${width / 2},${height} L${width},0 L0,0 Z`;
    };

    const getLeftSide = () => {
        return `M0,0 L${width / 2},${height}`;
    };

    const getRightSide = () => {
        return `M${width},0 L${width / 2},${height}`;
    };

    const createSiloBottomPoint = (options: { width?: number; height?: number; offsetY?: number }) => {
        const rectWidth = options?.width || 20;
        const rectHeight = options?.height || 5;

        const x = width / 2 - rectWidth / 2;

        const y = height - 10 - (options?.offsetY || 0);

        return (
            <>
                <Rect width={rectWidth} height={rectHeight} x={x} y={y} fill='#A0B4BC' stroke='none' />
                <Rect width={rectWidth} height={100} x={x} y={y + rectHeight} fill='#ffffff' stroke='none' />
            </>
        );
    };

    return (
        <>
            {/* Bottom Silo Content */}
            <G>
                <Path d={getCenterPoints()} strokeLinejoin='round' />
                <Path d={getLeftSide()} strokeWidth={constants.silo.stroke.width} stroke={constants.silo.stroke.color} />
                <Path d={getRightSide()} strokeWidth={constants.silo.stroke.width} stroke={constants.silo.stroke.color} />

                {createSiloBottomPoint({ width: 16, height: 0, offsetY: 0 })}
            </G>

            {/* Silo Legs */}
            <Rect
                x={-10}
                y={legsOffsetY * -1}
                width={legsWidth}
                height={legsHeight}
                fill='#9BB5BD'
                strokeWidth={constants.silo.stroke.width}
                stroke={constants.silo.stroke.color}
            />

            <Rect
                x={width + 5}
                y={legsOffsetY * -1}
                width={legsWidth}
                height={legsHeight}
                fill='#9BB5BD'
                strokeWidth={constants.silo.stroke.width}
                stroke={constants.silo.stroke.color}
            />
        </>
    );
};
