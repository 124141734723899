import DatePicker from '@legacy/shared/components/ui/DatePicker';
import SectionContainer from '@legacy/shared/components/ui/SectionContainer';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import { PropsWithChildren } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Stack } from 'tamagui';

type Props = PropsWithChildren<{
    control: Control<any>;
}>;

const ReportForm = ({ control, children }: Props) => {
    useStatusBar('dark-content');

    const startDate = useWatch({ control, name: 'startDate' });

    return (
        <SectionContainer title='Parâmetros do Relatório' containerProps={{ p: 0, px: 16, mb: 8 }}>
            <Stack ai='center' jc='center' mt={12} flexDirection='row'>
                <Stack flex={1}>
                    <Controller
                        control={control}
                        name='startDate'
                        render={({ field }) => (
                            <DatePicker
                                label='Data inicial'
                                placeholder='Insira a data inicial'
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Stack>

                <Stack flex={1} ml={12}>
                    <Controller
                        control={control}
                        name='endDate'
                        render={({ field }) => (
                            <DatePicker
                                label='Data final'
                                placeholder='Insira a data final'
                                value={field.value}
                                onChange={field.onChange}
                                minDate={startDate}
                            />
                        )}
                    />
                </Stack>
            </Stack>

            <Stack mt={12}>{children}</Stack>
        </SectionContainer>
    );
};

export default ReportForm;
