import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { IconProps } from '../types';
import { useSvgProps } from '../hooks/useSvgProps';

const ChevronLeft = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='m9 14.414 3-3 3 3L16.414 13l-3.707-3.707a1 1 0 0 0-1.414 0L7.586 13 9 14.414Z'
                clipRule='evenodd'
                transform='rotate(-90 12 12)'
            />
        </BaseSvg>
    );
};

export default { name: 'chevron-left', icon: ChevronLeft } as const;
