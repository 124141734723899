import { GenerateReportFormData, MetricType } from '@/metrics/types/GenerateReportForm';
import { Button, Popover, Separator, Stack } from '@alltis/ui';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ReportPopoverAviaryOptions from './ReportPopoverAviaryOptions';
import ReportPopoverMetricsOptions from './ReportPopoverMetricsOptions';
import ReportPopoverPeriodOptions from './ReportPopoverPeriodOptions';

const ReportPopoverContent = () => {
    const reportForm = useForm<GenerateReportFormData>({
        defaultValues: {
            aviaryIds: [],
            metrics: {},
            period: {
                startDate: dayjs().subtract(8, 'days').startOf('day').toDate(),
                endDate: dayjs().endOf('day').subtract(1, 'day').toDate(),
            },
        },
    });

    const navigation = useNavigation();

    function handleGenerateReport(report: GenerateReportFormData) {
        navigation.navigate('ReportMetricResult', {
            aviaryIds: report.aviaryIds,
            startDate: dayjs(report.period.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(report.period.endDate).format('YYYY-MM-DD'),
            metrics: Object.keys(report.metrics).filter((key) => report.metrics[key as MetricType] === true) as MetricType[],
        });
    }

    return (
        <FormProvider {...reportForm}>
            <Stack>
                <ReportPopoverAviaryOptions />
                <Separator borderColor='#EBEBEA' />

                <ReportPopoverMetricsOptions />
                <Separator borderColor='#EBEBEA' />

                <ReportPopoverPeriodOptions />
                <Separator borderColor='#EBEBEA' />

                <Stack px={64} py={12}>
                    <Button size='sm' onPress={reportForm.handleSubmit(handleGenerateReport)}>
                        Gerar relatório
                    </Button>
                </Stack>
            </Stack>
        </FormProvider>
    );
};

export default ReportPopoverContent;
