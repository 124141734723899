import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { Aviary } from '../entities/Aviary';
import { useSelectedFarm } from '../store/SelectedFarmStore';

export function useFarmAviaries(farmId?: string | undefined) {
    const selectedFarm = useSelectedFarm((store) => store.farm);

    const farmIdentifier = selectedFarm?.id || farmId;

    return useQuery<Aviary[]>({
        queryKey: ['farm-aviaries', farmIdentifier],
        queryFn: async () => (await alltisApiV2.get(`/farms/${farmId}/aviary/list`)).data,
        enabled: !!farmIdentifier,
    });
}
