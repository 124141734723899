import { Metric } from '@/metrics/types/MetricExportResponse';
import { getMetricIcon } from '@/metrics/utils/getMetricIcon';
import { formatBigNumber } from '@/shared/utils/number';
import { Icon, LineChart, PageList, Stack, Text } from '@alltis/ui';
import React from 'react';

type Props = {
    metric: Metric;
};

export const ReportMetricData = ({ metric }: Props) => {
    return (
        <Stack borderColor='#588290' borderWidth={2} borderTopWidth={0} $md={{ pr: 32, pl: 24 }}>
            <Stack $md={{ pl: 40 }} pl={24} flexDirection='row' ai='center' mt={33}>
                <Text color='#8CA9B3' fontSize={20} mr={33} fontWeight={500}>
                    {metric.name}
                </Text>

                <Icon name={getMetricIcon(metric.type)} size={24} color='#588290' />
            </Stack>

            <Stack width='90%'>
                <LineChart data={metric} isAutoWidth orientation='left' />
            </Stack>

            <Stack
                justifyContent='space-between'
                flexDirection='row'
                flexWrap='wrap'
                $md={{ pl: 40, p: 32 }}
                gap={20}
                p={24}
                pt={6}
                mt={6}
            >
                {metric.kpis.map((kpi) => {
                    const [quantity, caption] = kpi.values;

                    return (
                        <PageList.CardInfo
                            key={kpi.name}
                            title={kpi.name}
                            quantity={{
                                value: quantity.value || 0,
                                suffix: quantity.unit,
                            }}
                            caption={caption ? `${formatBigNumber(caption.value) || 0} ${caption.unit}` : undefined}
                            color='#376A7B'
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
};
