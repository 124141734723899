import { GenerateReportFormData } from '@/metrics/types/GenerateReportForm';
import { useFarmAviaries } from '@/user/hooks/useFarmAviaries';
import { Select } from '@alltis/ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReportPopoverOption from '../../ReportPopoverOption';

const ReportPopoverAviaryOptions = () => {
    const { control } = useFormContext<GenerateReportFormData>();

    const aviaries = useFarmAviaries();

    return (
        <ReportPopoverOption icon='aviary' title='Aviários'>
            <Controller
                control={control}
                name='aviaryIds'
                render={({ field }) => (
                    <Select
                        items={[
                            { label: 'Todos', value: null },
                            ...(aviaries.data?.map((aviary) => ({
                                label: aviary.name,
                                value: aviary.id,
                            })) || []),
                        ]}
                        multiple
                        label='Aviário'
                        value={field.value}
                        onChange={(value) => field.onChange(value.includes(null) ? [] : value)}
                    />
                )}
            />
        </ReportPopoverOption>
    );
};

export default ReportPopoverAviaryOptions;
