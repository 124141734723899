import { useTesterSettings } from '@legacy/user/store/TesterSettingsStore';
import { InternalAxiosRequestConfig } from 'axios';

export function createBaseUrlInterceptor(config: InternalAxiosRequestConfig) {
    const testerSettings = useTesterSettings.getState();

    if (testerSettings.isEnabled && testerSettings.settings?.apiUrl) {
        config.baseURL = `${testerSettings.settings.apiUrl}/api`;
    }

    return config;
}
