import { ReportFilterData, useReportFilter } from '@/reports/hooks/useReportFilter';
import Report from '@/reports/screens/Report';
import { ReportOptions } from '@/reports/screens/Report/ReportProps';
import SenseCO2Chart from '@/sense/components/SenseChart/SenseCO2Chart';
import SenseHumidityChart from '@/sense/components/SenseChart/SenseHumidityChart';
import SenseTemperatureChart from '@/sense/components/SenseChart/SenseTemperatureChart';
import { useSense } from '@/sense/hooks/fetch/useSense';
import { useSenseReport } from '@/sense/hooks/fetch/useSenseReport';
import { useSensePanel } from '@/sense/store /useSensePanel';
import { BaseCard } from '@/shared/components/ui/BaseCard';
import { FontAwesome5 } from '@expo/vector-icons';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import { Separator, Stack, Text } from 'tamagui';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';

type Props = {
    initialFilter?: ReportFilterData;
    options?: ReportOptions;
};

export const SenseReport = ({ initialFilter, options }: Props) => {
    const senses = useSense();

    const filter = useReportFilter(initialFilter);

    const report = useSenseReport(filter.data);

    const { params } = useRoute<RouteProp<UserStackParams, 'SenseReport'>>();

    const sensePanel = useSensePanel();

    useEffect(() => {
        const senseId = sensePanel?.senseId || params?.senseId;

        if (senseId) {
            filter.onFilterChange({ entityIds: [senseId] });
        }
    }, [params?.senseId, sensePanel.senseId]);

    return (
        <Report
            type='sense'
            title='Relatório de Ambiência'
            items={senses.data?.map(({ sense }) => ({ value: sense.id, label: sense.name }))}
            filter={filter}
            data={report.data || []}
            status={{ isError: report.isError, isLoading: report.isLoading }}
            selectProps={{ placeholder: 'Selecione os sensores', icon: <FontAwesome5 name='image' /> }}
            renderReportItem={(item) => (
                <Stack px='$3'>
                    <BaseCard px={0} mt={12}>
                        <Stack>
                            <Text color='$secondary500' fontSize={18} fontWeight='600' px={22}>
                                {item.name}
                            </Text>

                            <Separator my={18} />

                            <Stack>
                                <SenseTemperatureChart metrics={item.historic} />

                                <SenseHumidityChart metrics={item.historic} />

                                <SenseCO2Chart metrics={item.historic} />
                            </Stack>
                        </Stack>
                    </BaseCard>
                </Stack>
            )}
            options={options}
        />
    );
};
