import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack, Text } from 'tamagui';
import Icon from '../Icon/Icon';
import { IconButtonProps } from './IconButtonProps';
import Spinner from '@/shared/components/ui/Spinner';

const IconButton = ({ onPress, icon, label, loading, disabled }: IconButtonProps) => {
    return (
        <TouchableOpacity onPress={onPress} disabled={disabled}>
            <Stack
                px={label ? 8 : 0}
                height={38}
                width={label ? undefined : 38}
                jc='center'
                ai='center'
                bg='#F5F5F5'
                borderRadius={8}
                flexDirection='row'
                opacity={disabled ? 0.5 : 1}
            >
                {loading ? <Spinner size='small' /> : <Icon name={icon} size={24} color='#376A7B' />}

                {label && (
                    <Text fontSize={13} fontWeight={500} color='#376A7B' numberOfLines={1} ml={6}>
                        {label}
                    </Text>
                )}
            </Stack>
        </TouchableOpacity>
    );
};

export default IconButton;
