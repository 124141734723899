import * as Navigation from '@react-navigation/native';
import { ComponentProps, useCallback } from 'react';
import { useLogger } from '../config/logging/useLogger';
import { routingInstrumentation } from '../config/logging/sentry';
import qs from 'qs';
import { useNewUIEnabled } from '../config/theme/hooks/useNewUIEnabled';

type Props = Exclude<ComponentProps<typeof Navigation.NavigationContainer>, 'onStateChange'>;

export function NavigationContainer(props: Props) {
    const navigationContainerRef = Navigation.useNavigationContainerRef();

    const logger = useLogger();

    const onNavigationStateChange = useCallback(() => {
        const currentRoute = navigationContainerRef.getCurrentRoute();

        logger.setTag('screen', currentRoute?.name);

        logger.addBreadcrumb({
            category: 'navigation',
            message: `User navigated to screen ` + currentRoute?.name,
            timestamp: new Date().getTime(),
            data: currentRoute?.params,
        });
    }, []);

    const onReady = useCallback(() => {
        routingInstrumentation.registerNavigationContainer(navigationContainerRef);

        props.onReady?.();
    }, []);

    const isNewUI = useNewUIEnabled();

    return (
        <Navigation.NavigationContainer
            {...props}
            documentTitle={{
                enabled: true,
                formatter: () => 'ALLTIS | IRIS',
            }}
            onStateChange={onNavigationStateChange}
            ref={navigationContainerRef}
            onReady={onReady}
            linking={{
                prefixes: ['localhost:19006'],
                config: {
                    screens: {
                        Login: '/login',
                        ReportMetricsPDF: '/metrics/pdf',
                        Main: {
                            screens: {
                                AquaDetails: '/aqua/details',
                                EggsReport: '/eggs/report',
                                FeedManager: '/silos/ration',
                                FeedRegister: '/silos/ration/register/:rationId?',
                                WarehouseReport: '/silos/report',
                                SettingFieldScreen: '/settings/field',
                                PrivacyPolicy: '/privacy-policy',
                                WarehouseDetails: '/silos/details',
                                ReportExport: '/report/export/:type',
                                SenseReport: '/sense/report/:senseId',
                                Home: {
                                    screens: {
                                        Monitoring: '/',
                                        Places: '/eggs',
                                        Warehouses: '/silos/storage',
                                        Aqua: '/aqua',
                                        Configurations: '/configurations',
                                        Sense: '/sense',
                                        Aviaries: '/aviaries',
                                        RationConsumption: '/silos/ration/consumption',
                                        Birds: '/birds',
                                    },
                                },
                            },
                        },
                    },
                },
            }}
        >
            {props.children}
        </Navigation.NavigationContainer>
    );
}
