import { create } from 'zustand';

type DrawerStore = {
    isCollapsed: boolean;
    isOverlayVisible: boolean;

    toggleCollapsed: (isCollapsed: boolean) => void;
    toggleOverlay: (isVisible: boolean) => void;
};

export const useDrawerStore = create<DrawerStore>((set) => ({
    isCollapsed: true,
    isOverlayVisible: false,

    toggleCollapsed(isCollapsed) {
        set({ isCollapsed });
    },

    toggleOverlay(isOverlayVisible) {
        set({ isOverlayVisible });
    },
}));
