import Spinner from '@legacy/shared/components/ui/Spinner';
import React, { memo } from 'react';
import { View } from 'react-native';
import { Stack } from 'tamagui';
import { PlayerElement } from './PlayerElement';
import { VideoPlayerProps } from './VideoPlayerProps';

const VideoPlayer = (props: VideoPlayerProps) => {
    return (
        <Stack
            backgroundColor='$secondary500'
            height={270}
            borderRadius='$3'
            overflow='hidden'
            jc='center'
            ai='center'
            {...props}
        >
            <Stack position='absolute'>
                <Spinner size='small' color='#718096' />
            </Stack>

            <View style={{ flex: 1, width: '100%', height: '100%' }} pointerEvents='none'>
                <PlayerElement {...props} />
            </View>
        </Stack>
    );
};

export default memo(VideoPlayer);
