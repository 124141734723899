import CameraVideoSheet from '@legacy/camera-video/components/CameraVideoSheet';
import { CameraSource } from '@legacy/camera-video/entities/CameraSource';
import CoreNameField from '@legacy/chicken-house/components/PlaceEditor/CoreNameField';
import PlaceCameraRow from '@legacy/chicken-house/components/PlaceEditor/PlaceCameraRow';
import SubmitCamerasButton from '@legacy/chicken-house/components/PlaceEditor/SubmitCamerasButton';
import { PlaceSetupForm } from '@legacy/chicken-house/entities/PlaceSetupForm';
import { useAvailableCameraSources } from '@legacy/chicken-house/hooks/camera/useAvailableCameraSources';
import { Sheet } from '@legacy/shared/components/ui/Sheet';
import ViewState from '@legacy/shared/components/ui/ViewState';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import { FlashList } from '@shopify/flash-list';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Separator, Spacer, Stack, Text } from 'tamagui';

const PlaceEditor = () => {
    useStatusBar('dark-content');

    const cameraSheetRef = useRef<Sheet>(null);

    const { data: cameraSources, isError, isLoading, error } = useAvailableCameraSources();

    const [selectedCamera, setSelectedCamera] = useState<CameraSource>();

    const placesForm = useForm<PlaceSetupForm>({
        defaultValues: {
            places: [],
        },
    });

    if (isError || cameraSources?.length === 0 || isLoading) {
        return (
            <ViewState
                empty={{
                    enabled: cameraSources?.length === 0,
                    title: 'Nenhum aviário encontrado',
                    message: 'Verifique a configuração do servidor',
                }}
                error={{
                    enabled: isError,
                    error,
                }}
                loading={isLoading}
            />
        );
    }

    return (
        <FormProvider {...placesForm}>
            <Stack flex={1}>
                <FlashList
                    ListHeaderComponent={
                        <Stack p={12} mb={12}>
                            <CoreNameField />

                            <Text color='#A0AEC0' mt={22}>
                                Insira o nome dos aviários que deseja visualizar na tela inicial do aplicativo e aperte em
                                &quot;Salvar&quot; Para liberar as câmeras para visualização:
                            </Text>
                        </Stack>
                    }
                    data={cameraSources}
                    renderItem={({ item, index }) => (
                        <PlaceCameraRow
                            source={item}
                            index={index}
                            onPress={() => {
                                setSelectedCamera(item);
                                cameraSheetRef.current?.expand();
                            }}
                        />
                    )}
                    contentContainerStyle={{ padding: 12 }}
                    ItemSeparatorComponent={() => <Spacer size='$3.5' />}
                    estimatedItemSize={200}
                />

                <Separator borderColor='#eeeeee' />

                <SubmitCamerasButton />

                <CameraVideoSheet
                    source={selectedCamera}
                    allowRecording={false}
                    bottomSheetRef={cameraSheetRef}
                    onClose={() => setSelectedCamera(undefined)}
                />
            </Stack>
        </FormProvider>
    );
};

export default PlaceEditor;
