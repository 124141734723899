import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useLogger } from '../logging/useLogger';
import { useNotificationsEnabled } from './useNotificationsEnabled';
import { alltisApiV2 } from '../api/alltisApiV2';
import { RegisterNotificationDevice } from './types';

export function useSetupPushNotifications() {
    const logger = useLogger();
    const isNotificationsEnabled = useNotificationsEnabled();

    useEffect(() => {
        if (!isNotificationsEnabled) {
            return Notifications.setNotificationHandler(null);
        }

        Notifications.setNotificationHandler({
            handleNotification: async () => {
                return {
                    shouldPlaySound: false,
                    shouldSetBadge: false,
                    shouldShowAlert: true,
                };
            },
        });
    }, [isNotificationsEnabled]);

    async function setupNotificationPermissions() {
        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }

        const { status: existingStatus } = await Notifications.getPermissionsAsync();

        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }

        if (finalStatus !== 'granted') {
            throw new Error('Notification permission not granted');
        }
    }

    async function getPushToken() {
        const projectId = Constants?.expoConfig?.extra?.eas?.projectId ?? Constants?.easConfig?.projectId;

        if (!projectId) return;

        const pushTokenString = (
            await Notifications.getExpoPushTokenAsync({
                projectId,
            })
        ).data;

        return pushTokenString;
    }

    async function registerForPushNotificationsAsync() {
        try {
            await setupNotificationPermissions();

            const pushToken = await getPushToken();

            await alltisApiV2.post<null, RegisterNotificationDevice>('/user/notifications/register', {
                token: pushToken,
                device_id: Device.osBuildId,
                device_model: Device.modelName,
                name: Device.deviceName,
            });

            return pushToken;
        } catch (err) {
            logger.captureException(err);
        }
    }

    return {
        getPushToken,
        registerForPushNotificationsAsync,
    };
}
