import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Report = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                stroke={svg.color}
                strokeWidth={2}
                fill='transparent'
                d='M5 4a1 1 0 0 1 1-1h6.172a1 1 0 0 1 .707.293l5.828 5.828a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Z'
            />
            <Path stroke={svg.color} d='m12 3 7 7h-5a2 2 0 0 1-2-2V3Z' />
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M17 12h-4l1.47 1.47-1.97 1.97-1.47-1.47a.75.75 0 0 0-1.06 0l-2.5 2.5a.75.75 0 0 0 1.06 1.06l1.97-1.97 1.47 1.47a.75.75 0 0 0 1.06 0l2.5-2.5L17 16v-4Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'report', icon: Report } as const;
