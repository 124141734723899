import { useLogin } from '@/authentication/hooks/useLogin';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginFormData, loginFormSchema } from '@legacy/authentication/utils/validators/loginSchema';
import AppLogo from '@legacy/shared/components/ui/AppLogo';
import AppVersion from '@legacy/shared/components/ui/AppVersion';
import Button from '@legacy/shared/components/ui/Button';
import TextField from '@legacy/shared/components/ui/TextField';
import { useErrorHandler } from '@legacy/shared/hooks/useErrorHandler';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import { useTesterSettings } from '@legacy/user/store/TesterSettingsStore';
import { useNavigation } from '@react-navigation/native';
import { isAxiosError } from 'axios';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard, KeyboardAvoidingView, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Stack } from 'tamagui';

const Login = () => {
    useStatusBar('dark-content');

    const errorHandler = useErrorHandler();
    const login = useLogin();

    const isTesterSettingsEnabled = useTesterSettings((state) => state.isEnabled);

    const navigation = useNavigation();

    const { control, formState, handleSubmit, setFocus } = useForm<LoginFormData>({
        resolver: zodResolver(loginFormSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    async function onLogin(data: LoginFormData) {
        try {
            Keyboard.dismiss();

            await login.mutateAsync(data);

            if (Platform.OS === 'web') {
                setTimeout(() => location.reload(), 1000);
            }
        } catch (err) {
            if (isAxiosError(err) && err.response?.status === 401) {
                return errorHandler.handle(err, {
                    title: 'Login ou senha incorretos',
                    message: 'Verifique suas credenciais',
                });
            }

            return errorHandler.handle(err);
        }
    }

    return (
        <SafeAreaView>
            <KeyboardAvoidingView behavior='padding' enabled>
                <Stack
                    p={40}
                    $platform-web={{
                        maxWidth: '600px',
                        width: '100%',
                        height: '90vh',
                        alignSelf: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <AppLogo w='100%' h={40} />

                    <Stack mt={40} pb={20}>
                        <Controller
                            control={control}
                            name='email'
                            render={({ field: { onChange, ...field } }) => (
                                <TextField
                                    placeholder='Email'
                                    icon={<FontAwesome5 name='user' solid />}
                                    containerProps={{ mb: 12 }}
                                    onChangeText={onChange}
                                    autoCapitalize='none'
                                    returnKeyType='next'
                                    onSubmitEditing={() => setFocus('password')}
                                    blurOnSubmit={false}
                                    keyboardType='email-address'
                                    {...field}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='password'
                            render={({ field: { onChange, ...field } }) => (
                                <TextField
                                    placeholder='Senha'
                                    icon={<FontAwesome5 name='lock' solid />}
                                    secureTextEntry
                                    containerProps={{ mb: 6 }}
                                    onChangeText={onChange}
                                    autoCapitalize='none'
                                    onSubmitEditing={() => handleSubmit(onLogin)()}
                                    {...field}
                                />
                            )}
                        />
                    </Stack>

                    <Button disabled={!formState.isValid} loading={login.isLoading} onPress={() => handleSubmit(onLogin)()}>
                        Entrar
                    </Button>

                    {isTesterSettingsEnabled && (
                        <Button
                            disabled={login.isLoading}
                            variant='outlined'
                            onPress={() => navigation.navigate('Settings')}
                            mt={10}
                            color='primary'
                            icon={<FontAwesome5 name='cog' />}
                        >
                            Configurações
                        </Button>
                    )}

                    <Stack ai='center' mt={30}>
                        <AppVersion />
                    </Stack>
                </Stack>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

export default Login;
