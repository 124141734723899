import React from 'react';
import { Stack, Text } from 'tamagui';
import { BadgeProps } from './BadgeProps';
import { useSizeScale } from './hooks/useSizeScale';

const Badge = (props: BadgeProps) => {
    const badgeSize = useSizeScale(20, props.size);
    const fontSize = useSizeScale(10, props.size);

    return (
        <Stack
            br={9999}
            ov='hidden'
            px={props.children ? 6 : null}
            miw={badgeSize}
            mih={badgeSize}
            jc='center'
            ai='center'
            bg={props.color || '#8C1913'}
        >
            {props.children && (
                <Text color='#ffffff' fontSize={fontSize}>
                    {props.children}
                </Text>
            )}
        </Stack>
    );
};

export default Badge;
