import React, { forwardRef } from 'react';
import { Platform, TextInput } from 'react-native';
import { FieldBase } from '../FieldBase';
import { TextFieldProps } from './TextFieldProps';

export const fontSize = Platform.OS === 'web' ? 16 : 14;

const TextField = forwardRef<TextInput, TextFieldProps>((props, ref) => (
    <FieldBase {...props} backgroundColor='#043140'>
        <TextInput
            {...props}
            ref={ref}
            style={{
                flex: 1,
                paddingHorizontal: 16,
                fontSize: 14,
                color: '#ffffff',
                fontWeight: '600',
            }}
            placeholderTextColor='#8CA9B3'
        />
    </FieldBase>
));

TextField.displayName = 'TextField';

export default TextField;
