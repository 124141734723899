import { Sense } from '@legacy/sense/entities/Sense';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';
import { useQuery } from '../../../../config/cache/useQuery';

type GetSenseResponse = Array<{
    sense: Sense;
}>;

export function useSense() {
    return useQuery<GetSenseResponse>({
        queryKey: ['sense'],
        queryFn: async () => {
            const response = await alltisApiV2.get<GetSenseResponse>('/sense/list');
            return response.data;
        },
    });
}
