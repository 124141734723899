import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import Alert from '@/shared/components/ui/Alert';
import { PageList, RefreshControl, Stack } from '@alltis/ui';
import dayjs from 'dayjs';
import React from 'react';
import { FlatListProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DEFAULT_FETCH_INTERVAL } from '../../../../config/constants/fetchConfig';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';
import EggProductionTotalSummary from '../EggProduction/EggProductionTotalSummary';
import EggProductionCard from '../EggProductionCard';

type Props = Pick<FlatListProps<unknown>, 'ListEmptyComponent'>;

const PlacesList = (props: Props) => {
    const safeArea = useSafeAreaInsets();

    const isLarge = useLargeLayout();

    const chickenHouses = useChickenHouses({ refreshInterval: DEFAULT_FETCH_INTERVAL });

    return (
        <PageList
            onRefresh={chickenHouses.refetch}
            data={chickenHouses.data || []}
            ListHeaderComponent={
                <Stack mb={24} $md={{ mr: 24 }}>
                    <EggProductionTotalSummary />
                </Stack>
            }
            renderItem={({ item }) => (
                <Stack pb={isLarge ? 12 : 0} flex={1}>
                    <EggProductionCard data={item} />
                </Stack>
            )}
            ListEmptyComponent={props.ListEmptyComponent}
            isLoading={chickenHouses.isLoading}
            isError={chickenHouses.isError}
        >
            {!!chickenHouses?.dataUpdatedAt && (
                <Alert
                    message={`Atualizado em ${dayjs(chickenHouses?.dataUpdatedAt).format('DD [de] MMMM [às] HH:mm')}`}
                    severity='warn'
                    icon='clock'
                    borderBottomEndRadius={0}
                    borderBottomLeftRadius={0}
                    alignItems='center'
                    jc='center'
                    textProps={{ flex: 0 }}
                    pb={safeArea.bottom || undefined}
                />
            )}
        </PageList>
    );
};

export default PlacesList;
