import { abbreviation, formatBigNumber } from '@/shared/utils/number';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Dimensions, LayoutRectangle, Platform } from 'react-native';
import Svg, { Polygon } from 'react-native-svg';
import { Stack } from 'tamagui';
import {
    VictoryArea,
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLine,
    VictoryScatter,
    VictoryTheme,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from 'victory-native';
import * as Device from 'expo-device';
import { isWebOrTablet } from '../../../../../../config/constants/webOrTablet';

type Props = {
    data: {
        historic?: {
            timestamp: string | Date;
            value: number;
        }[];
    };
    range?: {
        minimum: number;
        maximum: number;
    };
    isDanger?: boolean;
    isAutoWidth?: boolean;
    height?: number;
    width?: number;
    tooltipAlwaysVisible?: boolean;
    orientation?: 'left' | 'right';
};

const isTabletDevice = Device.deviceType === Device.DeviceType.TABLET;

export const LineChart = ({
    data,
    range,
    isAutoWidth = isTabletDevice,
    height,
    tooltipAlwaysVisible,
    orientation = 'right',
    width,
}: Props) => {
    const [layoutSize, setLayoutSize] = useState<LayoutRectangle | undefined>(undefined);

    const formatDate = (date: Date | string) => dayjs(date).format('DD');

    const createDataArray = () => {
        return (
            data.historic?.map((entry) => ({
                x: formatDate(entry.timestamp),
                y: entry.value,
            })) || []
        );
    };

    const chartValues = createDataArray();

    const firstDataEntry = chartValues[0];
    const lastDataEntry = chartValues[chartValues.length - 1];

    const getTodayData = () => {
        const beforeLastDataEntry = chartValues[chartValues.length - 2];

        return beforeLastDataEntry ? [beforeLastDataEntry, lastDataEntry] : [lastDataEntry];
    };

    const getAverage = () => {
        const total = data?.historic?.reduce((sum, entry) => sum + entry.value, 0);

        const averageValue = (total || 0) / (data?.historic?.length || 1);

        return data.historic?.slice(0, -1)?.map((entry) => ({
            y: averageValue,
            x: formatDate(entry.timestamp),
        }));
    };

    const getRangeValues = () => {
        return {
            minimum: data.historic?.slice(0, -1).map((entry) => ({
                y: range?.minimum,
                x: formatDate(entry.timestamp),
            })),
            maximum: data.historic?.slice(0, -1).map((entry) => ({
                y: range?.maximum,
                x: formatDate(entry.timestamp),
            })),
        };
    };

    const getVerticalLine = () => {
        const lastX = data.historic?.[data.historic.length - 2];

        if (!lastX?.timestamp) return [];

        let maxValue =
            data.historic?.reduce((max, entry) => (entry.value > max.value ? entry : max), data.historic[0])?.value || 0;

        if (maxValue < (range?.maximum || 0)) {
            maxValue = range?.maximum || 0;
        }

        const x = formatDate(lastX.timestamp);

        return [
            { x, y: 0 },
            { x, y: maxValue },
        ];
    };

    const average = getAverage();
    const rangeData = getRangeValues();

    const fillColor = '#8DC044';

    const paddingLeft = Platform.OS === 'web' ? 40 : 60;
    const paddingRight = Platform.OS === 'web' ? 40 : isTabletDevice ? 40 : 90;

    const domainPaddingRight = orientation === 'left' ? 15 : 0;

    const hasWidth = width || layoutSize?.width;

    return (
        <Stack onLayout={(e) => isAutoWidth && setLayoutSize(e.nativeEvent.layout)}>
            <VictoryChart
                theme={VictoryTheme.material}
                height={height || 200}
                padding={{
                    top: 30,
                    bottom: 30,
                    left: orientation === 'right' ? 0 : paddingLeft,
                    right: orientation === 'right' ? paddingRight : 0,
                }}
                domainPadding={Platform.OS === 'web' ? { x: [20, domainPaddingRight], y: 50 } : { x: 10, y: 30 }}
                containerComponent={<VictoryVoronoiContainer />}
                {...(hasWidth ? { width: isAutoWidth ? layoutSize?.width : width || Dimensions.get('window').width } : {})}
            >
                {firstDataEntry && lastDataEntry && average?.[0] && (
                    <VictoryGroup>
                        <VictoryLine
                            data={average}
                            style={{
                                data: { stroke: fillColor, strokeWidth: 3 },
                            }}
                        />

                        <VictoryScatter
                            data={[average[0]]}
                            size={6}
                            style={{
                                data: {
                                    fill: fillColor,
                                },
                            }}
                            {...(Platform.OS === 'web'
                                ? {
                                      dataComponent: (
                                          <Svg height={20} width={20} style={{ overflow: 'visible' }}>
                                              <Polygon
                                                  points='10,0 0,20 20,20'
                                                  fill={fillColor}
                                                  transform='rotate(90 10 10) translate(-10, 0)'
                                              />
                                          </Svg>
                                      ),
                                  }
                                : {})}
                        />

                        <VictoryLine
                            data={getVerticalLine()}
                            style={{
                                data: { stroke: '#B2C5CC', strokeWidth: 2 },
                            }}
                        />
                    </VictoryGroup>
                )}

                <VictoryAxis
                    style={{
                        tickLabels: {
                            fill: '#B2C5CC',
                            fontSize: 10,
                            fontWeight: 400,
                            fontFamily: 'Inter',
                        },
                        axisLabel: {
                            fill: '#B2C5CC',
                            fontSize: 10,
                            fontWeight: 400,
                            fontFamily: 'Inter',
                        },
                        grid: { stroke: 'none' },
                        axis: { stroke: '#B2C5CC' },
                        ticks: { stroke: '#B2C5CC' },
                    }}
                />

                <VictoryAxis
                    tickFormat={(tick) => abbreviation(tick)}
                    dependentAxis
                    orientation={orientation}
                    style={{
                        axis: { stroke: '#B2C5CC' },
                        ticks: { stroke: '#B2C5CC' },
                        tickLabels: {
                            fill: '#B2C5CC',
                            fontSize: 10,
                            fontWeight: 400,
                            fontFamily: 'Inter',
                        },
                        axisLabel: {
                            fill: '#B2C5CC',
                            fontSize: 10,
                            fontWeight: 400,
                            fontFamily: 'Inter',
                        },
                        grid: { stroke: 'none' },
                        parent: { overflow: 'visible' },
                    }}
                />

                {lastDataEntry && (
                    <VictoryGroup>
                        <VictoryLine
                            data={chartValues.slice(0, -1)}
                            style={{
                                data: { stroke: '#05455A', strokeWidth: 4, borderColor: 'red', borderWidth: 40 },
                            }}
                            labels={({ datum }) => `${formatBigNumber(datum.y)}`}
                            labelComponent={
                                <VictoryTooltip
                                    constrainToVisibleArea
                                    renderInPortal={false}
                                    flyoutStyle={{
                                        fill: tooltipAlwaysVisible ? '#F5F5F5' : '#8CA9B3',
                                        stroke: 'none',
                                        borderRadius: 12,
                                    }}
                                    style={{
                                        fontWeight: '500',
                                        fontFamily: 'Inter',
                                        fill: tooltipAlwaysVisible ? '#698F9C' : '#ffffff',
                                        fontSize: 12,
                                    }}
                                    pointerLength={5}
                                    dy={-5}
                                    active={tooltipAlwaysVisible || undefined}
                                />
                            }
                        />

                        <VictoryLine
                            data={getTodayData()}
                            style={{
                                data: { stroke: '#05455A', strokeWidth: 5, strokeDasharray: '2.7' }, // Estilo pontilhado
                            }}
                        />

                        <VictoryScatter
                            data={[lastDataEntry]}
                            size={6}
                            style={{
                                data: {
                                    fill: 'white',
                                    stroke: '#05455A',
                                    strokeWidth: 4,
                                },
                            }}
                        />
                    </VictoryGroup>
                )}

                {!!range?.minimum && !!range?.maximum && (
                    <VictoryArea
                        data={rangeData.minimum?.map((min, index) => ({
                            ...min,
                            y0: rangeData.maximum?.[index].y,
                        }))}
                        style={{
                            data: { fill: fillColor, fillOpacity: 0.1, strokeWidth: 0 },
                        }}
                        events={[
                            {
                                target: 'data',
                                eventHandlers: { onPress: () => ({ target: 'data', mutation: () => null }) },
                            },
                        ]}
                    />
                )}
            </VictoryChart>
        </Stack>
    );
};
