import { ChickenHouseInfo } from '@legacy/chicken-house/entities/ChickenHouseInfo';

export const MOCK_CHICKEN_HOUSES: ChickenHouseInfo[] = [
    {
        id: 'mock-1',
        eggCount: {
            today: 234520,
            yesterday: 12340,
        },
        name: 'Aviário 1/2/3/4',
        site: {
            name: 'Granja A',
        },
        cameraSource: {
            ip: 'mock-ip',
            streamMAC: 'mock-mac',
            url: 'http://tamperehacklab.tunk.org:38001/nphMotionJpeg?Resolution=640x480&Quality=Clarity',
        },
    },
    {
        id: 'mock-2',
        eggCount: {
            today: 0,
            yesterday: 0,
        },
        name: 'aviário teste 2',
        site: {
            name: 'Granja A',
        },
        cameraSource: {
            ip: 'mock-ip',
            streamMAC: 'mock-mac',
            url: 'http://honjin1.miemasu.net/nphMotionJpeg?Resolution=640x480&Quality=Standard',
        },
    },
    {
        id: 'mock-3',
        eggCount: {
            today: 0,
            yesterday: 0,
        },
        name: 'cam 3',
        site: {
            name: 'Granja A',
        },
        cameraSource: {
            ip: 'mock-ip',
            streamMAC: 'mock-mac',
            url: 'http://195.196.36.242/mjpg/video.mjpg',
        },
    },
    {
        id: 'mock-4',
        eggCount: {
            today: 0,
            yesterday: 0,
        },
        name: 'cam 4',
        site: {
            name: 'Granja A',
        },
        cameraSource: {
            ip: 'mock-ip',
            streamMAC: 'mock-mac',
            url: 'http://takemotopiano.aa1.netvolante.jp:8190/nphMotionJpeg?Resolution=640x480&Quality=Standard&Framerate=30',
        },
    },
    {
        id: 'mock-5',
        eggCount: {
            today: 0,
            yesterday: 0,
        },
        name: 'cam 5',
        site: {
            name: 'Granja A',
        },
        cameraSource: {
            ip: 'mock-ip',
            streamMAC: 'mock-mac',
            url: 'http://camera.buffalotrace.com/mjpg/video.mjpg',
        },
    },
    {
        id: 'mock-6',
        eggCount: {
            today: 0,
            yesterday: 0,
        },
        name: 'cam 6',
        site: {
            name: 'Granja A',
        },
        cameraSource: {
            ip: 'mock-ip',
            streamMAC: 'mock-mac',
            url: 'http://takemotopiano.aa1.netvolante.jp:8190/nphMotionJpeg?Resolution=640x480&Quality=Standard&Framerate=30',
        },
    },
];
