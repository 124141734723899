import { IconButton, Popover, Stack } from '@alltis/ui';
import React from 'react';
import NotificationsList from '../NotificationsList';

const NotificationsButton = () => {
    return (
        <Popover>
            <Popover.Trigger>
                <Stack pointerEvents='none'>
                    <IconButton icon='notifications' />
                </Stack>
            </Popover.Trigger>

            <Popover.Content width={336} height={419}>
                <NotificationsList />
            </Popover.Content>
        </Popover>
    );
};

export default NotificationsButton;
