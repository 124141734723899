import { useSheetCheck } from '@/alltis-ui-v2/components/Sheet';
import * as Device from 'expo-device';
import React from 'react';
import { Header, HeaderProps } from '../../../Header';
import { useMedia } from 'tamagui';

const PopoverHeader = (props: HeaderProps) => {
    const isSheet = useSheetCheck();
    const media = useMedia();

    const isFormSheetPopover = Device.deviceType === Device.DeviceType.TABLET && isSheet && media.md;

    return <Header {...props} size={isFormSheetPopover ? 'md' : 'sm'} bg={isFormSheetPopover ? undefined : 'white'} />;
};

export default PopoverHeader;
