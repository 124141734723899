import { LineChart, SegmentedControl } from '@alltis/ui';
import React from 'react';
import MonitoringBaseCard, { MonitoringBaseCardChart, MonitoringBaseCardInfo } from '../MonitoringBaseCard';
import MonitoringCardData from '../MonitoringCardData';

const AquaMonitor = () => {
    return (
        <MonitoringBaseCard title='Consumo de Água' icon='aqua'>
            <MonitoringBaseCardChart>
                <LineChart data={{}} height={170} />
            </MonitoringBaseCardChart>

            <MonitoringBaseCardInfo>
                <MonitoringCardData
                    title='Média 134.760 litros/dia'
                    label='Total de Hoje'
                    quantity={{
                        value: 114760,
                        suffix: 'litros',
                    }}
                    header={
                        <SegmentedControl
                            items={[
                                { label: 'Dia', value: 'day' },
                                { label: 'Ave', value: 'bird' },
                                { label: 'Ovo', value: 'egg' },
                            ]}
                        />
                    }
                />
            </MonitoringBaseCardInfo>
        </MonitoringBaseCard>
    );
};

export default AquaMonitor;
