import { getRemoteServerChickenHouses, getStreamChickenHouses } from '@legacy/chicken-house/api/ChickenHouseApi';
import { ChickenHouseInfo } from '@legacy/chicken-house/entities/ChickenHouseInfo';
import { useCameraServer } from '@legacy/chicken-house/store/CameraServerStore';
import { MOCK_CHICKEN_HOUSES } from '@legacy/chicken-house/utils/mocks/cameraPreviewMocks';
import { useTesterSettings } from '@legacy/user/store/TesterSettingsStore';
import { useQuery } from '../../../../config/cache/useQuery';
import { useLogger } from '../../../../config/logging/useLogger';

type Props = {
    refreshInterval?: number;
};

export function useChickenHouses(props?: Props) {
    const logger = useLogger();

    const networkConfig = useCameraServer((state) => state.networkConfig);
    const shouldUseMocked = useTesterSettings((state) => state.settings.shouldUseMockedChickenHouses);

    const isQueryEnabled =
        shouldUseMocked || networkConfig?.shouldUseRemoteServer || (!!networkConfig?.ip && !!networkConfig.port);

    return useQuery<ChickenHouseInfo[]>({
        queryKey: ['chicken-houses', networkConfig, shouldUseMocked],
        queryFn: async () => {
            logger.info('fetching eggs information from server', networkConfig, 'eggs');

            if (shouldUseMocked) {
                return MOCK_CHICKEN_HOUSES;
            }

            const chickenHouses = await (networkConfig?.shouldUseRemoteServer
                ? getRemoteServerChickenHouses()
                : getStreamChickenHouses());

            return chickenHouses;
        },
        enabled: isQueryEnabled,
        refetchInterval: props?.refreshInterval,
    });
}
