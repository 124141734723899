import VideoPlayer from '@legacy/camera-video/components/VideoPlayer';
import { ChickenHouseInfo } from '@legacy/chicken-house/entities/ChickenHouseInfo';
import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import React from 'react';
import { Stack } from 'tamagui';
import CameraContainer from './CameraContainer';

type Props = {
    chickenHouse: ChickenHouseInfo | null;
    onPress: () => void;
    index: number;
};

const ChickenHouseCamera = ({ chickenHouse, index, onPress }: Props) => {
    return (
        <CameraContainer index={index}>
            {!chickenHouse?.cameraSource ? (
                <Stack flex={1} jc='center' ai='center' onPress={onPress} width='100%'>
                    <MaterialIcon name='videocam-off' size={15} color='#718096' />
                </Stack>
            ) : (
                <Stack width='100%' height='100%'>
                    <VideoPlayer
                        url={chickenHouse.cameraSource?.url}
                        width='100%'
                        height='100%'
                        onPress={onPress}
                        borderRadius={0}
                    />

                    {/* {chickenHouse.name && <LiveBadge />} */}
                </Stack>
            )}
        </CameraContainer>
    );
};

export default ChickenHouseCamera;
