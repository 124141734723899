import React from 'react';
import ConfigurationSection from '../../ConfigurationSection';
import NumberSettingField from '../../SettingFields/NumberSettingField';
import SettingSection from '../../SettingFields/SettingSection';
import RangeSettingField from '../../SettingFields/RangeSettingField';
import { NumericInput, Stack, Switch, Text } from '@alltis/ui';

const NotificationsSettings = () => {
    return (
        <ConfigurationSection title='Notificacões'>
            <SettingSection title='Mortalidade de Aves' icon='chicken'>
                <NumberSettingField label='Acima da faixa ideal da espécie' prefix='%' />
            </SettingSection>

            <SettingSection title='Produção de Ovos' icon='eggs'>
                <NumberSettingField label='Abaixo da faixa ideal da espécie' prefix='%' />
            </SettingSection>

            <SettingSection title='Consumo de Ração' icon='ration'>
                <NumberSettingField label='Abaixo da faixa ideal da espécie' prefix='%' />
                <NumberSettingField label='Abaixo da faixa ideal da espécie' prefix='%' />
            </SettingSection>

            <SettingSection title='Consumo de Água' icon='aqua'>
                <NumberSettingField label='Abaixo da faixa ideal da espécie' prefix='%' />
                <NumberSettingField label='Abaixo da faixa ideal da espécie' prefix='%' />
                <NumberSettingField label='Sem consumo de água' prefix='min' />
            </SettingSection>

            <SettingSection title='Temperatura Ambiente' icon='ambience'>
                <RangeSettingField label='Fora do intervalo' prefix='°C' />

                <Stack mt={20}>
                    <Text color='#8CA9B3' fontWeight={500} fontSize={16} mb={16}>
                        Umidade Relativa
                    </Text>

                    <RangeSettingField label='Fora do intervalo' prefix='°C' />
                </Stack>

                <Stack mt={20}>
                    <Text color='#8CA9B3' fontWeight={500} fontSize={16} mb={16}>
                        Nível de CO₂
                    </Text>

                    <Stack flexDirection='row' flexWrap='wrap' gap={14} alignItems='center'>
                        <Text color='#588290' fontSize={14} fontWeight='400'>
                            Acima de
                        </Text>

                        <NumericInput prefix='ppm' width={160} />

                        <Stack ml='auto'>
                            <Switch />
                        </Stack>
                    </Stack>
                </Stack>
            </SettingSection>

            <SettingSection title='Armazenamento dos Silos' icon='silo' isLast>
                <Stack flexDirection='row' flexWrap='wrap' gap={14} alignItems='center'>
                    <Text color='#588290' fontSize={14} fontWeight='400'>
                        Abaixo de
                    </Text>
                    <NumericInput prefix='%' />
                    <Text color='#588290' fontSize={14} fontWeight='400'>
                        da capacidade total
                    </Text>

                    <Stack ml='auto'>
                        <Switch />
                    </Stack>
                </Stack>
            </SettingSection>
        </ConfigurationSection>
    );
};

export default NotificationsSettings;
