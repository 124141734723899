import FarmAviarySelector from '@/user/components/FarmAviarySelector';
import React from 'react';
import { Stack } from '@alltis/ui';

const StartContent = () => {
    return (
        <Stack flex={1} $md={{ width: 300 }} jc='center' ai='center'>
            <FarmAviarySelector />
        </Stack>
    );
};

export default StartContent;
