import LabelAndValue from '@legacy/shared/components/ui/LabelAndValue';
import { Silo } from '@legacy/warehouse/entities/Silo';
import { useQuantityUnits } from '@legacy/warehouse/hooks/useQuantityUnits';
import dayjs from 'dayjs';
import { Text, XStack } from 'tamagui';

type Props = {
    showName?: boolean;
    showTons: boolean;
    silo: Pick<Silo, 'level' | 'name'>;
};

export function WarehouseSupplyColumns({ showTons, silo, showName }: Props) {
    const quantityUnits = useQuantityUnits(showTons);

    return (
        <>
            {showName && (
                <Text fontWeight='600' color='$secondary900' mb={10} fontSize={14}>
                    {silo.name}
                </Text>
            )}

            <XStack mb={16}>
                <LabelAndValue
                    label='Abastecido'
                    value={
                        silo?.level?.resupply?.timestamp ? dayjs(silo?.level?.resupply?.timestamp).format('DD [de] MMMM') : '-'
                    }
                    containerProps={{ flexGrow: 1 }}
                />

                <LabelAndValue
                    label='Carga Abastecida'
                    value={
                        silo.level.resupply
                            ? quantityUnits.format({
                                  perc: silo.level.resupply.percentage,
                                  tons: silo.level.resupply.tons,
                              })
                            : '-'
                    }
                    containerProps={{ flexGrow: 1 }}
                />
            </XStack>
        </>
    );
}
