import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { IconProps } from '../types';
import { useSvgProps } from '../hooks/useSvgProps';

const Aqua = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='m9.039 10.553.05-.06c.936-1.113 2.014-2.394 2.93-4.04.998 1.943 2.17 3.486 3.174 4.807l.041.055C17.006 13.646 18 15.059 18 17c0 2.576-2.292 5-6 5s-6-2.424-6-5c0-2.773 1.207-4.269 3.039-6.447Zm-1.53-1.288C8.748 7.79 10.061 6.23 10.97 4.04l.037-.092c.085-.207.166-.42.242-.64.34-.97.601-2.06.75-3.308a18.613 18.613 0 0 0 1.112 4.043c1.013 2.505 2.43 4.37 3.715 6.062C18.529 12.345 20 14.282 20 17c0 3.907-3.428 7-8 7-4.571 0-8-3.093-8-7 0-3.564 1.677-5.557 3.508-7.735Zm2.187 6.013a.75.75 0 0 0-1.392-.557c-.576 1.44-.464 2.838.267 3.893.727 1.05 1.978 1.636 3.429 1.636a.75.75 0 1 0 0-1.5c-1.05 0-1.797-.414-2.196-.99-.394-.569-.532-1.421-.108-2.482Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'aqua', icon: Aqua } as const;
