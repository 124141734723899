import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import { formatBigNumber } from '@/shared/utils/number';
import { LineChart, PageList, Stack, Text } from '@alltis/ui';
import { Feather } from '@expo/vector-icons';
import React, { useState } from 'react';
import LivePreviewSheet from '../LivePreview/LivePreviewSheet';
import { checkCanUseStreamPlayer } from '../LivePreview/StreamPlayer/checkCanUseStreamPlayer';

type Props = {
    data: ChickenHouseInfo;
};

const EggProductionCard = ({ data }: Props) => {
    const [isLivePreviewOpen, setIsLivePreviewOpen] = useState(false);

    const hasLivePreview = checkCanUseStreamPlayer() && data.eggCount.livePreview;

    return (
        <PageList.Card onPress={hasLivePreview ? () => setIsLivePreviewOpen(true) : undefined}>
            <PageList.CardHeader
                title={data.name}
                startContent={{
                    title: `Média últimos ${(data.eggCount.historic?.length || 1) - 1} dias`,
                    quantity: {
                        value: data.eggCount.periodAvg || 0,
                        suffix: 'ovos',
                    },
                    caption: `${data.eggCount.periodAvgBoxes || 0} caixas`,
                }}
                endContent={{
                    title: 'Produção de Hoje',
                    quantity: {
                        value: data.eggCount?.today || 0,
                        suffix: 'ovos',
                    },
                    caption: `${formatBigNumber(data.eggCount?.boxesToday || 0)} caixas`,
                }}
                icon='eggs'
                isDanger={data.eggCount.alert}
            />

            <PageList.CardContent>
                {hasLivePreview && (
                    <Stack alignItems='flex-end' flexDirection='row' ai='center' mb={-20}>
                        <Feather name='video' size={18} color='#588290' />

                        <Text color='#588290' fontSize={12} ml={8}>
                            Clique para ver a câmera
                        </Text>
                    </Stack>
                )}

                <LineChart
                    range={{
                        minimum: data.eggCount.thresholdMin || 0,
                        maximum: data.eggCount.thresholdMax || 0,
                    }}
                    data={{
                        historic: data.eggCount.historic?.map((d) => ({
                            timestamp: d.timestamp,
                            value: d.count,
                        })),
                    }}
                    isDanger={data.eggCount.alert}
                />
            </PageList.CardContent>

            {isLivePreviewOpen && <LivePreviewSheet device={data} onClose={() => setIsLivePreviewOpen(false)} />}
        </PageList.Card>
    );
};

export default EggProductionCard;
