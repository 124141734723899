import { Farm } from '@/user/entities/Farm';
import { FlashList } from '@shopify/flash-list';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack, Text } from 'tamagui';
import { useDrawerConstants } from '../../../../navigation/stacks/User/Main/hooks/useDrawerConstants';

type Props = {
    farms: Farm[];
    onFarmSelected: (farm: Farm) => void;
};

const FarmSelectionList = ({ onFarmSelected, farms }: Props) => {
    const { paddingX } = useDrawerConstants();

    return (
        <Stack flex={1}>
            <FlashList
                data={farms}
                contentContainerStyle={{ paddingVertical: 9 }}
                renderItem={({ item }) => (
                    <TouchableOpacity activeOpacity={0.7} onPress={() => onFarmSelected?.(item)}>
                        <Stack jc='center' py={16} px={paddingX}>
                            <Text color='#B2C5CC' fontSize={14}>
                                {item.name}
                            </Text>
                        </Stack>
                    </TouchableOpacity>
                )}
                estimatedItemSize={74}
            />
        </Stack>
    );
};

export default memo(FarmSelectionList);
