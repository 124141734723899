import { useUser } from '@legacy/authentication/store/UserStore';
import Popover from '@legacy/shared/components/ui/Popover';
import Profile from '@legacy/user/screens/Profile';
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Button, Stack } from 'tamagui';

const MenuPopover = () => {
    const name = useUser((state) => state?.user?.name);

    return (
        <Popover>
            <Popover.Trigger>
                <Button
                    px={8}
                    ml={8}
                    iconAfter={<FontAwesome5 name='user-circle' color='#929292' />}
                    bg='transparent'
                    textProps={{
                        color: '#929292',
                        fontWeight: '500',
                    }}
                >
                    {name}
                </Button>
            </Popover.Trigger>

            <Popover.Content>
                <Stack w={500} p={15}>
                    <Profile disableUsername />
                </Stack>
            </Popover.Content>
        </Popover>
    );
};

export default MenuPopover;
