import { LineChart } from '@alltis/ui';
import React from 'react';
import MonitoringBaseCard, { MonitoringBaseCardChart, MonitoringBaseCardInfo } from '../MonitoringBaseCard';
import MonitoringCardData from '../MonitoringCardData';

const BirdNumberMonitor = () => {
    return (
        <MonitoringBaseCard title='Número de Aves' icon='chicken'>
            <MonitoringBaseCardChart>
                <LineChart data={{}} height={170} />
            </MonitoringBaseCardChart>

            <MonitoringBaseCardInfo>
                <MonitoringCardData
                    title='Média 384 mortes/dia'
                    label='Total de Aves Hoje'
                    quantity={{
                        value: 1654230,
                        suffix: 'aves',
                    }}
                />
            </MonitoringBaseCardInfo>
        </MonitoringBaseCard>
    );
};

export default BirdNumberMonitor;
