import { useDrawerStore } from '../store/DrawerStore';
import { useDrawerConstants } from './useDrawerConstants';

export function useDrawerCollapsed() {
    const drawerOptions = useDrawerConstants();
    const isCollapsed = useDrawerStore((store) => store.isCollapsed);

    if (!drawerOptions.isCollapsable) return false;

    return isCollapsed;
}
