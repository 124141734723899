import VideoPlayer from '@legacy/camera-video/components/VideoPlayer';
import { CameraSource } from '@legacy/camera-video/entities/CameraSource';
import EditCameraName from '@legacy/chicken-house/components/PlaceEditor/EditCameraName';
import { BaseCard } from '@legacy/shared/components/ui/BaseCard';
import React from 'react';
import { Stack } from 'tamagui';

type Props = {
    index: number;
    source: CameraSource;
    onPress?: () => void;
};

const PlaceCameraRow = ({ source, onPress, index }: Props) => {
    return (
        <BaseCard p={10} borderRadius='$3' flexDirection='row'>
            <VideoPlayer url={source.url} width={130} height={80} onPress={onPress} />

            <Stack flex={1} justifyContent='center' pl={22}>
                <EditCameraName source={source} index={index} />
            </Stack>
        </BaseCard>
    );
};

export default PlaceCameraRow;
