import { NumericInput, Switch, Text } from '@alltis/ui';
import React from 'react';
import SettingGridLayout from '../SettingGridLayout';

type Props = {
    label: string;
    prefix: string;
};

const NumberSettingField = (props: Props) => {
    return (
        <SettingGridLayout
            Start={<NumericInput prefix={props.prefix} />}
            Middle={
                <Text color='#588290' fontSize={14} fontWeight='400'>
                    {props.label}
                </Text>
            }
            End={<Switch />}
        />
    );
};

export default NumberSettingField;
