import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Notifications = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M11.293 4.293A1 1 0 0 1 13 5a1 1 0 0 0 .573.904A6 6 0 0 1 17 11.024V14c0 .04.003.08.008.12.08.663.292 1.302.622 1.88H6.37a5 5 0 0 0 .623-1.88A1 1 0 0 0 7 14v-2.976a6 6 0 0 1 3.428-5.12A1 1 0 0 0 11 5a1 1 0 0 1 .293-.707ZM8 18H4a1 1 0 0 1-.492-1.87A3 3 0 0 0 5 13.935V11l.001-.047a8 8 0 0 1 4.07-6.597 3 3 0 0 1 5.86 0A8 8 0 0 1 19 11v2.935a3 3 0 0 0 1.492 2.195A1 1 0 0 1 20 18h-4a4 4 0 1 1-8 0Zm6 0a2 2 0 0 1-4 0h4Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'notifications', icon: Notifications } as const;
