import { useTabController } from '@legacy/shared/hooks/useTabController';
import { useViewMapListener } from '@legacy/warehouse/hooks/events/useViewMapEvent';
import { SiloTab, useSiloPanel } from '@legacy/warehouse/store/useSiloPanelStore';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';

export function useSiloTabs() {
    const isLarge = useLargeLayout();

    const panel = useSiloPanel();

    const { routes, index, getRoute } = useTabController<SiloTab>(
        [
            { key: 'silos', title: 'Silos' },
            { key: 'maps', title: 'Mapa de Silos' },
            isLarge && { key: 'reports', title: 'Relatórios' },
        ],
        {
            activeTab: panel.currentTab,
        }
    );

    useViewMapListener(() => panel.onTabChanged('maps'));

    const onIndexChange = (index: number) => {
        panel.onTabChanged(getRoute(index));
    };

    return {
        index,
        routes,
        onIndexChange,
    };
}
