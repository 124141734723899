import { Icon } from '@alltis/ui';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity } from 'react-native';

const DrawerButton = () => {
    const navigation = useNavigation();

    return (
        <TouchableOpacity
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            hitSlop={{
                bottom: 15,
                left: 15,
                right: 15,
                top: 15,
            }}
        >
            <Icon name='menu' color='#588290' size={24} />
        </TouchableOpacity>
    );
};

export default DrawerButton;
