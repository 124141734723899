import { useUser } from '@/authentication/store/UserStore';
import CompanyLogo from '@/user/components/CompanyLogo';
import { DrawerHeaderProps } from '@react-navigation/drawer';
import React from 'react';
import { Stack, Text } from 'tamagui';
import { useLargeLayout } from '../../../../../../hooks/useLargeLayout';
import DrawerButton from './DrawerButton';
import HeaderLayout from './HeaderLayout';
import UserProfileMenu from '../../../../../../containers/user/components/UserProfileMenu';

type Props = {
    header: DrawerHeaderProps;
};

const MainHeader = ({ header }: Props) => {
    const user = useUser((store) => store.user);

    const isLarge = useLargeLayout();

    return (
        <HeaderLayout
            start={
                isLarge ? (
                    <Stack w={120} h='70%'>
                        <CompanyLogo user={user} disableDefault w={120} h='100%' />
                    </Stack>
                ) : (
                    <DrawerButton />
                )
            }
            end={<UserProfileMenu />}
            middle={
                <Text fontSize={18} fontWeight={500} color='#ffffff' flex={1} textAlign='center'>
                    {header.options.title || header.options.headerTitle}
                </Text>
            }
        />
    );
};

export default MainHeader;
