import { useUser } from '@legacy/authentication/store/UserStore';
import PlaceHeader from '@legacy/chicken-house/components/PlaceHeader';
import AppLogo from '@legacy/shared/components/ui/AppLogo';
import { Header } from '@react-navigation/elements';
import React from 'react';
import { Stack } from 'tamagui';
import MenuPopover from '../MenuPopover';
import SelectFarmButton from '../SelectFarmButton';

const blacklist = ['/report/export'] as const;

const AppHeader = () => {
    const { user } = useUser();

    const isInBlacklist = blacklist.some((url) => window?.location?.href?.includes(url));

    if (!user || isInBlacklist) return null;

    return (
        <Header
            title=''
            headerTitle={() => <AppLogo mode='default' />}
            headerRight={() => (
                <Stack mr={12} flexDirection='row' ai='center' flex={1}>
                    <SelectFarmButton />

                    <MenuPopover />
                </Stack>
            )}
            headerLeft={() => <PlaceHeader />}
            headerTitleAlign='center'
        />
    );
};

export default AppHeader;
