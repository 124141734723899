import { Icon, SegmentedControl, Stack, Text, XStack } from '@alltis/ui';
import React from 'react';
import ConfigurationSection from '../../ConfigurationSection';

const UnitOfMeasure = () => {
    return (
        <ConfigurationSection title='Unidades de Medida'>
            <XStack fw='wrap' gap={12}>
                <XStack alignItems='center'>
                    <Icon name='silo' size={24} color='#588290' />
                    <Text ml={16} color='#588290' fontWeight={400} fontSize={16}>
                        Armazenamento Silos
                    </Text>
                </XStack>

                <Stack flex={1} />

                <XStack width={220}>
                    <SegmentedControl
                        items={[
                            { label: 'Toneladas', value: 'tons' },
                            { label: '%', value: 'percent' },
                        ]}
                        activeTextColor='#376A7B'
                        textStyle={{
                            color: '#8CA9B3',
                        }}
                        style={{
                            backgroundColor: '#F5F5F5',
                        }}
                    />
                </XStack>
            </XStack>
        </ConfigurationSection>
    );
};

export default UnitOfMeasure;
