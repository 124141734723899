import Ambience from './svg/Ambience';
import Aviary from './svg/Aviary';
import Aqua from './svg/Aqua';
import ChevronUp from './svg/ChevronUp';
import ChevronDown from './svg/ChevronDown';
import Chicken from './svg/Chicken';
import Configurations from './svg/Configurations';
import Eggs from './svg/Eggs';
import Farm from './svg/Farm';
import Menu from './svg/Menu';
import Monitoring from './svg/Monitoring';
import Notifications from './svg/Notifications';
import Ration from './svg/Ration';
import Report from './svg/Report';
import Silo from './svg/Silo';
import ChevronLeft from './svg/ChevronLeft';
import ChevronRight from './svg/ChevronRight';
import Password from './svg/Password';
import Mail from './svg/Mail';
import AlertTriangle from './svg/AlertTriangle';
import Close from './svg/Close';
import Metrics from './svg/Metrics';
import Calendar from './svg/Calendar';
import File from './svg/File';
import HorizontalDots from './svg/HorizontalDots';
import Plus from './svg/Plus';
import Logout from './svg/Logout';
import AccountCircle from './svg/AccountCircle';
import User from './svg/User';

export const iconSet = [
    Ambience,
    Aviary,
    Aqua,
    ChevronUp,
    ChevronDown,
    Chicken,
    Configurations,
    Eggs,
    Farm,
    Menu,
    Monitoring,
    Notifications,
    Ration,
    Report,
    Silo,
    ChevronLeft,
    ChevronRight,
    Password,
    Mail,
    AlertTriangle,
    Close,
    Metrics,
    Calendar,
    File,
    HorizontalDots,
    Plus,
    Logout,
    AccountCircle,
    User,
] as const;
