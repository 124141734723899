import React, { memo, useMemo, useState } from 'react';
import { Separator, Stack, Text } from 'tamagui';
import SelectList from '../Content/List/SelectList';
import { MultiSelectItem, MultiSelectProps } from '../MultiSelectProps';
import SearchField from '../../SearchField';
import { uniqBy } from 'lodash';
import { useSearch } from '@legacy/shared/hooks/useSearch';

type Props = Pick<MultiSelectProps, 'items' | 'value' | 'label'> & {
    onSelect: (items: MultiSelectItem) => void;
    title?: string;
};

const MultiSelectContent = ({ items, value, onSelect, title, label }: Props) => {
    const [searchText, setSearchText] = useState('');

    const filteredItems = useSearch(
        uniqBy(items, (item) => item.value),
        {
            search: searchText,
            transform: (d) => [d.label],
        }
    );

    return (
        <>
            <Stack py={18} px={16}>
                <Text fontSize={16} fontWeight='bold' textAlign='center' mb={12}>
                    {title || label}
                </Text>

                <SearchField placeholder='Buscar' onChangeText={setSearchText} />
            </Stack>

            <Separator />

            <SelectList items={filteredItems.results || []} value={value} onSelect={onSelect} />
        </>
    );
};

export default memo(MultiSelectContent);
