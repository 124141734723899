import Spinner from '@/shared/components/ui/Spinner';
import React from 'react';
import { Stack, StackProps, Text } from 'tamagui';
import { ButtonProps } from './ButtonProps';

const Button = (props: ButtonProps) => {
    const variant = props.variant || 'primary';

    const backgroundColor = variant === 'primary' ? '#376A7B' : '#8DC044';
    const backgroundHoverColor = variant === 'primary' ? '#2a4f5c' : '#7aa53e';
    const textColor = variant === 'primary' ? '#ffffff' : '#043140';

    const height = props.size === 'sm' ? 38 : 46;

    const disabledProps: StackProps = props.disabled
        ? {
              opacity: 0.5,
          }
        : {
              hoverStyle: { bc: backgroundHoverColor },
              pressStyle: { bc: backgroundHoverColor },
              cursor: 'pointer',
          };

    return (
        <Stack
            pointerEvents={props.loading || props.disabled ? 'none' : 'auto'}
            br={8}
            bc={backgroundColor}
            jc='center'
            ai='center'
            h={height}
            {...props}
            {...disabledProps}
        >
            {props.loading ? (
                <Spinner size='small' color='#ffffff' />
            ) : (
                <Text fontWeight={600} fontSize={16} textAlign='center' color={textColor}>
                    {props.children}
                </Text>
            )}
        </Stack>
    );
};

export default Button;
