import SectionContainer from '@legacy/shared/components/ui/SectionContainer';
import { Silo } from '@legacy/warehouse/entities/Silo';
import { useSilosByGroup } from '@legacy/warehouse/hooks/silos/useSilosByGroup';
import { useMemo } from 'react';
import Animated, { SequencedTransition, SlideInRight, SlideOutRight } from 'react-native-reanimated';
import { Stack, XStack } from 'tamagui';
import WarehouseHeaderInfo from './WarehouseHeaderInfo';
import { Platform } from 'react-native';

type Props = {
    silo: Silo;
};

const WarehouseHeader = ({ silo }: Props) => {
    const { data: silosFromGroup } = useSilosByGroup(silo.group_id);

    const linkedSilo = useMemo(() => {
        return silosFromGroup?.silos.filter((s) => s.id !== silo.id)?.[0];
    }, [silosFromGroup, silo.id]);

    return (
        <SectionContainer title='Nível atual' containerProps={{ bg: '#ffffff', p: 0, py: 25, minHeight: 250 }}>
            <XStack flex={1}>
                <XStack flex={1} justifyContent='center' alignItems='center'>
                    <Animated.View layout={Platform.OS === 'web' ? undefined : SequencedTransition.duration(1000)}>
                        <WarehouseHeaderInfo silo={silo} temperaturePosition='bottom' />
                    </Animated.View>

                    {linkedSilo && (
                        <Animated.View
                            entering={Platform.OS === 'web' ? undefined : SlideInRight.duration(600)}
                            exiting={Platform.OS === 'web' ? undefined : SlideOutRight.duration(1000)}
                        >
                            <Stack ml={32} ai='center'>
                                <WarehouseHeaderInfo silo={linkedSilo} temperaturePosition='bottom' />
                            </Stack>
                        </Animated.View>
                    )}
                </XStack>
            </XStack>
        </SectionContainer>
    );
};

export default WarehouseHeader;
