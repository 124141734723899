import { Icon } from '@alltis/ui';
import { useFarms } from '@/user/hooks/useFarms';
import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack, Text } from 'tamagui';
import DrawerSeparator from '../../../../navigation/stacks/User/Main/components/CustomAppDrawer/DrawerSeparator';
import { useDrawerConstants } from '../../../../navigation/stacks/User/Main/hooks/useDrawerConstants';

type Props = {
    title?: string;
    onPress?: () => void;
    isOpen?: boolean;
};

const DrawerFarmSelectorButton = ({ title, onPress, isOpen }: Props) => {
    const { paddingX } = useDrawerConstants();

    const farms = useFarms();

    if (!farms.data || farms.data.length <= 1) return null;

    return (
        <TouchableOpacity activeOpacity={0.8} onPress={onPress} style={{ height: 62 }}>
            <Stack flexDirection='row' alignItems='center' flex={1} px={paddingX} backgroundColor='#043140' transition='.2s'>
                <Stack width={20}>
                    <Icon name='farm' size={24} color='#8DC044' />
                </Stack>

                <Text color='#ffffff' fontSize={14} ml={18} fontWeight='500' numberOfLines={1} flex={1}>
                    {title || 'Granja'}
                </Text>

                <Icon name={isOpen ? 'chevron-left' : 'chevron-right'} size={24} color='#376A7B' />
            </Stack>

            <DrawerSeparator />
        </TouchableOpacity>
    );
};

export default memo(DrawerFarmSelectorButton);
