import { PlaceSetupForm } from '@legacy/chicken-house/entities/PlaceSetupForm';
import { useCameraSetup } from '@legacy/chicken-house/hooks/camera/useCameraSetup';
import Button from '@legacy/shared/components/ui/Button';
import { useErrorHandler } from '@legacy/shared/hooks/useErrorHandler';
import { useToast } from '@legacy/shared/hooks/useToast';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Stack } from 'tamagui';

const SubmitCamerasButton = () => {
    const insets = useSafeAreaInsets();
    const navigation = useNavigation();

    const errorHandler = useErrorHandler();
    const toast = useToast();

    const form = useFormContext<PlaceSetupForm>();
    const setupCameras = useCameraSetup();

    const onSubmit = async (data: PlaceSetupForm) => {
        try {
            const response = await setupCameras.mutateAsync(data);

            toast.show({
                title: response.message,
                message: 'As câmeras foram salvas',
                type: 'success',
            });

            navigation.navigate('Home');
        } catch (err) {
            errorHandler.handle(err);
        }
    };

    return (
        <Stack p={16} pb={insets.bottom || 10}>
            <Button onPress={form.handleSubmit(onSubmit)} loading={setupCameras.isLoading}>
                Salvar
            </Button>
        </Stack>
    );
};

export default SubmitCamerasButton;
