import { LineChart, SegmentedControl } from '@alltis/ui';
import React from 'react';
import MonitoringBaseCard, { MonitoringBaseCardChart, MonitoringBaseCardInfo } from '../MonitoringBaseCard';
import MonitoringCardData from '../MonitoringCardData';

const RationMonitor = () => {
    return (
        <MonitoringBaseCard title='Consumo de Ração' icon='ration'>
            <MonitoringBaseCardChart>
                <LineChart data={{}} height={170} />
            </MonitoringBaseCardChart>

            <MonitoringBaseCardInfo>
                <MonitoringCardData
                    title='Média 127 toneladas/dia'
                    label='Total de Hoje'
                    quantity={{
                        value: 97,
                        suffix: 'toneladas',
                    }}
                    header={
                        <SegmentedControl
                            items={[
                                { label: 'Dia', value: 'day' },
                                { label: 'Ave', value: 'bird' },
                                { label: 'Ovo', value: 'egg' },
                            ]}
                        />
                    }
                />
            </MonitoringBaseCardInfo>
        </MonitoringBaseCard>
    );
};

export default RationMonitor;
