import React from 'react';
import { Checkbox, Stack, Text, XStack } from '@alltis/ui';
import ReportPopoverOption from '../../ReportPopoverOption';
import { GenerateReportFormData, MetricType } from '@/metrics/types/GenerateReportForm';
import { Controller, useFormContext } from 'react-hook-form';

type Metric = {
    name: string;
    type: MetricType;
    disabled: boolean;
};

const ReportPopoverMetricsOptions = () => {
    const { control } = useFormContext<GenerateReportFormData>();

    const metrics: Metric[] = [
        { name: 'Número de Aves', type: 'bird_count', disabled: true },
        { name: 'Produção de Ovos', type: 'egg_production', disabled: false },
        { name: 'Consumo de Ração', type: 'ration_consumption', disabled: true },
        { name: 'Consumo de Água', type: 'water_consumption', disabled: true },
        { name: 'Ambiência', type: 'ambience', disabled: true },
        { name: 'Armazenamento Silos', type: 'silo_status', disabled: true },
    ];

    return (
        <ReportPopoverOption icon='metrics' title='Métricas'>
            <Stack pt={12}>
                {metrics.map((metric) => (
                    <XStack
                        ai='center'
                        key={metric.name}
                        mb={14}
                        disabled={metric.disabled}
                        opacity={metric.disabled ? 0.5 : 1}
                        pointerEvents={metric.disabled ? 'none' : 'auto'}
                    >
                        <Controller
                            name={`metrics.${metric.type}`}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Checkbox value={field.value} onValueChange={field.onChange} />

                                    <Text
                                        ml={8}
                                        fontSize={14}
                                        color='#376A7B'
                                        fontWeight={400}
                                        onPress={() => field.onChange(!field.value)}
                                        cursor='pointer'
                                    >
                                        {metric.name}
                                    </Text>
                                </>
                            )}
                        />
                    </XStack>
                ))}
            </Stack>
        </ReportPopoverOption>
    );
};

export default ReportPopoverMetricsOptions;
