import { useGetUser } from '@/authentication/hooks/useGetUser';
import { RouteProp, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Stack, Text } from 'tamagui';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';
import { ReportMetricResult } from '../ReportMetricResult';
import CompanyLogo from '@/user/components/CompanyLogo';

import WhiteLogo from '../../../../assets/logo/alltis_logo_white.png';
import { Image } from 'expo-image';

export const ReportMetricsPDF = () => {
    const { data: user } = useGetUser();

    const { params } = useRoute<RouteProp<UserStackParams, 'ReportMetricResult'>>();

    const parsed = useMemo(() => {
        const startDate = dayjs(params?.startDate, 'YYYY-MM-DD').tz('America/Sao_Paulo').toDate();
        const endDate = dayjs(params?.endDate, 'YYYY-MM-DD').tz('America/Sao_Paulo').toDate();

        return {
            startDate,
            endDate,
            aviaryIds: params.aviaryIds,
            metrics: params.metrics,
        };
    }, [params]);

    return (
        <Stack>
            <Stack
                px='$10'
                bg={user?.organization?.header_color || '$primary500'}
                jc='space-between'
                ai='center'
                flexDirection='row'
                minHeight={100}
            >
                <Stack width={200} justifyContent='flex-start' position='absolute' left={20}>
                    <Text color={user?.organization?.header_font_color || 'white'} whiteSpace='nowrap' fontSize={10}>
                        {user?.organization?.name || 'Relatório'}
                    </Text>

                    <Text color={user?.organization?.header_font_color || 'white'} whiteSpace='nowrap' mt={8} fontSize={10}>
                        {dayjs(parsed.startDate).format('DD/MM/YYYY')} até {dayjs(parsed.endDate).format('DD/MM/YYYY')}
                    </Text>
                </Stack>

                <Stack flex={1} jc='center' ai='center'>
                    <CompanyLogo user={user} />
                </Stack>

                <Stack width={300} justifyContent='flex-end' alignItems='flex-end' position='absolute' right={20}>
                    <Text color={user?.organization?.header_font_color || 'white'} textAlign='right' fontSize={10} mb={10}>
                        Emitido em {dayjs().tz('America/Sao_Paulo').format('DD/MM/YYYY')}
                    </Text>

                    <Image source={WhiteLogo} style={{ width: 80, height: 12 }} contentFit='contain' />
                </Stack>
            </Stack>

            <Stack p={16}>
                <ReportMetricResult />
            </Stack>
        </Stack>
    );
};
