import { LineChart, SegmentedControl } from '@alltis/ui';
import React from 'react';
import MonitoringBaseCard, { MonitoringBaseCardChart, MonitoringBaseCardInfo } from '../MonitoringBaseCard';
import MonitoringCardData from '../MonitoringCardData';

const EggsProductionMonitor = () => {
    return (
        <MonitoringBaseCard title='Produção de Ovos' icon='eggs'>
            <MonitoringBaseCardChart>
                <LineChart data={{}} height={170} />
            </MonitoringBaseCardChart>

            <MonitoringBaseCardInfo>
                <MonitoringCardData
                    quantity={{
                        value: 875326,
                        suffix: 'ovos',
                    }}
                    title='Média 1.205.212 ovos/dia'
                    label='Total de Hoje'
                    header={
                        <SegmentedControl
                            items={[
                                {
                                    label: 'Unidade',
                                    value: 'unit',
                                },
                                {
                                    label: '%',
                                    value: 'percent',
                                },
                            ]}
                        />
                    }
                />
            </MonitoringBaseCardInfo>
        </MonitoringBaseCard>
    );
};

export default EggsProductionMonitor;
