import * as FileSystem from 'expo-file-system';
import * as IntentLauncher from 'expo-intent-launcher';
import { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import FileViewer from 'react-native-file-viewer';
import mimeTypes from 'react-native-mime-types';

export type Format = 'pdf' | 'xlsx';

export function useOpenFile() {
    const [isLoading, setIsLoading] = useState(false);

    const open = useCallback(async (uri: string, format: Format) => {
        try {
            setIsLoading(true);

            if (Platform.OS === 'android') {
                const cUri = await FileSystem.getContentUriAsync(uri);

                await IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                    data: cUri,
                    flags: 1,
                    type: mimeTypes.contentType(format) || '',
                });
            }

            if (Platform.OS === 'ios') {
                await FileViewer.open(uri);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { open, isLoading };
}
