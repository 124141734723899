import MonitoringEntities from '@/aviary/components/Monitoring/MonitoringEntities';
import MonitoringMap from '@/aviary/components/Monitoring/MonitoringMap';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';
import TabBar from '@/shared/components/ui/TabBar';
import { Stack, XStack } from '@alltis/ui';
import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { TabView } from 'react-native-tab-view';
import { useMedia } from 'tamagui';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';

export const Monitoring = () => {
    const [index, setIndex] = useState(0);

    const media = useMedia();

    const scrollStyle = {
        paddingHorizontal: media.md ? 32 : 16,
        paddingVertical: media.md ? 40 : 24,
        backgroundColor: '#EBEBEA',
    };

    if (media.md) {
        return (
            <ScrollView contentContainerStyle={scrollStyle}>
                <AppBar />

                <XStack gap={24}>
                    <Stack width='49%'>
                        <MonitoringEntities />
                    </Stack>

                    <Stack width='50%'>
                        <MonitoringMap />
                    </Stack>
                </XStack>
            </ScrollView>
        );
    }

    const renderScene = ({ route }) => {
        switch (route.key) {
            case 'entities':
                return (
                    <ScrollView contentContainerStyle={[scrollStyle]}>
                        <MonitoringEntities />

                        <SafeBottom />
                    </ScrollView>
                );
            case 'map':
                return <MonitoringMap borderless />;
        }
    };

    return (
        <Stack flex={1} backgroundColor='#EBEBEA'>
            <AppBar />

            <TabView
                onIndexChange={setIndex}
                renderScene={renderScene}
                navigationState={{
                    index,
                    routes: [
                        { key: 'entities', title: 'Monitoramento' },
                        { key: 'map', title: 'Mapa' },
                    ],
                }}
                renderTabBar={(props) => <TabBar {...props} colorMode='light' />}
            />
        </Stack>
    );
};

export default Monitoring;
