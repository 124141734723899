import { useNavigation } from '@react-navigation/native';
import { PropsWithChildren, useEffect } from 'react';
import { XStack } from 'tamagui';

export const HeaderRight = ({ children }: PropsWithChildren) => {
    const navigation = useNavigation();

    useEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <XStack ai='center' gap={8}>
                    {children}
                </XStack>
            ),
        });
    }, [navigation, navigation.setOptions, children]);

    return null;
};
