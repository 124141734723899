import { CameraRecord } from '@legacy/camera-video/entities/CameraRecord';
import { useDeleteCameraRecording } from '@legacy/camera-video/hooks/useDeleteCameraRecording';
import { useOpenSystemVideo } from '@legacy/chicken-house/hooks/recordings/useOpenSystemVideo';
import { useShareVideo } from '@legacy/chicken-house/hooks/recordings/useShareVideo';
import { useErrorHandler } from '@legacy/shared/hooks/useErrorHandler';
import React, { PropsWithChildren } from 'react';
import { Alert } from 'react-native';
import { useToast } from '@legacy/shared/hooks/useToast';
import { MenuView } from '@legacy/shared/components/ui/MenuView';

type Props = PropsWithChildren<{
    recording: CameraRecord;
}>;

enum RecordingAction {
    Open = 'open',
    Share = 'share',
    Delete = 'delete',
}

const PlaceRecordingMenu = ({ recording, children }: Props) => {
    const errorHandler = useErrorHandler();
    const toast = useToast();

    const { open: openVideo } = useOpenSystemVideo();
    const { share: shareVideo } = useShareVideo();
    const { mutateAsync: deleteRecording } = useDeleteCameraRecording();

    async function onConfirmDelete() {
        try {
            await deleteRecording(recording);

            toast.show({
                title: 'Successo',
                message: 'A Gravação foi apagada',
            });
        } catch {
            errorHandler.handle(recording);
        }
    }

    function handleDelete() {
        Alert.alert('Apagar gravação', 'Tem certeza que deseja apagar essa gravação? Essa ação não poderá ser desfeita', [
            {
                text: 'Sim, apagar',
                style: 'destructive',
                onPress: onConfirmDelete,
            },
            {
                text: 'Cancelar',
                isPreferred: true,
            },
        ]);
    }

    function handleShare() {
        shareVideo(recording.path);
    }

    function handleOpen() {
        openVideo(recording.path);
    }

    return (
        <MenuView
            actions={[
                {
                    id: RecordingAction.Open,
                    title: 'Abrir gravação',
                    image: 'video',
                },
                {
                    id: RecordingAction.Share,
                    title: 'Compartilhar gravação',
                    image: 'square.and.arrow.up',
                },
                {
                    id: RecordingAction.Delete,
                    title: 'Excluir gravação',
                    attributes: {
                        destructive: true,
                    },
                    image: 'trash',
                },
            ]}
            isAnchoredToRight
            title='Gravação da Câmera'
            onPressAction={({ nativeEvent: { event } }) => {
                if (event === RecordingAction.Open) {
                    return handleOpen();
                }

                if (event === RecordingAction.Delete) {
                    return handleDelete();
                }

                if (event === RecordingAction.Share) {
                    return handleShare();
                }
            }}
        >
            {children}
        </MenuView>
    );
};

export default PlaceRecordingMenu;
