import { Stack, Text, XStack } from '@alltis/ui';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { DataTableProps } from './DataTableProps';
import { ScrollView } from 'tamagui';

type ColumnMeta = {
    isCustomCell?: boolean;
    grow?: number;
};

export const DataTable = <T,>(props: DataTableProps<T>) => {
    const table = useReactTable({
        columns: props.columns.map((column) => ({
            ...column,
            meta: {
                ...column.meta,
                isCustomCell: !!column.cell && typeof column.cell === 'function',
                grow: column.grow,
            },
        })),
        data: props.data,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <ScrollView
            horizontal
            style={{ overflow: 'visible' }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
        >
            <Stack>
                <XStack mb={12}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <XStack key={headerGroup.id} flex={1} pl={24}>
                            {headerGroup.headers.map((header) => (
                                <Stack
                                    flexGrow={(header.column.columnDef.meta as ColumnMeta)?.grow}
                                    width={(header.column.columnDef.meta as ColumnMeta)?.grow ? undefined : header.getSize()}
                                    key={header.id}
                                >
                                    <Text color='#588290' fontSize={16}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </Text>
                                </Stack>
                            ))}
                        </XStack>
                    ))}
                </XStack>

                <Stack br={8} bw={2} borderColor='#EBEBEA'>
                    <Stack py={24} gap={21}>
                        {table.getRowModel().rows.map((row) => (
                            <XStack key={row.id} pl={24}>
                                {row.getVisibleCells().map((cell, index) => {
                                    const isCustomCell = (cell.column.columnDef.meta as ColumnMeta).isCustomCell;

                                    const isLastColumn = index === row.getVisibleCells().length - 1;

                                    if (isCustomCell) {
                                        return (
                                            <Stack
                                                width={
                                                    (cell.column.columnDef.meta as ColumnMeta)?.grow
                                                        ? undefined
                                                        : cell.column.getSize()
                                                }
                                                key={cell.id}
                                                alignItems={isLastColumn ? 'flex-end' : undefined}
                                                pr={24}
                                                flexGrow={(cell.column.columnDef.meta as ColumnMeta)?.grow}
                                                height={30}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Stack>
                                        );
                                    }

                                    return (
                                        <Stack
                                            width={
                                                (cell.column.columnDef.meta as ColumnMeta)?.grow
                                                    ? undefined
                                                    : cell.column.getSize()
                                            }
                                            key={cell.id}
                                            pr={24}
                                            flexGrow={(cell.column.columnDef.meta as ColumnMeta)?.grow}
                                        >
                                            <Text color='#376A7B' fontWeight='500' fontSize={16}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Text>
                                        </Stack>
                                    );
                                })}
                            </XStack>
                        ))}
                    </Stack>

                    {props.renderFooter && (
                        <Stack btw={2} borderColor='#EBEBEA'>
                            {props.renderFooter()}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </ScrollView>
    );
};
