import { NumericInput, Switch, Text, XStack } from '@alltis/ui';
import React from 'react';
import SettingGridLayout from '../SettingGridLayout';

type Props = {
    label: string;
    prefix: string;
};

const RangeSettingField = (props: Props) => {
    return (
        <SettingGridLayout
            Middle={
                <XStack gap={16}>
                    <NumericInput prefix={props.prefix} />
                    <NumericInput prefix={props.prefix} />
                </XStack>
            }
            Start={
                <Text color='#588290' fontSize={14} fontWeight='400'>
                    {props.label}
                </Text>
            }
            End={<Switch />}
        />
    );
};

export default RangeSettingField;
