import { usePrivacyPolicy } from '@legacy/authentication/hooks/usePrivacyPolicy';
import { useAuth } from '@legacy/authentication/store/AuthStore';
import Section from '@legacy/shared/components/ui/Section';
import Guard from '@legacy/shared/components/utils/Guard';
import { useMainStatusBar } from '@legacy/shared/hooks/useMainStatusBar';
import { useTesterSettings } from '@legacy/user/store/TesterSettingsStore';
import React from 'react';
import { Platform } from 'react-native';
import { Stack } from 'tamagui';
import { useNotificationsEnabled } from '../../../../../config/notifications/useNotificationsEnabled';
import TesterSettings from './TesterSettings';
import NotificationSettings from './TesterSettings/NotificationSettings';
import { useLogout } from '@/authentication/hooks/useLogout';

const Menu = () => {
    useMainStatusBar();

    const logout = useLogout();

    const privacyPolicy = usePrivacyPolicy();

    const enableTesterSettings = useTesterSettings((settings) => settings.isEnabled);

    const enableNotificationsSettings = useNotificationsEnabled();

    const isLogged = useAuth((state) => !!state.auth?.token);

    return (
        <Stack>
            <Guard isEnabled={false}>
                <Section name='Termos e condições'>
                    <Section.Item title='Política de Privacidade' icon='shield' onPress={() => privacyPolicy.open()} />
                </Section>
            </Guard>

            {enableNotificationsSettings && <NotificationSettings />}

            {enableTesterSettings && <TesterSettings />}

            {isLogged && (
                <Section name='Minha conta'>
                    <Section.Item
                        title='Sair da Conta'
                        icon='log-out'
                        onPress={() => logout.handleLogout({ hasConfirmation: Platform.OS !== 'web' })}
                        loading={logout.isLoading}
                    />
                </Section>
            )}
        </Stack>
    );
};

export default Menu;
