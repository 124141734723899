import { Stack, Text } from '@alltis/ui';

import React, { PropsWithChildren } from 'react';

type Props = {
    title: string;
};

const ConfigurationSection = (props: PropsWithChildren<Props>) => {
    return (
        <Stack backgroundColor='white' width='100%' br={8} overflow='hidden'>
            <Stack
                flexDirection='row'
                alignItems='center'
                h={56}
                justifyContent='center'
                width='100%'
                borderBottomWidth={1}
                borderColor='#EBEBEA'
            >
                <Text color='#8CA9B3' fontWeight={600} fontSize={18}>
                    {props.title}
                </Text>
            </Stack>

            <Stack px={22} py={20} $md={{ px: 32 }}>
                {props.children}
            </Stack>
        </Stack>
    );
};

export default ConfigurationSection;
