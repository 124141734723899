import { toFixedOrNot } from '@legacy/shared/utils/number';

/**
 * Values configuration to choose between tons or percentage
 */
type FormatValues =
    | {
          tons: number | undefined;
          perc: number | undefined;
      }
    | number;

/**
 * Used to choose values between percentage or tons to show on screen, based on warehouse configuration
 * @param isTons if `true`, formatted values will be considered as tons
 */
export function useQuantityUnits(isTons = false) {
    function getValue(config: FormatValues): number {
        const values = {
            perc: typeof config === 'number' ? config : config.perc,
            tons: typeof config === 'number' ? config : config.tons,
        };

        if (isTons && values.tons) {
            return values.tons;
        }

        return (values.perc || 0) * 100;
    }

    function format(configOrValue: FormatValues) {
        const value = getValue(configOrValue);

        if (isTons) {
            return toFixedOrNot(value, 1) + 't';
        }

        return toFixedOrNot(value, 1) + '%';
    }

    return {
        getValue,
        format,
    };
}
