import { EggsReportForm } from '@legacy/chicken-house/entities/reports/EggsReportForm';
import { useEggReport } from '@legacy/chicken-house/hooks/reports/useEggReports';
import { Report } from '@legacy/reports/components';
import React from 'react';
import EggReportResult from '../EggReportResult';

type Props = {
    filter: EggsReportForm;
};

const EggReportContent = ({ filter }: Props) => {
    const report = useEggReport(filter as EggsReportForm);

    const hasChickenHouses = !!filter.chickenHouseIds?.length;

    return (
        <Report.Content
            title='Relatório dos Aviários'
            options={{
                empty: !report.data || !hasChickenHouses,
                error: report.isError,
                loading: report.isLoading && hasChickenHouses,
            }}
        >
            <Report.List data={report?.data} renderItem={({ item }) => <EggReportResult report={item} />} />
        </Report.Content>
    );
};

export default EggReportContent;
