import { CameraSource } from '@legacy/camera-video/entities/CameraSource';
import { useQuery } from '@tanstack/react-query';
import { useCameraServer } from '../../store/CameraServerStore';
import { createURL, getIpv4FromString } from '../../utils/network';
import { cameraScanApi } from '../../api/config/cameraScanApi';

type IPSResponse = {
    ip: string;
    ports: number[];
    stream_mac: string;
}[];

export function useAvailableCameraSources() {
    const { scanResult, networkConfig } = useCameraServer();

    return useQuery<CameraSource[]>(
        ['available-cameras'],
        async () => {
            if (!networkConfig) return [];

            const cameras = await cameraScanApi.get<IPSResponse>('/scan');

            const cameraSources: CameraSource[] = [];

            cameras.data.forEach((ipResponse) => {
                const ipv4 = getIpv4FromString(ipResponse.ip);
                const allPorts = ipResponse.ports;

                return allPorts.forEach((port) => {
                    cameraSources.push({
                        url: createURL({
                            protocol: 'rtsp',
                            port: port,
                            host: ipv4,
                            auth: networkConfig.auth?.enableAuth ? networkConfig.auth : undefined,
                            path: networkConfig.networkPath || '',
                        }),
                        streamMAC: ipResponse.stream_mac,
                        ip: ipv4,
                    });
                });
            });

            return cameraSources;
        },
        {
            enabled: scanResult?.status === 'DONE' && !!networkConfig,
        }
    );
}
