import { WarehouseIcon } from '@legacy/shared/components/ui/Icons/WarehouseIcon';
import ViewState from '@legacy/shared/components/ui/ViewState';
import React from 'react';

type Props = {
    loading?: boolean;
    hasSilo: boolean;
};

const SiloViewState = ({ hasSilo, loading }: Props) => {
    return (
        <ViewState
            loading={loading}
            empty={{
                enabled: !hasSilo,
                title: 'Nenhum silo selecionado',
                message: 'Selecione um silo para ver os detalhes',
                renderIcon: () => <WarehouseIcon size={120} color='#acacac' />,
            }}
        />
    );
};

export default SiloViewState;
