import { useSiloReportNavigation } from '@/warehouse/hooks/navigation/useSiloReportNavigation';
import React from 'react';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';

const SiloAppBar = () => {
    const siloReportNavigation = useSiloReportNavigation();

    return <AppBar onReportPress={() => siloReportNavigation.openReportPage()} />;
};

export default SiloAppBar;
