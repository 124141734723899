import WebProvider from '@/shared/components/providers/WebProvider';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { PropsWithChildren } from 'react';
import { CacheProvider } from '../cache';
import { ThemeProvider } from '../theme/ThemeProvider';

export function AppProviders(props: PropsWithChildren) {
    return (
        <CacheProvider>
            <ThemeProvider>
                <WebProvider>
                    <BottomSheetModalProvider>{props.children}</BottomSheetModalProvider>
                </WebProvider>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default AppProviders;
