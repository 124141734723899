import DrawerFarmSelectorButton from '@/user/components/DrawerFarmSelectorButton';
import { useSelectedFarm } from '@/user/store/SelectedFarmStore';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { TabView } from 'react-native-tab-view';
import { ScrollView, Stack } from 'tamagui';
import { useFlag } from '../../../../../../config/feature-flag';
import DrawerFarmSelector from '../../../../../../containers/user/components/DrawerFarmSelector';
import { useDrawerStore } from '../../store/DrawerStore';
import DrawerAnimatedContainer from './DrawerAnimatedContainer';
import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';
import DrawerRoutes from './DrawerRoutes';
import DrawerSeparator from './DrawerSeparator';

const CustomAppDrawer = () => {
    const navigation = useNavigation();

    const showFarmSelectionButton = useFlag('HEADER_FARM_SELECTION_BUTTON', false);

    const farm = useSelectedFarm((store) => store.farm);

    const [isSelectingFarm, setIsSelectingFarm] = useState(false);

    const toggleOverlay = useDrawerStore((store) => store.toggleOverlay);

    function onBackFarmSelection() {
        setIsSelectingFarm(false);
        toggleOverlay(false);
    }

    const renderRoute = (key: string) => {
        if (key === 'menu') {
            return (
                <Stack flex={1}>
                    {showFarmSelectionButton && (
                        <Stack mb={6}>
                            <DrawerFarmSelectorButton
                                title={farm?.name}
                                onPress={() => {
                                    setIsSelectingFarm(true);
                                    toggleOverlay(true);
                                }}
                            />
                        </Stack>
                    )}

                    <Stack flex={1}>
                        <ScrollView contentContainerStyle={{ marginTop: 12, minHeight: '100%' }}>
                            <DrawerRoutes />

                            <Stack flex={1} />

                            <DrawerFooter />
                        </ScrollView>
                    </Stack>
                </Stack>
            );
        }

        return (
            <Stack flex={1}>
                <Stack pb={6} flex={1}>
                    <DrawerFarmSelector
                        onFarmSelected={() => {
                            navigation.dispatch(DrawerActions.closeDrawer());
                        }}
                        onBack={onBackFarmSelection}
                    />
                </Stack>
            </Stack>
        );
    };

    return (
        <DrawerAnimatedContainer onOverlayPress={onBackFarmSelection}>
            <LinearGradient colors={['#05455A', '#021D26']} style={{ flex: 1 }}>
                <Stack flex={1} maxHeight='100vh'>
                    <DrawerHeader />

                    <DrawerSeparator mt={24} />

                    <TabView
                        style={{ flex: 1 }}
                        renderScene={({ route }) => renderRoute(route.key)}
                        navigationState={{
                            index: isSelectingFarm ? 1 : 0,
                            routes: [{ key: 'menu' }, { key: 'farms' }],
                        }}
                        renderTabBar={() => null}
                        onIndexChange={() => null}
                        swipeEnabled={false}
                        lazy={false}
                    />
                </Stack>
            </LinearGradient>
        </DrawerAnimatedContainer>
    );
};

export default CustomAppDrawer;
