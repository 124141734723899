import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Metrics = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 24 24' fill='none'>
            <Path d='M3 5V19H21' stroke={props.color} stroke-width='2' stroke-linecap='square' />
            <Path d='M16 6H20V10L18 8L16 6Z' fill={svg.color} />
            <Path
                d='M8 10L8.5145 9.14251L8 8.83381L7.4855 9.14251L8 10ZM13 13L12.4855 13.8575L13.155 14.2592L13.7071 13.7071L13 13ZM20 6H21C21 5.44772 20.5523 5 20 5V6ZM16 6V5C15.5955 5 15.2309 5.24364 15.0761 5.61732C14.9213 5.99099 15.0069 6.42111 15.2929 6.70711L16 6ZM20 10L19.2929 10.7071C19.5789 10.9931 20.009 11.0787 20.3827 10.9239C20.7564 10.7691 21 10.4045 21 10H20ZM3.5145 13.8575L8.5145 10.8575L7.4855 9.14251L2.4855 12.1425L3.5145 13.8575ZM7.4855 10.8575L12.4855 13.8575L13.5145 12.1425L8.5145 9.14251L7.4855 10.8575ZM13.7071 13.7071L18.7071 8.70711L17.2929 7.29289L12.2929 12.2929L13.7071 13.7071ZM18.7071 8.70711L20.7071 6.70711L19.2929 5.29289L17.2929 7.29289L18.7071 8.70711ZM16 7H20V5H16V7ZM19 6V10H21V6H19ZM15.2929 6.70711L17.2929 8.70711L18.7071 7.29289L16.7071 5.29289L15.2929 6.70711ZM17.2929 8.70711L19.2929 10.7071L20.7071 9.29289L18.7071 7.29289L17.2929 8.70711Z'
                fill={props.color}
            />
        </BaseSvg>
    );
};

export default { name: 'metrics', icon: Metrics } as const;
