import { useLogin } from '@/authentication/hooks/useLogin';
import { LoginFormData, loginFormSchema } from '@/authentication/utils/validators/loginSchema';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { useTesterSettings } from '@/user/store/TesterSettingsStore';
import { Button, Stack } from '@alltis/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import { isAxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Keyboard } from 'react-native';

import LoginAppLogo from '@/authentication/components/LoginAppLogo';
import LoginBackground from '@/authentication/components/LoginBackground';
import LoginFormFields from '@/authentication/components/LoginFormFields';
import LoginFormWrapper from '@/authentication/components/LoginFormWrapper';

const Login = () => {
    useStatusBar('dark-content');

    const errorHandler = useErrorHandler();
    const login = useLogin();

    const isTesterSettingsEnabled = useTesterSettings((state) => state.isEnabled);

    const navigation = useNavigation();

    const loginForm = useForm<LoginFormData>({
        resolver: zodResolver(loginFormSchema),
        defaultValues: {
            email: process.env.EXPO_PUBLIC_DEFAULT_EMAIL || '',
            password: process.env.EXPO_PUBLIC_DEFAULT_PASSWORD || '',
        },
    });

    async function onLogin(data: LoginFormData) {
        try {
            Keyboard.dismiss();

            await login.mutateAsync(data);
        } catch (err) {
            if (isAxiosError(err) && err.response?.status === 401) {
                return errorHandler.handle(err, {
                    title: 'Login ou senha incorretos',
                    message: 'Verifique suas credenciais',
                });
            }

            return errorHandler.handle(err);
        }
    }

    return (
        <LoginBackground>
            <Stack
                flex={1}
                $platform-web={{
                    jc: 'center',
                    ai: 'center',
                }}
            >
                <Stack p={40} $md={{ width: 420 }} $xs={{ width: '100%' }}>
                    <LoginAppLogo />

                    <FormProvider {...loginForm}>
                        <LoginFormWrapper>
                            <LoginFormFields onSubmit={loginForm.handleSubmit(onLogin)} />

                            <Button
                                disabled={!loginForm.formState.isValid}
                                loading={login.isLoading}
                                onPress={() => loginForm.handleSubmit(onLogin)()}
                                mt={32}
                                variant='secondary'
                            >
                                Entrar
                            </Button>

                            {isTesterSettingsEnabled && (
                                <Button
                                    disabled={login.isLoading}
                                    onPress={() => navigation.navigate('Settings')}
                                    mt={10}
                                    variant='secondary'
                                >
                                    Configurações
                                </Button>
                            )}
                        </LoginFormWrapper>
                    </FormProvider>
                </Stack>
            </Stack>
        </LoginBackground>
    );
};

export default Login;
