import { DrawerActions, getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { Stack } from 'tamagui';
import { useMainRoutes } from '../../../hooks/useMainRoutes';
import { DrawerRoute } from '../../../types';
import DrawerItem from '../DrawerItem';
import DrawerSeparator from '../DrawerSeparator';
import { useDrawerCollapsed } from '../../../hooks/useDrawerCollapsed';
import { useDrawerStore } from '../../../store/DrawerStore';

const DrawerRoutes = () => {
    const sections = useMainRoutes();
    const isCollapsed = useDrawerCollapsed();
    const drawerStore = useDrawerStore();

    const route = useRoute();

    const focusedRouteName = useMemo(() => getFocusedRouteNameFromRoute(route), [route]);

    const navigation = useNavigation();

    const onPress = useCallback((item: DrawerRoute) => {
        if (item.key) {
            navigation.navigate(item.key);
            navigation.dispatch(DrawerActions.closeDrawer());

            drawerStore.toggleCollapsed(true);
        }
    }, []);

    const isTabActive = useCallback((item: DrawerRoute) => focusedRouteName === item.key, [focusedRouteName]);

    return (
        <Stack
            $platform-web={{
                minHeight: '47vh',
            }}
            minHeight='60%'
        >
            {sections.map((section, index) => (
                <Stack key={index}>
                    {section.routes.map((route, index) =>
                        route.hide ? null : (
                            <DrawerItem
                                isCollapsed={isCollapsed}
                                key={route.key || index}
                                item={route}
                                onPress={onPress}
                                isActive={isTabActive(route)}
                            />
                        )
                    )}

                    {section.routes.some((route) => !route.hide) && <DrawerSeparator my={12} />}
                </Stack>
            ))}
        </Stack>
    );
};

export default DrawerRoutes;
