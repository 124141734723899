import ViewState from '@/shared/components/ui/ViewState';
import { FlashList, FlashListProps } from '@shopify/flash-list';
import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { Separator, Stack, useMedia, View } from 'tamagui';
import { useNewUIEnabled } from '../../../../config/theme/hooks/useNewUIEnabled';
import { Skeleton, SkeletonView } from '../Skeleton';
import PageListCard from './components/PageListCard';
import PageListCardContent from './components/PageListCardContent';
import PageListCardHeader from './components/PageListCardHeader';
import PageListCardInfo from './components/PageListCardInfo';
import { isWebOrTablet } from '../../../../config/constants/webOrTablet';
import { RefreshControl, RefreshControlProps } from '../RefreshControl';

type Props<T> = {
    data: T[] | undefined;
    isError?: boolean;
    isLoading?: boolean;
} & Pick<
    FlashListProps<T>,
    | 'renderItem'
    | 'ListEmptyComponent'
    | 'ListHeaderComponent'
    | 'ListFooterComponent'
    | 'children'
    | 'estimatedItemSize'
    | 'keyboardShouldPersistTaps'
    | 'extraData'
> & {
        onRefresh?: RefreshControlProps['onRefresh'];
    };

const PageList = <T,>(props: Props<T>) => {
    const media = useMedia();
    const isNewUIEnabled = useNewUIEnabled();
    const window = useWindowDimensions();

    const numColumns = useMemo(() => {
        const MAX_COLUMN_SIZE = media?.md ? 450 : 300;
        return Math.floor(window.width / MAX_COLUMN_SIZE);
    }, [window.width, media?.md, media?.lg, media?.sm]);

    const ListEmptyContent = () => {
        // eslint-disable-next-line react/prop-types
        if (props.ListEmptyComponent) return props.ListEmptyComponent;

        if (props.isLoading) {
            return (
                <View flexDirection='row' flexWrap='wrap' gap={14} pr={media.md ? 24 : 0}>
                    <Skeleton>
                        {new Array(9).fill(null).map((_, index) => (
                            <SkeletonView width={300} height={300} flexGrow={1} key={index} />
                        ))}
                    </Skeleton>
                </View>
            );
        }

        return <ViewState empty={props.data?.length === 0} error={props.isError} loading={props.isLoading} />;
    };

    return (
        <Stack flex={1} bg={isNewUIEnabled ? '#EBEBE9' : '#ffffff'}>
            <FlashList
                {...props}
                onRefresh={undefined}
                data={props.data || []}
                contentContainerStyle={{
                    paddingVertical: media.md ? 32 : 24,
                    paddingHorizontal: media.md ? 32 : 16,
                    paddingRight: media.md ? 8 : 16,
                }}
                estimatedItemSize={props.estimatedItemSize || 100}
                renderItem={(...renderItemProps) => (
                    <Stack flex={1} $sm={{ mr: 24 }} mr={0}>
                        {props.renderItem?.(...renderItemProps)}
                    </Stack>
                )}
                ItemSeparatorComponent={() => (
                    <Separator $platform-native={{ my: 12 }} $platform-web={{ border: 'none', my: 12 }} />
                )}
                ListEmptyComponent={ListEmptyContent()}
                numColumns={isWebOrTablet ? numColumns : 1}
                key={isWebOrTablet ? numColumns : 1}
                refreshControl={props.onRefresh ? <RefreshControl onRefresh={props.onRefresh} /> : undefined}
            />
            {props.children}
        </Stack>
    );
};

PageList.Card = PageListCard;
PageList.CardHeader = PageListCardHeader;
PageList.CardContent = PageListCardContent;
PageList.CardInfo = PageListCardInfo;

export default PageList;
