import { DeepPartial } from '@legacy/shared/types/DeepPartial';
import { GetSilosResponse } from '@legacy/warehouse/api/types/SiloResponse';
import { Silo } from '@legacy/warehouse/entities/Silo';
import { useQueryClient } from '@tanstack/react-query';
import merge from 'lodash.merge';

export function useSiloCacheHelper() {
    const queryClient = useQueryClient();

    function updateById(siloId: string, newCache: DeepPartial<Silo>) {
        queryClient.setQueryData(['silos'], (response: GetSilosResponse) => {
            if (!response) return null;

            return {
                silos: response.silos.map((silo) => {
                    if (silo.id === siloId) {
                        return merge(silo, newCache);
                    }

                    return silo;
                }),
            };
        });
    }

    return { updateById };
}
