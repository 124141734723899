import { useMemo } from 'react';

export type ScalableSize = '$0.4' | '$0.5' | '$1' | '$2' | '$3';

export function useSizeScale(baseSize: number, factor: ScalableSize) {
    const getScaleFactor = (factor: ScalableSize): number => {
        switch (factor) {
            case '$0.4':
                return 0.4;
            case '$0.5':
                return 0.5;
            case '$1':
                return 1;
            case '$2':
                return 1.2;
            case '$3':
                return 1.5;
            default:
                return 1;
        }
    };

    const scaledSize = useMemo(() => baseSize * getScaleFactor(factor), [baseSize, factor]);

    return scaledSize;
}
