import ReportControlledForm from '@/reports/components/ReportControlledForm';
import { ReportFilterData, useReportFilter } from '@/reports/hooks/useReportFilter';
import { ReportOptions } from '@/reports/screens/Report/ReportProps';
import { WarehouseIcon } from '@/shared/components/ui/Icons/WarehouseIcon';
import TabBar from '@/shared/components/ui/TabBar';
import { useSilos } from '@/warehouse/hooks/silos/useSilos';
import { useSiloPanel } from '@/warehouse/store/useSiloPanelStore';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';
import { Stack } from 'tamagui';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';
import ConsumptionReport from '../../components/SiloReport/Reports/ConsumptionReport';
import ResupplyReport from '../../components/SiloReport/Reports/ResupplyReport';

type Props = {
    initialFilter?: ReportFilterData;
    options?: ReportOptions;
};

export const SiloReport = ({ initialFilter }: Props) => {
    const silos = useSilos();

    const filter = useReportFilter(initialFilter);

    const { params } = useRoute<RouteProp<UserStackParams, 'WarehouseReport'>>();

    const siloPanel = useSiloPanel();

    const activeSilos = useMemo(() => {
        if (siloPanel.currentTab === 'reports' && siloPanel.selectedSiloId) {
            return [siloPanel.selectedSiloId];
        }

        return params?.silo?.id ? [params?.silo?.id] : [];
    }, [params?.silo?.id, siloPanel.currentTab, siloPanel.selectedSiloId]);

    useEffect(() => {
        filter.onFilterChange({ entityIds: activeSilos || [] });
    }, [activeSilos]);

    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Stack flex={1}>
            <ReportControlledForm
                filter={filter}
                items={silos.data?.silos.map((item) => ({ label: item.name, value: item.id, caption: item.site?.name || '-' }))}
                selectProps={{ placeholder: 'Selecionar os silos', icon: <WarehouseIcon size={20} color='#CBD5E0' /> }}
            />

            <TabView
                renderScene={SceneMap({
                    consumption: () => <ConsumptionReport filter={filter} enabled={tabIndex === 0} />,
                    resupply: () => <ResupplyReport filter={filter} enabled={tabIndex === 1} />,
                })}
                navigationState={{
                    index: tabIndex,
                    routes: [
                        { key: 'consumption', title: 'Consumo' },
                        { key: 'resupply', title: 'Reabastecimento' },
                    ],
                }}
                onIndexChange={setTabIndex}
                renderTabBar={(props) => <TabBar {...props} colorMode='light' />}
                swipeEnabled={Platform.OS !== 'web'}
            />
        </Stack>
    );
};
