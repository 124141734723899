import Constants from 'expo-constants';
import React, { useMemo } from 'react';
import { Text } from 'tamagui';
import { config } from '../../../../../config/env';
import * as Updates from 'expo-updates';
import dayjs from 'dayjs';

type Props = {
    color?: string;
};

const AppVersion = ({ color = '#c8c8c8' }: Props) => {
    const channel = useMemo(() => {
        if (config.mode === 'production' || !config.mode) return '';

        return `-${config.mode}`;
    }, []);

    function getVersion() {
        if (config.mode !== 'production') {
            return (
                <>
                    {dayjs(Updates.createdAt || new Date()).format('YYYY.MM.YY.HH:mm')}
                    {channel}
                </>
            );
        }

        return (
            <>
                {Constants.expoConfig?.version}
                {channel}
            </>
        );
    }

    return (
        <Text color={color} numberOfLines={1} fontSize={14}>
            v{getVersion()}
        </Text>
    );
};

export default AppVersion;
