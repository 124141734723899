import Button from '@legacy/shared/components/ui/Button';
import React from 'react';
import { Separator, Stack } from 'tamagui';

type Props = {
    onConfirm?: () => void;
};

const FilterContentFooter = (props: Props) => {
    return (
        <Stack bg='white'>
            <Separator />

            <Stack p={18} pb={12}>
                <Button color='secondary' onPress={() => props.onConfirm?.()}>
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};

export default FilterContentFooter;
