import { Header, Icon } from '@/alltis-ui-v2/components';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import React from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Separator, Stack, Text, useMedia } from 'tamagui';

type Props = {
    onClose?: () => void;
    descriptor: NativeStackHeaderProps;
};

const DialogPageHeader = (props: Props) => {
    const media = useMedia();
    const safeArea = useSafeAreaInsets();

    useStatusBar(Platform.OS === 'ios' ? 'light-content' : 'dark-content');

    return (
        <Header
            title={((props.descriptor?.options?.headerTitle || props.descriptor?.options?.title) as string) || ''}
            onClose={props.onClose || props.descriptor?.navigation.goBack}
            renderActions={() =>
                props.descriptor?.options.headerRight?.({
                    tintColor: props.descriptor.options.headerTintColor,
                    canGoBack: props.descriptor.navigation.canGoBack(),
                })
            }
            size='md'
        />
    );
};

export default DialogPageHeader;
