import { Silo } from '@/warehouse/entities/Silo';
import React from 'react';
import { WarehouseStateIcon } from '../WarehouseStateIcon';

type Props = {
    silo: Pick<Silo, 'prefer_tons' | 'name' | 'level' | 'temperature' | 'proportions' | 'cloud_points'>;
    dimensions?: {
        width?: number;
        height?: number;
    };
};

const SiloFigure = ({ silo, dimensions }: Props) => {
    const toPercent = (value: number) => {
        return (value || 0) * 100;
    };

    return (
        <WarehouseStateIcon
            label=''
            percentage={toPercent(silo?.level.current.linear || 0)}
            status={silo?.level.status}
            numberOfPanels={silo?.proportions.plates || 0}
            levelPoints={
                silo.cloud_points?.map((point) => ({
                    x: toPercent(point.pos_x) * 2, // multiply per 2 because x range is from 0.0 to 0.5
                    y: toPercent(point.pos_y),
                })) || []
            }
            proportions={
                silo.proportions && {
                    base: toPercent(silo.proportions.base_height),
                    body: toPercent(silo.proportions.body_height),
                    ceil: toPercent(silo.proportions.ceil_height),
                    diameter: toPercent(silo.proportions.diameter),
                }
            }
            dimensions={dimensions}
        />
    );
};

export default SiloFigure;
