import { RationTable } from '@/ration';
import React from 'react';
import ConfigurationSection from '../../ConfigurationSection';
import { Icon, Stack } from '@alltis/ui';

const RationTypes = () => {
    return (
        <ConfigurationSection title='Tipos de Ração'>
            <Stack flexDirection='row'>
                <Stack mr={11} display='none' $sm={{ display: 'flex' }}>
                    <Icon name='ration' size={24} color='#588290' />
                </Stack>

                <Stack flex={1}>
                    <RationTable />
                </Stack>
            </Stack>
        </ConfigurationSection>
    );
};

export default RationTypes;
