import { useTesterSettings } from '@legacy/user/store/TesterSettingsStore';
import { useEffect } from 'react';
import { useFlag } from '../../../../config/feature-flag';

export function useTesterSettingsEnabled() {
    const isFlagActive = useFlag('TESTER_SETTINGS_ENABLED', false);

    const setTesterSettingsEnabled = useTesterSettings((store) => store.setEnabled);

    useEffect(() => {
        setTesterSettingsEnabled(isFlagActive);
    }, [isFlagActive]);
}
