import { handleResponseNumber } from '@legacy/shared/utils/response';
import { WarehouseReportData } from '@legacy/warehouse/entities/WarehouseReportData';
import dayjs from 'dayjs';
import { maxBy, minBy } from 'lodash';

export type SiloHistoryResponse = {
    id: string;
    name: string;
    group_name: string;
    prefer_tons: true;
    historic: {
        percentage: 0;
        timestamp: string;
        tons: 0;
    }[];
    summary: {
        maximum: {
            timestamp: string;
            percentage: number;
            tons: number;
        };
        minimum: {
            timestamp: string;
            percentage: number;
            tons: number;
        };
        average: {
            percentage: number;
            tons: number;
        };
        sum?: {
            percentage: number;
            tons: number;
        };
    };
}[];

export function useSiloReportMapper() {
    function mapToReportData(data: SiloHistoryResponse[number]): WarehouseReportData {
        const isPercentage = !data.prefer_tons;

        const info: WarehouseReportData = {
            averageValueInPeriod: handleResponseNumber(
                data.prefer_tons ? data.summary.average.tons : data.summary.average.percentage,
                isPercentage
            ),
            historic: data.historic.map((c) => ({
                date: dayjs(c.timestamp).toDate(),
                value: handleResponseNumber(data.prefer_tons ? c.tons : c.percentage, !data.prefer_tons),
            })),
            sumFromPeriod: {
                value: handleResponseNumber(
                    data.prefer_tons ? data.summary.sum?.tons : data.summary.sum?.percentage,
                    !data.prefer_tons
                ),
            },
        };

        info.minimumFromPeriod = minBy(info.historic, (c) => c.value);
        info.maximumFromPeriod = maxBy(info.historic, (c) => c.value);

        return info;
    }

    return { mapToReportData };
}
