import { GetSilosResponse } from '@/warehouse/api/types/SiloResponse';
import { container } from '../../../../config/di';
import { SiloApi } from '../../api/SiloApi';
import { useQuery } from '../../../../config/cache/useQuery';

type Props = {
    refetchOnMount?: boolean;
};

export function useSilos(props?: Props) {
    const warehouseApi = container.resolve(SiloApi);

    return useQuery<GetSilosResponse>({
        queryKey: ['silos'],
        queryFn: async () => {
            return warehouseApi.getSilos();

            return {
                silos: [
                    {
                        id: '159d577e-8de7-468d-bfcf-b57458f230fd',
                        name: 'Domo 3 Pontos [Lobo R4 B]',
                        device_status: 'ACTIVE',
                        temperature: 22,
                        last_seen: '2024-11-12T21:27:42-03:00',
                        prefer_tons: true,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.56122935, tons: 11.105827, linear: 0.59541476 },
                            remaining: { percentage: 0.43877065, tons: 8.682566, days: 4, linear: 0.40458524 },
                            resupply: { tons: 9.176653, percentage: 0.46373916, timestamp: '2024-11-11T10:26:36-03:00' },
                            consumption: {
                                average: { percentage: 0.12999143, tons: 2.5723217 },
                                sum: { percentage: 1.0399314, tons: 20.578573 },
                                historic: [
                                    { tons: 3.1225128, percentage: 0.15779516, timestamp: '2024-11-05T00:00:00-03:00' },
                                    { tons: 0, percentage: 0, timestamp: '2024-11-06T00:00:00-03:00' },
                                    { tons: 0.7062827, percentage: 0.035691764, timestamp: '2024-11-07T00:00:00-03:00' },
                                    { tons: 5.8892183, percentage: 0.29760972, timestamp: '2024-11-08T00:00:00-03:00' },
                                    { tons: 0.082849674, percentage: 0.004186781, timestamp: '2024-11-09T00:00:00-03:00' },
                                    { tons: 2.9227908, percentage: 0.14770228, timestamp: '2024-11-10T00:00:00-03:00' },
                                    { tons: 4.407587, percentage: 0.22273599, timestamp: '2024-11-11T00:00:00-03:00' },
                                    { tons: 3.447332, percentage: 0.17420979, timestamp: '2024-11-12T00:00:00-03:00' },
                                ],
                            },
                        },
                        ration: {
                            id: '3bb562d4-0bfa-4c17-ad6d-9b1122cadc62',
                            name: 'updated json',
                            order: 0,
                            active: true,
                            density: 0.71,
                        },
                        proportions: {
                            body_height: 0.43769214,
                            base_height: 0.3634267,
                            ceil_height: 0.19888121,
                            diameter: 0.4740348,
                            plates: 2,
                        },
                        cloud_points: [
                            { pos_x: 0.017, pos_y: 0.5043659 },
                            { pos_x: 0.183, pos_y: 0.6496654 },
                            { pos_x: 0.35, pos_y: 0.632213 },
                        ],
                        silo_type: 'CLOUD',
                    },
                    {
                        id: '5443e980-f72f-49b1-8b5f-df269c25a065',
                        name: 'Domo 3 Pontos [Somai G3D]',
                        device_status: 'ACTIVE',
                        temperature: 22,
                        last_seen: '2024-11-12T21:30:36-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.7120142, tons: 36.379723, linear: 0.6775004 },
                            remaining: { percentage: 0.2879858, tons: 14.714378, days: 3, linear: 0.32249957 },
                            resupply: { tons: 16.483044, percentage: 0.32260168, timestamp: '2024-11-12T13:30:22-03:00' },
                            consumption: {
                                average: { percentage: 0.27791935, tons: 14.200041 },
                                sum: { percentage: 2.2233548, tons: 113.60033 },
                                historic: [
                                    { tons: 11.065451, percentage: 0.21657003, timestamp: '2024-11-05T00:00:00-03:00' },
                                    { tons: 19.647852, percentage: 0.38454247, timestamp: '2024-11-06T00:00:00-03:00' },
                                    { tons: 9.594077, percentage: 0.1877727, timestamp: '2024-11-07T00:00:00-03:00' },
                                    { tons: 16.444351, percentage: 0.32184443, timestamp: '2024-11-08T00:00:00-03:00' },
                                    { tons: 14.748698, percentage: 0.28865755, timestamp: '2024-11-09T00:00:00-03:00' },
                                    { tons: 10.678374, percentage: 0.20899428, timestamp: '2024-11-10T00:00:00-03:00' },
                                    { tons: 16.369635, percentage: 0.3203821, timestamp: '2024-11-11T00:00:00-03:00' },
                                    { tons: 15.051884, percentage: 0.29459143, timestamp: '2024-11-12T00:00:00-03:00' },
                                ],
                            },
                        },
                        ration: {
                            id: '6391660b-7956-41c5-9af0-bc14d24ab469',
                            name: 'Postura 1',
                            order: 1,
                            active: true,
                            density: 2.3,
                        },
                        proportions: {
                            body_height: 0.44816837,
                            base_height: 0.35044745,
                            ceil_height: 0.20138417,
                            diameter: 0.46333197,
                            plates: 3,
                        },
                        cloud_points: [
                            { pos_x: 0.075, pos_y: 0.628144 },
                            { pos_x: 0.22, pos_y: 0.6243893 },
                            { pos_x: 0.322, pos_y: 0.7799678 },
                        ],
                        silo_type: 'CLOUD',
                    },
                    {
                        id: '63143d4c-a6ba-4ea7-af4f-acd21c1e9e8d',
                        name: 'Faria AV10 Silo 1',
                        device_status: 'ACTIVE',
                        temperature: 20,
                        last_seen: '2024-11-12T21:50:42-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.616188, tons: 12.870407, linear: 0.6353132 },
                            remaining: { percentage: 0.383812, tons: 8.016737, days: 1, linear: 0.3646868 },
                            resupply: { tons: 14.886621, percentage: 0.712717, timestamp: '2024-11-12T18:05:32-03:00' },
                            consumption: {
                                average: { percentage: 0.48282835, tons: 10.084906 },
                                sum: { percentage: 3.8626268, tons: 80.679245 },
                                historic: [
                                    { tons: 17.833149, percentage: 0.85378593, timestamp: '2024-11-05T00:00:00-03:00' },
                                    { tons: 17.518948, percentage: 0.8387431, timestamp: '2024-11-06T00:00:00-03:00' },
                                    { tons: 14.213712, percentage: 0.6805005, timestamp: '2024-11-07T00:00:00-03:00' },
                                    { tons: 10.57991, percentage: 0.5065274, timestamp: '2024-11-08T00:00:00-03:00' },
                                    { tons: 7.4601207, percentage: 0.35716328, timestamp: '2024-11-09T00:00:00-03:00' },
                                    { tons: 0.28709897, percentage: 0.013745249, timestamp: '2024-11-10T00:00:00-03:00' },
                                    { tons: 0.08111067, percentage: 0.0038832822, timestamp: '2024-11-11T00:00:00-03:00' },
                                    { tons: 12.705198, percentage: 0.6082784, timestamp: '2024-11-12T00:00:00-03:00' },
                                ],
                            },
                        },
                        ration: {
                            id: '00000000-0000-0000-0000-000000000000',
                            name: 'Padrão',
                            order: 0,
                            active: true,
                            density: 0.8,
                        },
                        proportions: {
                            body_height: 0.41285098,
                            base_height: 0.39062053,
                            ceil_height: 0.1965285,
                            diameter: 0.46842706,
                            plates: 1,
                        },
                        silo_type: 'STANDALONE',
                    },
                    {
                        id: 'b637800b-9881-4e37-9be9-c44311fd2f72',
                        name: 'Faria AV11 Silo 1 T',
                        device_status: 'ACTIVE',
                        temperature: 8,
                        last_seen: '2024-08-11T20:10:30-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'CRITICAL',
                            current: { percentage: 0.017618181, tons: 0.3679935, linear: 0.17006192 },
                            remaining: { percentage: 0.9823818, tons: 20.51915, days: 1, linear: 0.82993805 },
                            resupply: { tons: 12.535556, percentage: 0.60015655, timestamp: '2024-08-09T13:32:09-03:00' },
                            consumption: { average: { percentage: 0, tons: 0 }, sum: { percentage: 0, tons: 0 }, historic: null },
                        },
                        ration: {
                            id: '00000000-0000-0000-0000-000000000000',
                            name: 'Padrão',
                            order: 0,
                            active: true,
                            density: 0.8,
                        },
                        proportions: {
                            body_height: 0.41285098,
                            base_height: 0.39062053,
                            ceil_height: 0.1965285,
                            diameter: 0.46842706,
                            plates: 1,
                        },
                        silo_type: 'STANDALONE',
                    },
                    {
                        id: '9cb3d184-8209-4a59-a39e-1d8080d16ea9',
                        name: 'Faria AV11 Silo 2',
                        device_status: 'ACTIVE',
                        temperature: 10,
                        last_seen: '2024-08-10T18:31:14-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.46475196, tons: 9.707341, linear: 0.5432157 },
                            remaining: { percentage: 0.53524804, tons: 11.179803, days: 1, linear: 0.4567843 },
                            resupply: { tons: 10.476017, percentage: 0.5015533, timestamp: '2024-08-08T13:14:28-03:00' },
                            consumption: { average: { percentage: 0, tons: 0 }, sum: { percentage: 0, tons: 0 }, historic: null },
                        },
                        ration: {
                            id: '00000000-0000-0000-0000-000000000000',
                            name: 'Padrão',
                            order: 0,
                            active: true,
                            density: 0.8,
                        },
                        proportions: {
                            body_height: 0.41285098,
                            base_height: 0.39062053,
                            ceil_height: 0.1965285,
                            diameter: 0.46842706,
                            plates: 1,
                        },
                        silo_type: 'STANDALONE',
                    },
                    {
                        id: '40da002e-fa45-4bad-9c88-a79d57d2322a',
                        name: 'Faria AV11 Silo 2',
                        device_status: 'ACTIVE',
                        temperature: 7,
                        last_seen: '2024-07-25T00:38:27-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.6710183, tons: 15.592417, linear: 0.66865885 },
                            remaining: { percentage: 0.3289817, tons: 7.6445293, days: 1, linear: 0.33134115 },
                            resupply: { tons: 10.685113, percentage: 0.4598329, timestamp: '2024-07-24T12:38:02-03:00' },
                            consumption: { average: { percentage: 0, tons: 0 }, sum: { percentage: 0, tons: 0 }, historic: null },
                        },
                        ration: {
                            id: 'e564b21b-cd89-45ed-aeed-fe3c884123ae',
                            name: 'Postura 3',
                            order: 3,
                            active: true,
                            density: 0.89,
                        },
                        proportions: {
                            body_height: 0.41285098,
                            base_height: 0.39062053,
                            ceil_height: 0.1965285,
                            diameter: 0.46842706,
                            plates: 1,
                        },
                        silo_type: 'STANDALONE',
                    },
                    {
                        id: '270457f2-6e8d-4037-8904-f581ad30677a',
                        name: 'Lobo Fábrica 4',
                        device_status: 'ACTIVE',
                        temperature: 20,
                        last_seen: '2024-11-12T21:46:14-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.5637066, tons: 59.065975, linear: 0.67988676 },
                            remaining: { percentage: 0.43629342, tons: 45.71545, days: 2, linear: 0.32011324 },
                            resupply: { tons: 101.379425, percentage: 0.9675324, timestamp: '2024-11-12T10:45:53-03:00' },
                            consumption: {
                                average: { percentage: 0.2964356, tons: 31.060947 },
                                sum: { percentage: 2.3714848, tons: 248.48758 },
                                historic: [
                                    { tons: 17.032398, percentage: 0.1625517, timestamp: '2024-11-05T00:00:00-03:00' },
                                    { tons: 2.2539856, percentage: 0.021511309, timestamp: '2024-11-06T00:00:00-03:00' },
                                    { tons: 75.11318, percentage: 0.7168559, timestamp: '2024-11-07T00:00:00-03:00' },
                                    { tons: 14.044067, percentage: 0.13403204, timestamp: '2024-11-08T00:00:00-03:00' },
                                    { tons: 14.737595, percentage: 0.14065084, timestamp: '2024-11-09T00:00:00-03:00' },
                                    { tons: 0, percentage: 0, timestamp: '2024-11-10T00:00:00-03:00' },
                                    { tons: 36.757298, percentage: 0.35079977, timestamp: '2024-11-11T00:00:00-03:00' },
                                    { tons: 88.54905, percentage: 0.8450835, timestamp: '2024-11-12T00:00:00-03:00' },
                                ],
                            },
                        },
                        ration: {
                            id: '00000000-0000-0000-0000-000000000000',
                            name: 'Padrão',
                            order: 0,
                            active: true,
                            density: 0.8,
                        },
                        proportions: {
                            body_height: 0.28620335,
                            base_height: 0.5767431,
                            ceil_height: 0.13705353,
                            diameter: 0.4561908,
                            plates: 1,
                        },
                        silo_type: 'STANDALONE',
                    },
                    {
                        id: 'a8a1a0fb-2337-4e36-80e1-cd1b97886c7c',
                        name: 'Lobo Pinteiro 1',
                        device_status: 'ACTIVE',
                        temperature: 20,
                        last_seen: '2024-11-12T21:21:38-03:00',
                        prefer_tons: false,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'CRITICAL',
                            current: { percentage: 0.19744319, tons: 1.0045133, linear: 0.36169598 },
                            remaining: { percentage: 0.8025568, tons: 4.0830936, days: 1, linear: 0.638304 },
                            resupply: { tons: 4.243587, percentage: 0.83410275, timestamp: '2024-11-09T09:49:04-03:00' },
                            consumption: {
                                average: { percentage: 0.17578018, tons: 0.8943004 },
                                sum: { percentage: 1.4062414, tons: 7.154403 },
                                historic: [
                                    { tons: 1.3224888, percentage: 0.2599432, timestamp: '2024-11-05T00:00:00-03:00' },
                                    { tons: 1.0840071, percentage: 0.21306817, timestamp: '2024-11-06T00:00:00-03:00' },
                                    { tons: 0.7307812, percentage: 0.14363948, timestamp: '2024-11-07T00:00:00-03:00' },
                                    { tons: 0.30588242, percentage: 0.060123045, timestamp: '2024-11-08T00:00:00-03:00' },
                                    { tons: 0.61098313, percentage: 0.120092444, timestamp: '2024-11-09T00:00:00-03:00' },
                                    { tons: 1.8428122, percentage: 0.3622159, timestamp: '2024-11-10T00:00:00-03:00' },
                                    { tons: 0.6720845, percentage: 0.1321023, timestamp: '2024-11-11T00:00:00-03:00' },
                                    { tons: 0.58536386, percentage: 0.11505682, timestamp: '2024-11-12T00:00:00-03:00' },
                                ],
                            },
                        },
                        ration: {
                            id: '00000000-0000-0000-0000-000000000000',
                            name: 'Padrão',
                            order: 0,
                            active: true,
                            density: 0.8,
                        },
                        proportions: {
                            body_height: 0.49875307,
                            base_height: 0.34289274,
                            ceil_height: 0.15835421,
                            diameter: 0.5270913,
                            plates: 1,
                        },
                        silo_type: 'STANDALONE',
                    },
                    {
                        id: '33f4c8b7-40b2-46f2-9a57-d8d4c5e1e151',
                        name: 'Vale Verde G3 [3P]',
                        device_status: 'ACTIVE',
                        temperature: 18,
                        last_seen: '2024-11-12T21:38:44-03:00',
                        prefer_tons: true,
                        position: { latitude: 0, longitude: 0 },
                        level: {
                            status: 'NORMAL',
                            current: { percentage: 0.74484104, tons: 11.797829, linear: 0.7718176 },
                            remaining: { percentage: 0.25515896, tons: 4.041562, days: 3, linear: 0.22818238 },
                            resupply: { tons: 5.905103, percentage: 0.3728113, timestamp: '2024-11-12T17:38:39-03:00' },
                            consumption: {
                                average: { percentage: 0.22881198, tons: 3.6242423 },
                                sum: { percentage: 1.8304958, tons: 28.993938 },
                                historic: [
                                    { tons: 4.4166055, percentage: 0.27883685, timestamp: '2024-11-05T00:00:00-03:00' },
                                    { tons: 2.427374, percentage: 0.15324919, timestamp: '2024-11-06T00:00:00-03:00' },
                                    { tons: 1.8398907, percentage: 0.11615918, timestamp: '2024-11-07T00:00:00-03:00' },
                                    { tons: 4.5313497, percentage: 0.28608108, timestamp: '2024-11-08T00:00:00-03:00' },
                                    { tons: 3.2103405, percentage: 0.20268081, timestamp: '2024-11-09T00:00:00-03:00' },
                                    { tons: 3.424854, percentage: 0.21622384, timestamp: '2024-11-10T00:00:00-03:00' },
                                    { tons: 4.470487, percentage: 0.28223857, timestamp: '2024-11-11T00:00:00-03:00' },
                                    { tons: 4.673037, percentage: 0.2950263, timestamp: '2024-11-12T00:00:00-03:00' },
                                ],
                            },
                        },
                        ration: {
                            id: '3bb562d4-0bfa-4c17-ad6d-9b1122cadc62',
                            name: 'updated json',
                            order: 0,
                            active: true,
                            density: 0.71,
                        },
                        proportions: {
                            body_height: 0.42076024,
                            base_height: 0.4568703,
                            ceil_height: 0.12236946,
                            diameter: 0.42390025,
                            plates: 3,
                        },
                        cloud_points: [
                            { pos_x: 0.078, pos_y: 0.7506557 },
                            { pos_x: 0.248, pos_y: 0.78764194 },
                            { pos_x: 0.359, pos_y: 0.77715516 },
                        ],
                        silo_type: 'CLOUD',
                    },
                ],
            } as any;
        },
        refetchOnMount: props?.refetchOnMount,
        cacheTime: Infinity,
    });
}
