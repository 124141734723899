import { EggVisionDevice } from '@/chicken-house/api/types/EggVisionResponse';
import { LineChart, PageList, Stack, useMedia, useResponsiveChart } from '@alltis/ui';
import React from 'react';

type Props = {
    eggVision: EggVisionDevice;
};

const EggProductionSummaryCard = ({ eggVision: { egg_vision } }: Props) => {
    const media = useMedia();
    const chartWidth = useResponsiveChart();

    const TotalCard = (
        <PageList.CardInfo
            title='Total no Período'
            quantity={{
                value: egg_vision.production.period_sum,
                suffix: 'ovos',
            }}
            caption={`${egg_vision.production.period_sum_boxes} caixas`}
            size={media.lg ? 'md' : 'sm'}
            titleSpacing={media.lg ? 24 : undefined}
        />
    );

    const AverageCard = (
        <PageList.CardInfo
            title='Média últimos 7 dias'
            quantity={{
                value: egg_vision.production.period_avg,
                suffix: 'ovos',
            }}
            caption={`${egg_vision.production.period_avg_boxes} caixas`}
            size={media.lg ? 'md' : 'sm'}
            color='#8DC044'
            titleSpacing={media.lg ? 24 : undefined}
        />
    );

    const TodayProductionCard = (
        <PageList.CardInfo
            title='Produção de Hoje'
            quantity={{
                value: egg_vision.production.today,
                suffix: 'ovos',
            }}
            caption={`${egg_vision.production.today_boxes} caixas`}
            size={media.lg ? 'md' : 'sm'}
            titleSpacing={media.lg ? 24 : undefined}
        />
    );

    const Chart = (
        <Stack flex={1} transform='translateY(-18px)' ml={media.md ? 0 : 20}>
            <LineChart
                data={{
                    historic: egg_vision.production.historic.map((d) => ({
                        timestamp: d.timestamp,
                        value: d.count,
                    })),
                }}
                height={media.md ? 140 : 190}
                width={chartWidth}
            />
        </Stack>
    );

    const getCardContent = () => {
        if (media.md) {
            return (
                <Stack flexDirection='row' pb={14} px={24} gap={24} alignItems={media.lg ? 'center' : 'flex-start'} flex={1}>
                    {media.lg && TotalCard}
                    {AverageCard}
                    {Chart}
                    {TodayProductionCard}
                </Stack>
            );
        }

        return (
            <Stack>
                <Stack flexDirection='row' pb={14} px={24} jc='space-between' flex={1}>
                    {AverageCard}
                    {TodayProductionCard}
                </Stack>

                {Chart}
            </Stack>
        );
    };

    return (
        <PageList.Card minHeight={0}>
            <PageList.CardHeader title={egg_vision.name} icon='eggs' backgroundColor='#8CA9B3' foregroundColor='#FFFFFF' />

            {getCardContent()}
        </PageList.Card>
    );
};

export default EggProductionSummaryCard;
