import ReportMetricResultHeader from '@/metrics/components/ReportMetricResultHeader';
import { useMetricResults } from '@/metrics/hooks/useMetricResults';
import { metricFormToPayload } from '@/metrics/mappers/metricFormToPayload';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';
import ViewState from '@/shared/components/ui/ViewState';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import { Stack, useSheetCheck } from '@alltis/ui';
import { BottomSheetFlatList } from '@gorhom/bottom-sheet';
import { RouteProp, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useMedia } from 'tamagui';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';
import { ReportMetricData } from '../../components/ReportMetricData';
import { ReportResultContainer } from '../../components/ReportResultContainer';
import { List } from '@/shared/components/ui/List';

export const ReportMetricResult = () => {
    useStatusBar('light-content');

    const { params } = useRoute<RouteProp<UserStackParams, 'ReportMetricResult'>>();

    const parsed = useMemo(() => {
        const startDate = dayjs(params.startDate, 'YYYY-MM-DD').tz('America/Sao_Paulo').toDate();
        const endDate = dayjs(params.endDate, 'YYYY-MM-DD').tz('America/Sao_Paulo').toDate();

        return {
            startDate,
            endDate,
            aviaryIds: params.aviaryIds,
            metrics: params.metrics,
        };
    }, [params]);

    const { data: aviaryMetric, isError, isLoading } = useMetricResults(metricFormToPayload(params));

    const isSheet = useSheetCheck();

    const ListComponent = (isSheet ? BottomSheetFlatList : List) as typeof List;

    const media = useMedia();

    return (
        <Stack flex={1}>
            <ReportMetricResultHeader params={params} />

            <ListComponent
                data={aviaryMetric || []}
                estimatedItemSize={300}
                renderItem={({ item }) => {
                    return (
                        <ReportResultContainer title={item.aviary_name} period={parsed}>
                            {item.metrics?.map((metric) => (
                                <ReportMetricData metric={metric} key={metric.name} />
                            ))}
                        </ReportResultContainer>
                    );
                }}
                ItemSeparatorComponent={() => <Stack height={30} />}
                ListEmptyComponent={<ViewState empty={aviaryMetric?.length === 0} error={isError} loading={isLoading} />}
                contentContainerStyle={{
                    paddingVertical: media.md ? 56 : 16,
                    paddingHorizontal: media.md ? 32 : 12,
                }}
                ListFooterComponent={<SafeBottom />}
            />
        </Stack>
    );
};
