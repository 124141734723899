import { PlaceSetupForm } from '@legacy/chicken-house/entities/PlaceSetupForm';
import TextField from '@legacy/shared/components/ui/TextField';
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CoreNameField = () => {
    const { control } = useFormContext<PlaceSetupForm>();

    return (
        <Controller
            control={control}
            name='coreName'
            render={({ field: { onChange, ...field } }) => (
                <TextField
                    placeholder='Nome do núcleo'
                    icon={<FontAwesome5 name='home' solid />}
                    onChangeText={onChange}
                    autoCapitalize='none'
                    helperText='Eg. Núcleo 1'
                    {...field}
                />
            )}
        />
    );
};

export default CoreNameField;
