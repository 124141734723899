import React from 'react';
import { Stack } from 'tamagui';
import { PageListCardProps } from '../../PageListProps';

const PageListCard = (props: PageListCardProps) => {
    return (
        <Stack
            cursor={props.onPress ? 'pointer' : 'default'}
            hoverStyle={props.onPress ? { bg: '#fafafa' } : {}}
            pressStyle={props.onPress ? { bg: '#fafafa' } : {}}
            bg='#ffffff'
            br={8}
            w='100%'
            mih={216}
            overflow='hidden'
            {...props}
        />
    );
};

export default PageListCard;
