import { useStatusBar } from '@/shared/hooks/useStatusBar';
import React from 'react';
import { WebView } from 'react-native-webview';

const HTML = `
<!DOCTYPE html>
<!-- saved from url=(0039)https://bi.alltis.com.br/privacy-policy -->
<html lang="pt-BR">

    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

    <style>
        * {
        font-family: sans-serif;
        }

        #root {
        padding: 0 16px;
        }
    </style>

    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>ALLTIS | IRIS</title>
    </head>

    <body>
    <div id="root">
        <div class="MuiBox-root css-1qapnz0">
        <h4 class="MuiTypography-root MuiTypography-h4 css-sryyju">Política de Privacidade</h4>
        <p class="MuiTypography-root MuiTypography-body1 css-rdjv66">Data de vigência: 21 de novembro de 2023</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">A ALLTIS TECNOLOGIA TERMICA LTDA ("nós", "nós" ou
            "nosso") opera o site bi.alltis.com.br e o aplicativo móvel ALLTIS BI (doravante denominado "Serviço").</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Esta página informa sobre nossas políticas relativas
            à coleta, uso e divulgação de dados pessoais quando você usa nosso Serviço e as opções que você associou a esses
            dados.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Usamos seus dados para fornecer e melhorar o
            serviço. Ao usar o Serviço, você concorda com a coleta e uso de informações de acordo com esta política. A menos
            que definido de outra forma nesta Política de Privacidade, os termos usados nesta Política de Privacidade têm os
            mesmos significados que os nossos Termos e Condições.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Definições</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">Serviço</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Serviço significa o site bi.alltis.com.br e o
            aplicativo móvel ALLTIS BI operado por ALLTIS TECNOLOGIA TERMICA LTDA</p>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">Dados pessoais</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Dados Pessoais significam dados sobre um indivíduo
            vivo que podem ser identificados a partir desses dados (ou dessas e outras informações que possuímos ou que
            podemos obter em nosso poder).</p>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">Dados de uso</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Dados de Uso são dados coletados automaticamente,
            gerados pelo uso do Serviço ou da própria infraestrutura do Serviço (por exemplo, a duração de uma visita à
            página).</p>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">“Cookies”</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Cookies são pequenos arquivos armazenados no seu
            dispositivo (computador ou dispositivo móvel).</p>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">Coleta e Uso de Informações</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Coletamos vários tipos diferentes de informações
            para diversos fins, para fornecer e melhorar nosso Serviço para você.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Tipos de dados coletados</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">Dados pessoais</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Ao usar o nosso Serviço, podemos solicitar que você
            nos forneça algumas informações pessoais identificáveis que podem ser usadas para contatá-lo ou identificá-lo
            ("Dados Pessoais"). Informações pessoalmente identificáveis podem incluir, mas não estão limitadas a:</p>
        <ul class="MuiList-root MuiList-padding css-1ontqvh">
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Endereço de e-mail</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Primeiro nome e sobrenome</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Número de telefone</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Endereço, Estado, Província,
            CEP / Código Postal, Cidade</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Cookies e Dados de Uso</li>
        </ul>
        <p class="MuiTypography-root MuiTypography-body1 css-15rjg99">Podemos usar seus Dados Pessoais para contatá-lo com
            boletins informativos, materiais de marketing ou promocionais e outras informações que possam ser de seu
            interesse. Você pode optar por não receber nenhuma ou todas essas comunicações, seguindo o link de cancelamento
            de inscrição ou as instruções fornecidas em qualquer email que enviarmos.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-2af81i">Dados de uso</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Também podemos coletar informações que seu navegador
            envia sempre que você visita nosso Serviço ou quando você acessa o Serviço por ou através de um dispositivo
            móvel ("Dados de Uso").</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Estes Dados de Uso podem incluir informações como o
            endereço IP do seu computador (por exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do
            nosso Serviço que você visita, a hora e data de sua visita, o tempo gasto nessas páginas identificadores de
            dispositivos e outros dados de diagnóstico.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Quando você acessa o Serviço com um dispositivo
            móvel, esses Dados de uso podem incluir informações como o tipo de dispositivo móvel usado, o ID exclusivo do
            dispositivo móvel, o endereço IP do dispositivo móvel, o sistema operacional móvel e o tipo de Internet móvel.
            navegador que você usa, identificadores de dispositivo exclusivos e outros dados de diagnóstico.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Rastreamento e dados de cookies</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Usamos cookies e tecnologias de rastreamento
            semelhantes para rastrear a atividade em nosso Serviço e mantemos certas informações.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Cookies são arquivos com uma pequena quantidade de
            dados que podem incluir um identificador exclusivo anônimo. Os cookies são enviados para o seu navegador a
            partir de um site e armazenados no seu dispositivo. Outras tecnologias de rastreamento também são usadas, como
            beacons, tags e scripts para coletar e rastrear informações e para melhorar e analisar nosso Serviço.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Você pode instruir seu navegador para recusar todos
            os cookies ou para indicar quando um cookie está sendo enviado. No entanto, se você não aceitar cookies, talvez
            não consiga usar algumas partes de nosso Serviço.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Exemplos de cookies que usamos:</p>
        <ul class="MuiList-root MuiList-padding css-1ontqvh">
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Cookies de sessão. Usamos
            cookies de sessão para operar nosso serviço.</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Cookies preferenciais. Usamos
            Cookies Preferenciais para lembrar suas preferências e várias configurações.</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Cookies de segurança. Nós
            usamos cookies de segurança para fins de segurança.</li>
        </ul>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Uso de dados</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">A ALLTIS TECNOLOGIA TERMICA LTDA utiliza os dados
            coletados para diversos fins:</p>
        <ul class="MuiList-root MuiList-padding css-1ontqvh">
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para fornecer e manter nosso
            serviço</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para notificá-lo sobre
            alterações no nosso serviço</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para permitir que você
            participe de recursos interativos do nosso Serviço ao optar por fazê-lo</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para fornecer suporte ao
            cliente</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para reunir análises ou
            informações valiosas para que possamos melhorar nosso serviço</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para monitorar o uso do nosso
            serviço</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para detectar, prevenir e
            resolver problemas técnicos</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para lhe fornecer notícias,
            ofertas especiais e informações gerais sobre outros bens, serviços e eventos que oferecemos que sejam
            semelhantes àqueles sobre os quais você já comprou ou perguntou, a menos que tenha optado por não receber tais
            informações</li>
        </ul>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Transferência de dados</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Suas informações, incluindo Dados Pessoais, podem
            ser transferidas para - e mantidas em - computadores localizados fora do seu estado, província, país ou outra
            jurisdição governamental, onde as leis de proteção de dados podem diferir das da sua jurisdição.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Se você estiver localizado fora do Brasil e optar
            por nos fornecer informações, observe que transferimos os dados, incluindo os Dados Pessoais, para o Brasil e os
            processamos lá</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">O seu consentimento para com esta Política de
            Privacidade, seguido pelo envio de tais informações, representa sua concordância com essa transferência.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">A ALLTIS TECNOLOGIA TERMICA LTDA tomará todas as
            medidas razoavelmente necessárias para garantir que seus dados sejam tratados com segurança e de acordo com esta
            Política de Privacidade e nenhuma transferência de seus Dados Pessoais ocorrerá a uma organização ou país a
            menos que haja controles adequados no local, incluindo a segurança de seus dados e outras informações pessoais.
        </p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Divulgação de Dados</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-13uxfu1">Requerimentos legais</p>
        <p class="MuiTypography-root MuiTypography-body1 css-13uxfu1">A ALLTIS TECNOLOGIA TERMICA LTDA pode divulgar os
            seus Dados Pessoais na crença de boa fé de que tal ação é necessária para:</p>
        <ul class="MuiList-root MuiList-padding css-1ontqvh">
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para cumprir uma obrigação
            legal</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Proteger e defender os direitos
            ou propriedade de ALLTIS TECNOLOGIA TERMICA LTDA</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para evitar ou investigar
            possíveis irregularidades relacionadas ao Serviço</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para proteger a segurança
            pessoal dos usuários do Serviço ou do público</li>
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Para proteger contra
            responsabilidade legal</li>
        </ul>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Segurança de dados</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">A segurança dos seus dados é importante para nós,
            mas lembre-se de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100%
            seguro. Embora nos esforcemos para usar meios comercialmente aceitáveis para proteger seus dados pessoais, não
            podemos garantir sua segurança absoluta.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Nossa Política de "Não Rastrear" Sinais sob a Lei de
            Proteção Online da Califórnia (CalOPPA)</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Nós não suportamos Do Not Track ("DNT"). Não
            rastrear é uma preferência que você pode definir em seu navegador para informar sites que você não deseja
            rastrear.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Você pode ativar ou desativar o recurso Não rastrear
            visitando a página Preferências ou Configurações do seu navegador da web.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Provedores de serviço</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Podemos empregar empresas e indivíduos terceirizados
            para facilitar o nosso Serviço ("Prestadores de Serviços"), fornecer o Serviço em nosso nome, executar serviços
            relacionados ao Serviço ou nos auxiliar na análise de como nosso Serviço é utilizado.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Esses terceiros têm acesso aos seus Dados Pessoais
            apenas para realizar essas tarefas em nosso nome e são obrigados a não divulgá-los ou usá-los para qualquer
            outra finalidade.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Links para outros sites</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Nosso Serviço pode conter links para outros sites
            que não são operados por nós. Se você clicar em um link de terceiros, você será direcionado para o site de
            terceiros. Recomendamos vivamente que reveja a política de privacidade de todos os sites que visita.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Não temos controle e não assumimos nenhuma
            responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de
            terceiros.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Privacidade das crianças</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Nosso Serviço não atende a menores de 18 anos
            ("Filhos").</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Não coletamos intencionalmente informações de
            identificação pessoal de menores de 18 anos. Se você é pai / mãe ou responsável legal e está ciente de que seu
            filho nos forneceu dados pessoais, entre em contato conosco. Se ficarmos cientes de que coletamos Dados Pessoais
            de crianças sem a verificação do consentimento dos pais, tomamos medidas para remover essas informações de
            nossos servidores.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Alterações a esta política de privacidade</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Podemos atualizar nossa Política de Privacidade de
            tempos em tempos. Vamos notificá-lo de quaisquer alterações publicando a nova Política de Privacidade nesta
            página.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Avisaremos você por e-mail e / ou um aviso
            proeminente em nosso Serviço antes que a alteração entre em vigor e atualize a "data efetiva" na parte superior
            desta Política de Privacidade.</p>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Recomenda-se que você revise esta Política de
            Privacidade periodicamente para quaisquer alterações. Alterações a esta Política de Privacidade são efetivas
            quando publicadas nesta página.</p>
        <h5 class="MuiTypography-root MuiTypography-h5 css-3085g0">Entre em contato conosco</h5>
        <p class="MuiTypography-root MuiTypography-body1 css-1f4tkfc">Se você tiver alguma dúvida sobre esta Política de
            Privacidade, entre em contato conosco:</p>
        <ul class="MuiList-root MuiList-padding css-1ontqvh">
            <li class="MuiListItem-root MuiListItem-gutters MuiListItem-padding css-1yo8bqd">Por email:
            suporte@alltis.com.br</li>
        </ul>
        </div>
    </div>
    </body>
</html>
`;

const PrivacyPolicy = () => {
    useStatusBar('dark-content');

    return <WebView source={{ html: HTML }} />;
};

export default PrivacyPolicy;
