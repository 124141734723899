import { useUser } from '@/authentication/store/UserStore';
import React from 'react';
import { Avatar } from 'tamagui';
import DefaultAvatar from './DefaultAvatar';

type Props = {
    size?: '$1' | '$2' | '$3' | '$4' | '$5' | '$6' | '$7' | '$8' | '$9' | '$10';
};

export const UserAvatar = (props: Props) => {
    const profilePhoto = useUser((store) => store?.user?.profile_photo_uri);

    if (!profilePhoto) {
        return <DefaultAvatar />;
    }

    return (
        <Avatar circular size={props.size || '$2.5'} borderColor='white' borderWidth={1} backgroundColor='transparent'>
            <Avatar.Image src={profilePhoto} width='100%' height='100%' />

            <Avatar.Fallback backgroundColor='#8CA9B3' />
        </Avatar>
    );
};
