import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import { useEggManager } from '@/chicken-house/store/useEggManagerStore';
import { useNavigation } from '@react-navigation/native';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';

export function useEggReportNavigation() {
    const navigation = useNavigation();
    const isLargeScreen = useLargeLayout();
    const eggManager = useEggManager();

    function openReportPage(chickenHouse?: Pick<ChickenHouseInfo, 'id' | 'name'>) {
        if (isLargeScreen) {
            if (chickenHouse?.id) {
                eggManager.onSelectChickenHouse(chickenHouse.id);
            }

            eggManager.onTabChanged('reports');

            return;
        }

        return navigation.navigate('EggsReport', { chickenHouse });
    }

    return { openReportPage };
}
