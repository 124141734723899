import { Ration } from '@legacy/warehouse/api/types/FeedResponse';
import { FeedFormData, feedFormSchema } from '@legacy/warehouse/utils/validators/feedSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export function useRationForm(ration?: Ration) {
    const { control, handleSubmit, formState, reset } = useForm<FeedFormData>({
        defaultValues: {
            name: '',
            density: 0,
        },
        resolver: zodResolver(feedFormSchema),
    });

    useEffect(() => {
        if (!ration) return;

        reset(ration);
    }, [ration]);

    return { control, handleSubmit, formState };
}
