import React from 'react';
import Svg from 'react-native-svg';
import { Path, Text } from 'victory-native';

type Props = {
    value: number;
};

const AlertBadge = ({ value }: Props) => {
    return (
        <Svg width='27' height='25' viewBox='0 0 27 25' fill='none'>
            <Path
                d='M16.8738 2.93499L16.8738 2.93499L16.8709 2.93018L16.868 2.92549C16.868 2.92547 16.868 2.92545 16.868 2.92543C16.5114 2.33767 16.0094 1.85172 15.4104 1.51446C14.8114 1.17718 14.1355 1 13.448 1C12.7605 1 12.0847 1.17718 11.4856 1.51446C10.8866 1.85172 10.3846 2.33768 10.028 2.92548L10.0251 2.93024L1.55228 17.075L1.55226 17.075L1.54819 17.0819L1.54006 17.0957L1.54004 17.0957L1.53604 17.1026C1.18677 17.7075 1.00197 18.3932 1.00002 19.0917C0.99806 19.7901 1.17902 20.4769 1.52489 21.0837C1.87076 21.6905 2.36949 22.1961 2.97145 22.5503C3.57334 22.9045 4.25755 23.095 4.95604 23.1027L4.95604 23.1027H4.96702L21.918 23.1028V23.1029L21.9291 23.1027L21.94 23.1026C21.94 23.1026 21.9401 23.1026 21.9401 23.1026C22.6385 23.0949 23.3227 22.9045 23.9246 22.5503L23.4174 21.6885L23.9246 22.5503C24.5266 22.1961 25.0253 21.6905 25.3711 21.0837C25.717 20.4769 25.898 19.7901 25.896 19.0917C25.8941 18.3932 25.7093 17.7075 25.36 17.1026L25.36 17.1026L25.356 17.0958L25.3479 17.082L25.3479 17.082L25.3438 17.075L16.8738 2.93499Z'
                fill='#8C1913'
                stroke='#B26561'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />

            <Text textAnchor='middle' x={13} y={18} fontSize={value > 10 ? 10 : 12} fontWeight={600} fill='white'>
                {value}
            </Text>
        </Svg>
    );
};

export default AlertBadge;
