import { EggsReportData } from '@/chicken-house/entities/reports/EggsReportData';
import { EggsReportForm } from '@/chicken-house/entities/reports/EggsReportForm';
import { handleResponseNumber } from '@/shared/utils/response';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { maxBy, minBy } from 'lodash';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';

type EggReportResponse = {
    id: string;
    name: string;
    historic: {
        count: number;
        count_boxes: number;
        is_maximum: boolean;
        is_minimum: boolean;
        timestamp: string;
    }[];
    summary: {
        maximum: {
            timestamp: string;
            count: number;
            count_boxes: number;
        };
        minimum: {
            timestamp: string;
            count: number;
            count_boxes: number;
        };
        average: {
            count: number;
            count_boxes: number;
        };
        sum?: {
            count: number;
            count_boxes: number;
        };
    };
}[];

export function useEggReport(filter: EggsReportForm) {
    return useQuery<EggsReportData[]>(
        ['eggs-report', filter],
        async () => {
            const chickenHouseIds = filter.chickenHouseIds.join(',');

            const response = await alltisApiV2.get<EggReportResponse>(`/egg-vision/historical`, {
                params: {
                    ids: chickenHouseIds,
                    initial_period: dayjs(filter.startDate).startOf('day').toDate(),
                    final_period: dayjs(filter.endDate).endOf('day').toDate(),
                },
            });

            return response.data.map((data) => {
                const report: EggsReportData = {
                    chickenHouseName: data.name,
                    summary: {
                        averageFromPeriod: {
                            value: data.summary.average.count || 0,
                            totalBoxes: data.summary.average.count_boxes || 0,
                        },
                        sumFromPeriod: {
                            value: data.summary?.sum?.count || 0,
                            totalBoxes: data.summary?.sum?.count_boxes || 0,
                        },
                    },
                    produced: data.historic.map((p) => ({
                        date: dayjs(p.timestamp).toDate(),
                        value: handleResponseNumber(p.count),
                        totalBoxes: handleResponseNumber(p.count_boxes),
                    })),
                };

                report.summary.minimumFromPeriod = minBy(report.produced, (c) => c.value);
                report.summary.maximumFromPeriod = maxBy(report.produced, (c) => c.value);

                return report;
            });
        },
        {
            enabled: !!filter.chickenHouseIds.length && !!filter.endDate && !!filter.startDate,
        }
    );
}
