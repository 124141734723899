import Section from '@legacy/shared/components/ui/Section';
import { useSettingField } from '@legacy/user/screens/SettingFieldScreen/hooks/useSettingField';
import { useTesterSettings } from '@legacy/user/store/TesterSettingsStore';
import React from 'react';
import { Text } from 'tamagui';

const ApiUrlSetting = () => {
    const { settings, onUpdateSetting } = useTesterSettings();
    const settingField = useSettingField();

    return (
        <Section.Item
            title='URL da API'
            icon='globe'
            onPress={() => {
                settingField.open({
                    toolbarTitle: 'Opções do testador',
                    title: 'URL da API',
                    description: 'Insira a URL que o app irá enviar as requisições HTTP',
                    defaultValue: settings.apiUrl,
                    icon: 'globe',
                    placeholder: 'eg. https://api.alltis.com.br',
                    onSubmit: (apiUrl) => onUpdateSetting({ apiUrl }),
                });
            }}
            rightContent={
                <Text color='$secondary900' numberOfLines={1} fontSize={14}>
                    {settings.apiUrl}
                </Text>
            }
        />
    );
};

export default ApiUrlSetting;
