import { createDrawerNavigator, DrawerHeaderProps } from '@react-navigation/drawer';
import { useTheme } from 'tamagui';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';
import CustomAppDrawer from './components/CustomAppDrawer';
import MainHeader from './components/MainHeader';
import { useDrawerConstants } from './hooks/useDrawerConstants';
import { useMainRoutes } from './hooks/useMainRoutes';
import { MainRoutesParams } from './types';
import SoonMessage from '@/shared/components/ui/SoonMessage';

const Drawer = createDrawerNavigator<MainRoutesParams>();

const DrawerContentRenderer = () => <CustomAppDrawer />;

const HeaderContentRenderer = (props: DrawerHeaderProps) => <MainHeader header={props} />;

export const MainDrawer = () => {
    const drawerOptions = useDrawerConstants();

    const sections = useMainRoutes();

    const isLargeScreen = useLargeLayout();

    const theme = useTheme();

    return (
        <Drawer.Navigator
            initialRouteName='Places'
            drawerContent={DrawerContentRenderer}
            screenOptions={{
                headerTintColor: '#ffffff',
                headerStyle: {
                    backgroundColor: theme.primary500.get(),
                },
                headerShadowVisible: false,
                drawerType: isLargeScreen || drawerOptions.isCollapsable ? 'permanent' : 'front',
                drawerStyle: {
                    borderRightColor: 'transparent',
                    borderRightWidth: 0,
                    width: drawerOptions.isCollapsable ? drawerOptions.width.collapsed : drawerOptions.width.expanded,
                },
                header: HeaderContentRenderer,
                unmountOnBlur: isLargeScreen,
            }}
            defaultStatus={isLargeScreen ? 'open' : 'closed'}
        >
            {sections.map((section) =>
                section.routes.map(
                    (route) =>
                        route.key && (
                            <Drawer.Screen
                                key={route.key}
                                name={route.key}
                                component={route.disabled ? SoonMessage : route.component}
                                options={{ title: route.title, drawerIcon: route.icon }}
                            />
                        )
                )
            )}
        </Drawer.Navigator>
    );
};
