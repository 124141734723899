import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Farm = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='m15 0 8.336 4.168a.75.75 0 0 1-.671 1.341l-.915-.457V9.59h-4.5v-3h-4.5v1.77l-1.5-.75V5.09h7.5v3h1.5v-3.75l.001-.037-5.25-2.625-5.252 2.625a.77.77 0 0 1 .001.037v3.75h.042l-1.542.77V5.052l-.914.457a.75.75 0 0 1-.671-1.341L15 0Zm6.447 14.944L11 9.72.553 14.944a1 1 0 0 0 .894 1.789L2 16.457v6.381h6v-4h6v4h6v-6.381l.553.276a1 1 0 0 0 .894-1.789ZM4 20.838v-5.381l7-3.5 7 3.5v5.381h-2v-4H6v4H4Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'farm', icon: Farm } as const;
