import FeedMenu from '@/warehouse/components/FeedCard/FeedMenu';
import { useFeedTypes } from '@/warehouse/hooks/feed/useFeedTypes';
import { DataTable, Icon, Stack, TableButton } from '@alltis/ui';
import React from 'react';
import { useMedia } from 'tamagui';

const BirdSpeciesTable = () => {
    const rations = useFeedTypes();
    const media = useMedia();

    return (
        <DataTable
            data={rations.data?.items || []}
            columns={[
                {
                    header: 'Nome',
                    accessorKey: 'name',
                },
                {
                    header: 'Tabelas de Produção',
                    accessorKey: 'density',
                    cell: () => (
                        <Stack flexDirection='row' gap={8}>
                            <Icon name='chicken' size={22} color='#8CA9B3' />
                            <Icon name='eggs' size={22} color='#8CA9B3' />
                            <Icon name='ration' size={22} color='#8CA9B3' />
                            <Icon name='aqua' size={22} color='#8CA9B3' />
                        </Stack>
                    ),
                    size: 145,
                },
                {
                    header: '',
                    accessorKey: 'actions',
                    cell: ({ row }) => <FeedMenu feed={row.original} />,
                    size: media.md ? undefined : 10,
                },
            ]}
            renderFooter={() => <TableButton title='Adicionar Espécie' icon='plus' />}
        />
    );
};

export default BirdSpeciesTable;
