import { useAquaPanel } from '@/aqua/store /useAquaPanelStore';
import { RouteProp, useRoute } from '@react-navigation/native';
import { UserStackParams } from '../../../../../navigation/stacks/User/UserStack';

export function useAquaId() {
    const { params } = useRoute<RouteProp<UserStackParams, 'AquaDetails'>>();
    const panelAquaId = useAquaPanel((state) => state.aquaId);

    return params?.aqua?.id || panelAquaId;
}
