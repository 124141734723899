import { useAquaList } from '@/aqua/hooks/useAquaList';
import BirdCard from '@/birds/components/RationCard';
import { useMainStatusBar } from '@/shared/hooks/useMainStatusBar';
import { PageList } from '@alltis/ui';
import React from 'react';
import { Stack } from 'tamagui';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';

const Birds = () => {
    useMainStatusBar();

    const { data, isError, isLoading, refetch } = useAquaList();

    return (
        <Stack flex={1}>
            <AppBar />

            <PageList
                data={data}
                isError={isError}
                isLoading={isLoading}
                renderItem={({ item }) => <BirdCard bird={item.aqua} site={item.site} />}
                onRefresh={refetch}
            />
        </Stack>
    );
};

export default Birds;
