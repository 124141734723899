import { useUser } from '@/authentication/store/UserStore';
import { Image } from 'expo-image';
import React from 'react';

import connectLogo from '../../../../../../../../assets/images/connect-logo.png';

const width = 100;
const height = 40;

const ProviderLogo = () => {
    const providerLogo = useUser((store) => store?.user?.organization?.provider_logo_uri);

    if (!providerLogo) return null;

    return <Image source={providerLogo ? { uri: providerLogo } : connectLogo} style={{ width, height }} contentFit='contain' />;
};

export default ProviderLogo;
