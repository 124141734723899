import { Stack, Text } from '@alltis/ui';
import React from 'react';

type Props = {
    title: string;
};

const AviaryHeader = ({ title }: Props) => {
    return (
        <Stack py={8} br={8} backgroundColor='#ffffff' alignItems='center' justifyContent='center' mb={32}>
            <Text color='#588290' fontSize={20} fontWeight={500} numberOfLines={1} textAlign='center'>
                {title}
            </Text>
        </Stack>
    );
};

export default AviaryHeader;
