import React from 'react';
import { Line, Rect } from 'react-native-svg';
import { SiloPartProps } from './props';
import { constants } from './constants';

export const SiloBody = (props: SiloPartProps) => {
    const height = props.height;
    const width = props.width;

    return (
        <>
            {/* Body Content */}
            <Rect width={width} height={height} strokeLinejoin='round' />

            {/* Body Strokes */}
            <Line
                y1={0}
                y2={height}
                strokeLinejoin='round'
                strokeWidth={constants.silo.stroke.width}
                stroke={constants.silo.stroke.color}
                x={0}
            />
            <Line
                x1={width}
                x2={width}
                y1={0}
                y2={height}
                strokeLinejoin='round'
                strokeWidth={constants.silo.stroke.width}
                stroke={constants.silo.stroke.color}
                x={0}
            />
        </>
    );
};
