import React, { ReactNode } from 'react';
import { Stack, StackProps } from 'tamagui';
import TextOrNode from '../../utils/TextOrNode';
import { Button } from '@alltis/ui';

type Props = {
    image?: JSX.Element;
    title: string | ReactNode;
    description: string | ReactNode;
    action?: {
        title: string;
        onPress: () => void;
    };
    caption?: string | ReactNode;
    flex?: StackProps['flex'];
};

const StateMessage = (props: Props) => {
    return (
        <Stack ai='center' jc='center' flex={props.flex}>
            {props.image}

            <Stack paddingHorizontal='10%' ai='center'>
                <Stack mb={12} mt={14}>
                    <TextOrNode color='$secondary500' fontWeight='600' fontSize={18} textAlign='center'>
                        {props.title}
                    </TextOrNode>
                </Stack>

                <TextOrNode color='#A0AEC0' textAlign='center' fontSize={14}>
                    {props.description}
                </TextOrNode>

                {props.action && (
                    <Stack mt='$4.5'>
                        <Button onPress={props.action.onPress} accessibilityLabel={props.action.title}>
                            {props.action.title}
                        </Button>
                    </Stack>
                )}

                {props.caption && (
                    <Stack mt={24}>
                        <TextOrNode color='#A0AEC0' textAlign='center' fontSize={14}>
                            {props.caption}
                        </TextOrNode>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default StateMessage;
