import { Silo } from '@legacy/warehouse/entities/Silo';
import useBus, { dispatch } from 'use-bus';

const eventKey = '@warehouses/view-map';

type EventData = Pick<Silo, 'id' | 'position'>;

export function useViewMapEvent() {
    return {
        dispatch(silo: EventData) {
            return dispatch({
                type: eventKey,
                payload: silo,
            });
        },
    };
}

export function useViewMapListener(onEvent: (silo: EventData) => void) {
    return useBus(eventKey, (event) => onEvent(event.payload), []);
}
