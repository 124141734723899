import TabBar from '@legacy/shared/components/ui/TabBar';
import { useMainStatusBar } from '@legacy/shared/hooks/useMainStatusBar';
import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';
import { Stack } from 'tamagui';
import { SiloReport } from '../SiloReport';
import WarehouseMaps from '../WarehouseMaps';
import Warehouses from '../Warehouses';
import { useSiloTabs } from './useSiloTabs';

const WarehouseTabs = () => {
    useMainStatusBar();

    const layout = useWindowDimensions();

    const { index, routes, onIndexChange } = useSiloTabs();

    return (
        <Stack flex={1}>
            <TabView
                renderScene={SceneMap({
                    silos: Warehouses,
                    maps: WarehouseMaps,
                    reports: SiloReport,
                })}
                navigationState={{ index, routes }}
                onIndexChange={onIndexChange}
                initialLayout={{ width: layout.width }}
                renderTabBar={(props) => <TabBar {...props} colorMode='light' />}
                lazy={Platform.OS !== 'web'}
                swipeEnabled={Platform.OS !== 'web'}
            />
        </Stack>
    );
};

export default WarehouseTabs;
