import SiloAmbienceCard from '@legacy/sense/components/SiloAmbienceCard';
import { useSense } from '@legacy/sense/hooks/fetch/useSense';
import ViewState from '@legacy/shared/components/ui/ViewState';
import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { Spacer } from 'tamagui';

const Sense = () => {
    const { data, isLoading, isError } = useSense();

    return (
        <FlashList
            contentContainerStyle={{ padding: 16 }}
            ItemSeparatorComponent={() => <Spacer my={1} />}
            data={data}
            renderItem={({ item }) => <SiloAmbienceCard sense={item.sense} />}
            estimatedItemSize={307}
            ListEmptyComponent={<ViewState search={false} empty={data?.length === 0} loading={isLoading} error={isError} />}
        />
    );
};

export default Sense;
