import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React from 'react';
import { DatePickerProps } from './DatePickerProps';
import { Text } from 'tamagui';
import { Icon } from '../Icon';
import DatePickerSuffix from './components/DatePickerSuffix';

const DatePicker = (props: DatePickerProps) => {
    return (
        <MuiDatePicker
            label={props.label}
            minDate={props.minDate ? dayjs(props.minDate) : undefined}
            maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
            value={props.value ? dayjs(props.value) : undefined}
            onChange={(date) => props.onChange?.(date?.toDate())}
            format='DD MMM YYYY'
            slots={{
                openPickerIcon: () => <Icon name='chevron-down' size={24} color='#376A7B' />,
            }}
            slotProps={{
                textField: {
                    size: 'small',
                    placeholder: props.placeholder || 'Selecionar',
                    InputProps: {
                        startAdornment: <DatePickerSuffix>{props.suffix}</DatePickerSuffix>,
                        sx: { fontSize: 14, color: '#376A7B' },
                    },
                },
            }}
        />
    );
};

export default DatePicker;
