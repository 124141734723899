import { Dialog, DialogContent } from '@mui/material';
import { Descriptor, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import React, { PropsWithChildren, useState } from 'react';
import DialogPageHeader from './DialogPageHeader';
import { useMedia } from 'tamagui';

type Props = PropsWithChildren<{
    descriptor: Descriptor<NativeStackNavigationOptions, any, any>;
}>;

const WebDialogPage = (props: Props) => {
    const media = useMedia();
    const [isOpen, setIsOpen] = useState(true);

    const navigation = useNavigation();

    function handleClose() {
        setIsOpen(false);
        navigation.goBack();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                style: { width: media.md ? '70%' : '95%', height: '85vh', borderRadius: 8 },
            }}
            fullScreen
        >
            <DialogPageHeader onClose={handleClose} descriptor={props.descriptor} />

            <DialogContent sx={{ padding: 0 }}>{props.children}</DialogContent>
        </Dialog>
    );
};

export default WebDialogPage;
