import { abbreviation } from '@/shared/utils/number';
import { Silo } from '@/warehouse/entities/Silo';
import { Select, Stack, Text, useMedia, XStack } from '@alltis/ui';
import React from 'react';
import SiloFigure from '../../SiloFigure';

type Props = {
    silo: Silo;
};

const FullSiloInformation = ({ silo }: Props) => {
    const media = useMedia();

    const CurrentStorage = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Armazenamento Atual
            </Text>
            <Text fontWeight={700} fontSize={20} $md={{ fontSize: 32 }} color='#8C1913'>
                {abbreviation(silo.level.current.tons)} t
            </Text>
        </Stack>
    );

    const AverageConsumption = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Consumo Médio
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                {abbreviation(silo.level.consumption.average.tons || 0)} t/dia
            </Text>
        </Stack>
    );

    const Autonomy = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Autonomia
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                + {silo?.level.remaining.days} dias
            </Text>
        </Stack>
    );

    const RationType = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Tipo de Ração
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                <Select items={[]} label='Postura 2' value={[]} />
            </Text>
        </Stack>
    );

    const Temperature = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Temperatura
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                {silo.temperature} °C
            </Text>
        </Stack>
    );

    const Interval = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Intervalo Abastec. Médio
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                3.4 dias
            </Text>
        </Stack>
    );

    const Figure = (
        <SiloFigure
            silo={silo}
            dimensions={
                media.md
                    ? undefined
                    : {
                          height: 280,
                          width: 150,
                      }
            }
        />
    );

    if (media.md) {
        return (
            <XStack>
                <Stack>
                    {CurrentStorage}

                    <Stack my={32}>{AverageConsumption}</Stack>

                    {Autonomy}
                </Stack>

                <Stack mx={20}>{Figure}</Stack>

                <Stack width='25%'>
                    {RationType}

                    <Stack my={32}>{Temperature}</Stack>

                    {Interval}
                </Stack>
            </XStack>
        );
    }

    return (
        <XStack flex={1} mt={32}>
            <Stack flex={1}>{Figure}</Stack>

            <Stack gap={20} flex={1}>
                {RationType}

                {CurrentStorage}

                {AverageConsumption}

                {Autonomy}

                {Temperature}

                {Interval}
            </Stack>
        </XStack>
    );
};

export default FullSiloInformation;
