import { RANGE_OPTIONS } from '@legacy/sense/constants/rangeOptions';
import { SenseMetrics } from '@legacy/sense/entities/Sense';
import { useChartWidth } from '@legacy/shared/components/ui/ScrollableChart/useChartWidth';
import { formatChartValue } from '@legacy/shared/utils/charts';
import dayjs from 'dayjs';
import React from 'react';
import { Dimensions } from 'react-native';
import { VictoryAxis, VictoryChart, VictoryLegend, VictoryLine, VictoryScatter, VictoryTheme } from 'victory-native';
import { senseChartAxis } from './base/SenseChartAxis';

type Props = {
    metrics: Pick<SenseMetrics, 'co2' | 'timestamp'>[];
};

const SenseCO2Chart = (props: Props) => {
    const chartWidth = useChartWidth();

    return (
        <VictoryChart
            width={chartWidth}
            theme={VictoryTheme.material}
            domainPadding={{
                x: Dimensions.get('screen').width / 40,
                y: 20,
            }}
            // minDomain={{
            //     y: RANGE_OPTIONS.co2.range[0],
            // }}
            // maxDomain={{
            //     y: RANGE_OPTIONS.co2.range[1],
            // }}
        >
            <VictoryLegend
                orientation='horizontal'
                x={40}
                data={[{ name: 'Nível CO²', symbol: { fill: '#E9AD5B' } }]}
                style={{ labels: { fontSize: 12 } }}
            />

            <VictoryLine
                data={props.metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.co2,
                }))}
                labels={({ datum }) => formatChartValue(datum.y, { prefix: 'ppm', abbr: true })}
                style={{ data: { stroke: '#E9AD5B30' } }}
            />

            <VictoryScatter
                data={props.metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.co2,
                }))}
                labels={() => null}
                style={{ data: { fill: '#a47d46' } }}
            />

            <VictoryAxis
                // @ts-ignore
                data={props.metrics.map((metric) => ({
                    x: dayjs(metric.timestamp).format('DD/MM'),
                    y: metric.co2,
                }))}
                labels={({ datum }) => formatChartValue(datum.y, { prefix: 'ªc' })}
                dependentAxis
                offsetY={100}
                tickFormat={(value) => formatChartValue(value, { abbr: true })}
            />

            {senseChartAxis.x}
        </VictoryChart>
    );
};

export default SenseCO2Chart;
