import { useSelectedFarm } from '@/user/store/SelectedFarmStore';
import { QueryObserverOptions, QueryOptions, useQuery as useDefaultQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Options<T> = QueryOptions<T> & QueryObserverOptions<T>;

export function useQuery<TData>(options: Options<TData>) {
    const selectedFarm = useSelectedFarm();

    const queryKey = useMemo(() => {
        if (Array.isArray(options.queryKey)) {
            return [...options.queryKey, { selectedFarm }];
        }
    }, [selectedFarm, options.queryKey]);

    return useDefaultQuery({ ...options, queryKey });
}
