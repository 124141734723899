import { Icon } from '@/alltis-ui-v2';
import { MenuView } from '@/shared/components/ui/MenuView';
import Spinner from '@/shared/components/ui/Spinner';
import { useErrorHandler } from '@/shared/hooks/useErrorHandler';
import { Ration } from '@/warehouse/api/types/FeedResponse';
import { useDeleteFeed } from '@/warehouse/hooks/feed/useDeleteFeed';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Stack } from 'tamagui';

enum FeedAction {
    Delete = 'Delete',
    Edit = 'Edit',
}

type Props = {
    feed: Ration;
};

const FeedMenu = ({ feed }: Props) => {
    const navigation = useNavigation();
    const errorHandler = useErrorHandler();

    const deleteFeed = useDeleteFeed();

    function onDeleteFeed() {
        deleteFeed
            .mutateAsync({
                feedId: feed.id,
            })
            .catch(errorHandler.handle);
    }

    function onEditFeed() {
        navigation.navigate('FeedRegister', { rationId: feed.id });
    }

    if (deleteFeed.isLoading || deleteFeed.isSuccess) {
        return <Spinner color='#376A7B' size='small' />;
    }

    return (
        <Stack>
            <MenuView
                title='Opções da Ração'
                actions={[
                    {
                        id: FeedAction.Edit,
                        title: 'Editar ração',
                    },
                    {
                        id: FeedAction.Delete,
                        title: 'Apagar ração',
                        attributes: {
                            destructive: true,
                        },
                    },
                ]}
                isAnchoredToRight
                style={{ alignSelf: 'flex-start' }}
                onPressAction={({ nativeEvent: { event } }) => {
                    if (event === FeedAction.Edit) {
                        return onEditFeed();
                    }

                    if (event === FeedAction.Delete) {
                        return onDeleteFeed();
                    }
                }}
            >
                <Icon name='horizontal-dots' size={16} color='#376A7B' />
            </MenuView>
        </Stack>
    );
};

export default FeedMenu;
