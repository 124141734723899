import { useAuth } from '@/authentication/store/AuthStore';
import { useToast } from '@/shared/hooks/useToast';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { config } from '../../../config/env';
import { metricFormToPayload } from '../mappers/metricFormToPayload';
import { ReportMetricResultParams } from '../screens/ReportMetricResult/params';
import { ExportMetricsInput } from './useMetricResults';
import { useOpenFile } from './useOpenFile';
import { useSaveFile } from './useSaveFile';

type Input = {
    format: 'xlsx' | 'pdf';
    params: ReportMetricResultParams;
};

export function useExportMetricsFile() {
    const toast = useToast();
    const saveFile = useSaveFile();
    const openFile = useOpenFile();

    const { auth } = useAuth();

    /** Fetches the file from the alltis API */
    async function fetchFileV2(params: ReportMetricResultParams, format: Input['format']) {
        return alltisApiV2.get<ExportMetricsInput, AxiosResponse<Blob>>('/metrics/export', {
            params: {
                ...metricFormToPayload(params),
                format,
            },
            responseType: 'blob',
        });
    }

    /** Fetches the PDF file from PDF service */
    async function fetchPDF(params: ReportMetricResultParams) {
        const payload = {
            ...params,
            token: auth?.token,
            path: '/metrics/pdf',
        };

        // if (__DEV__) {
        //     Linking.openURL('http://192.168.1.100:19006/metrics/pdf?' + qs.stringify(payload, { arrayFormat: 'comma' }));
        // }

        return axios.post<Blob>('/report/export', payload, { baseURL: config.baseUrl, responseType: 'blob' });
    }

    return useMutation({
        mutationKey: ['export-metrics-file'],
        mutationFn: async (input: Input) => {
            let responseBlob: Blob;

            if (input.format === 'pdf') {
                responseBlob = (await fetchPDF(input.params)).data;
            } else {
                responseBlob = (await fetchFileV2(input.params, input.format)).data;
            }

            return saveFile.handleSave(responseBlob, input.format);
        },

        onSuccess(filePath, input) {
            openFile.open(filePath, input.format);

            toast.show({
                title: 'Sucesso',
                message: 'O arquivo foi salvo',
                type: 'success',
            });
        },

        onError() {
            toast.show({
                title: 'Erro ao exportar arquivo',
                type: 'error',
                message: 'Tente novamente mais tarde',
            });
        },
    });
}
