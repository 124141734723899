import { Aqua } from '@/aqua/entities/Aqua';
import { SiloSite } from '@/warehouse/entities/Silo';
import { LineChart, PageList } from '@alltis/ui';
import React from 'react';

type Props = {
    bird: Aqua;
    site?: SiloSite;
};

const BirdCard = ({ bird }: Props) => {
    return (
        <PageList.Card>
            <PageList.CardHeader
                title={bird.name}
                startContent={{
                    title: 'Média de mortes',
                    quantity: {
                        value: bird.consumption.daily_average,
                        suffix: 'aves/semana',
                    },
                }}
                endContent={{
                    title: `63 semanas de vida`,
                    quantity: {
                        value: 42.632,
                    },
                    caption: 'Aves',
                }}
                icon='chicken'
                isDanger={false}
            />

            <PageList.CardContent>
                <LineChart data={bird.consumption} />
            </PageList.CardContent>
        </PageList.Card>
    );
};

export default BirdCard;
