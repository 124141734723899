import { useAuth } from '@/authentication/store/AuthStore';
import SettingFieldScreen, { SettingScreenRouteProps } from '@/user/screens/SettingFieldScreen';
import NavigationTheme from '../config/theme/NavigationTheme';
import GuestStack from './stacks/Guest/GuestStack';
import UserStack from './stacks/User/UserStack';

import PrivacyPolicy from '@/authentication/screens/PrivacyPolicy';
import ReportExport, { ReportExportScreenProps } from '@/reports/screens/ReportExport';
import { useTesterSettingsEnabled } from '@/user/hooks/flags/useTesterSettingsEnabled';
import { Platform } from 'react-native';
import { createAppStackNavigator } from './helpers/createAppStackNavigator';
import { ReportMetricResultParams, ReportMetricsPDF } from '@/metrics';

export type AppStackParamsList = {
    Main: undefined;
    PrivacyPolicy: undefined;
    SettingFieldScreen: SettingScreenRouteProps;
    ReportExport: ReportExportScreenProps;
    ReportMetricsPDF: ReportMetricResultParams;
};

export const AppStack = createAppStackNavigator<AppStackParamsList>();

export function AppStackNavigator() {
    useTesterSettingsEnabled();

    const { auth } = useAuth();

    return (
        <NavigationTheme>
            <AppStack.Navigator
                initialRouteName='Main'
                screenOptions={{
                    headerTintColor: '#05455A',
                }}
            >
                <AppStack.Screen
                    name='Main'
                    component={!auth ? GuestStack : UserStack}
                    options={{
                        headerShown: false,
                    }}
                />

                <AppStack.Screen
                    name='PrivacyPolicy'
                    component={PrivacyPolicy}
                    options={{
                        presentation: 'modal',
                        headerTitle: 'Política de Privacidade',
                    }}
                />

                <AppStack.Screen
                    name='SettingFieldScreen'
                    component={SettingFieldScreen}
                    options={({ route }) => ({
                        presentation: 'modal',
                        headerTitle: route.params.toolbarTitle || route.params.title,
                    })}
                />

                {Platform.OS === 'web' && (
                    <AppStack.Screen name='ReportExport' component={ReportExport} options={{ headerShown: false }} />
                )}

                <AppStack.Screen name='ReportMetricsPDF' component={ReportMetricsPDF} options={{ headerShown: false }} />
            </AppStack.Navigator>
        </NavigationTheme>
    );
}
