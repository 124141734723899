import { Database } from '@nozbe/watermelondb';

import { Q, tableSchema } from '@nozbe/watermelondb';

import { CameraRecordModel } from '@legacy/camera-video/db/models/CameraRecordModel';
import { ServerConfigModel } from '@legacy/chicken-house/db/server-config/ServerConfigModel';

import adapter from './adapter';

const database = new Database({
    adapter,
    modelClasses: [CameraRecordModel, ServerConfigModel],
});

export type Schema = ReturnType<typeof tableSchema>;

export const getDatabase = () => database;

export { Q };
