import { useErrorHandler } from '@legacy/shared/hooks/useErrorHandler';
import { useMutation } from '@tanstack/react-query';
import { container } from '../../../config/di';
import { SiloApi } from '../api/SiloApi';
import { useSiloCacheHelper } from './helpers/useSiloCacheHelper';

type UpdateTonsVisibilityParams = {
    siloId: string;
    showTons: boolean;
};

export function useUpdateTonsVisibility() {
    const siloCache = useSiloCacheHelper();

    const siloApi = container.resolve(SiloApi);

    const errorHandler = useErrorHandler();

    return useMutation({
        mutationFn: async ({ siloId, showTons }: UpdateTonsVisibilityParams) => {
            await siloApi.updateSiloProperties(siloId, { prefer_tons: showTons });
        },
        onMutate({ siloId: deviceId, showTons }) {
            siloCache.updateById(deviceId, { prefer_tons: showTons });
        },
        onError(err) {
            errorHandler.handle(err, {
                title: 'Erro ao atualizar as opções',
                message: 'Tente novamente',
            });
        },
    });
}
