import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Close = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 32 32'>
            <Path
                fill={svg.color}
                fillRule='evenodd'
                d='M14.5866 15.9999L8.92984 21.6566L10.3441 23.0708L16.0008 17.4141L21.6576 23.0709L23.0718 21.6566L17.415 15.9999L23.072 10.3429L21.6578 8.92871L16.0008 14.5856L10.3439 8.92873L8.92969 10.3429L14.5866 15.9999Z'
                clipRule='evenodd'
            />
        </BaseSvg>
    );
};

export default { name: 'close', icon: Close } as const;
