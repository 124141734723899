import FilterIndicator from '@legacy/shared/components/ui/Filters/Base/FilterIndicator';
import { useSelectedFarm } from '@legacy/user/store/SelectedFarmStore';
import React, { memo, useMemo } from 'react';
import { Stack } from 'tamagui';
import { useFlag } from '../../../../config/feature-flag';
import FarmSelector from '../FarmSelector';

const SelectFarmButton = () => {
    const store = useSelectedFarm();

    const label = useMemo(() => {
        if (!store.farm && !store.aviary) {
            return 'Todas as granjas';
        }

        if (store.farm && store.aviary) {
            return `${store.farm.name} / ${store.aviary.name}`;
        }

        return store.farm?.name || store.aviary?.name;
    }, [store.farm, store.aviary]);

    const showFarmSelectionButton = useFlag('HEADER_FARM_SELECTION_BUTTON', false);

    if (!showFarmSelectionButton) return null;

    return (
        <Stack jc='center'>
            <FarmSelector>
                <FilterIndicator
                    label={label || ''}
                    stackProps={{
                        py: 0,
                        height: 33,
                        bg: 'transparent',
                        borderColor: '$secondary900',
                    }}
                />
            </FarmSelector>
        </Stack>
    );
};

export default memo(SelectFarmButton);
