import dayjs from 'dayjs';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';
import { getReportsDirectory } from '../../../config/file-system';

export function useSaveFile() {
    async function handleSave(fileBlob: Blob, ext: string) {
        const filename = `Relatorio ${dayjs().format('DD MM YYYY HH mm ss')}.${ext}`.replaceAll(' ', '-');

        if (Platform.OS === 'web') {
            const url = URL.createObjectURL(fileBlob);

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = filename;
            document.body.appendChild(a);

            a.click();

            return url;
        }

        const base64 = await readFile(fileBlob);

        const reportFileUri = await getReportsDirectory(filename);

        await FileSystem.writeAsStringAsync(reportFileUri, base64.split(',')[1], {
            encoding: FileSystem.EncodingType.Base64,
        });

        return reportFileUri;
    }

    function readFile(blob: Blob) {
        return new Promise<string>((resolve, reject) => {
            const fr = new FileReader();

            fr.onload = () => {
                resolve(fr.result as string);
            };

            fr.onerror = reject;

            fr.readAsDataURL(blob);
        });
    }

    return { handleSave };
}
