import { CameraSource } from '@legacy/camera-video/entities/CameraSource';
import { useRecordStore } from '@legacy/camera-video/store/useRecordStore';
import { Sheet } from '@legacy/shared/components/ui/Sheet';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import React, { RefObject } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Portal } from 'tamagui';
import CameraVideo from '../CameraVideo';

type Props = {
    source: CameraSource | undefined;
    allowRecording?: boolean;
    bottomSheetRef: RefObject<Sheet>;
    onClose: () => void;
};

const CameraVideoSheet = ({ bottomSheetRef, source, onClose, allowRecording = true }: Props) => {
    const { bottom } = useSafeAreaInsets();

    const height = allowRecording ? 450 : 340;

    const isRecording = useRecordStore((store) => store.isRecording);

    return (
        <Portal>
            <Sheet
                ref={bottomSheetRef}
                snapPoints={[bottom + height]}
                index={-1}
                enablePanDownToClose={!isRecording}
                backdropComponent={(props) => (
                    <BottomSheetBackdrop
                        {...props}
                        pressBehavior={isRecording ? 'none' : 'close'}
                        appearsOnIndex={0}
                        disappearsOnIndex={-1}
                    />
                )}
                backgroundStyle={{ backgroundColor: '#ffffff' }}
                onClose={() => onClose?.()}
                keyboardBlurBehavior='restore'
                keyboardBehavior='extend'
                animationConfigs={{
                    damping: 800,
                    stiffness: 500,
                }}
            >
                {source?.url && (
                    <CameraVideo
                        onRecordSuccess={() => bottomSheetRef.current?.forceClose()}
                        url={source.url}
                        hideRecordButton={!allowRecording}
                    />
                )}
            </Sheet>
        </Portal>
    );
};

export default CameraVideoSheet;
