import { Aqua } from '@legacy/aqua/entities/Aqua';
import SectionContainer from '@legacy/shared/components/ui/SectionContainer';
import React from 'react';
import { XStack } from 'tamagui';
import AquaIcon from '../../AquaIcon';

type Props = {
    aqua: Aqua;
};

const AquaDetailsHeader = ({ aqua }: Props) => {
    return (
        <SectionContainer title='Nível atual' containerProps={{ bg: '#ffffff', p: 0, h: 250 }}>
            <XStack flex={1}>
                <XStack flex={1} justifyContent='center' alignItems='center'>
                    <AquaIcon
                        svgProps={{
                            style: { marginLeft: -10 },
                        }}
                        value={aqua.consumption.today}
                    />
                </XStack>
            </XStack>
        </SectionContainer>
    );
};

export default AquaDetailsHeader;
