import { RTSPRecorderService } from '@legacy/camera-video/services/rtsp/RTSPRecorderService';
import { create } from 'zustand';
import { container } from '../../../config/di';
import { CameraRecordRepository } from '../db/repository/CameraRecordRepository';
import { getDatabase } from '../../../config/database';
import { queryClient } from '../../../config/cache/queryClient';
import { dispatch } from 'use-bus';

type RecordStore = {
    executionId: string | null;
    isRecording: boolean;
    timelapsed: number;
    status: 'starting' | 'stopping' | 'recording' | 'stopped';
    startRecording: (url: string) => Promise<void>;
    stopRecording: () => Promise<void>;
};

const rmptRecorderService = new RTSPRecorderService();

let stopwatchInterval: NodeJS.Timer;

export const useRecordStore = create<RecordStore>((set, get) => ({
    executionId: null,
    isRecording: false,
    timelapsed: 0,
    status: 'stopped',

    async startRecording(url: string) {
        set({ status: 'starting' });

        const recording = await rmptRecorderService.startRecording(url);

        set({
            isRecording: true,
            timelapsed: 0,
            executionId: recording,
        });

        setTimeout(() => {
            set({ status: 'recording' });
        }, 1000);

        stopwatchInterval = setInterval(() => {
            set((store) => ({ timelapsed: store.timelapsed + 100 }));
        }, 1000);
    },

    async stopRecording() {
        set({ status: 'stopping' });

        const store = get();
        const db = getDatabase();

        const recordingPath = await rmptRecorderService.stopRecordingAndSaveFile(store.executionId as string);

        if (stopwatchInterval) {
            // @ts-ignore
            clearInterval(stopwatchInterval);
        }

        db.write(async () => {
            await container.resolve(CameraRecordRepository).create({
                corePlaceId: '1',
                createdAt: new Date(),
                duration: store.timelapsed,
                path: recordingPath,
            });
        });

        queryClient.refetchQueries(['cameraRecordings']);

        dispatch({ type: '@record/finish' });

        set({
            isRecording: false,
            executionId: null,
            timelapsed: 0,
            status: 'stopped',
        });
    },
}));
