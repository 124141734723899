import React from 'react';
import { AppStack } from '../AppStack';
import Login from '@legacy/authentication/screens/Login';
import Menu from '@legacy/user/components/Profile/Menu';

export type GuestStackParams = {
    Login: undefined;
    Settings: undefined;
};

const GuestStack = () => {
    return (
        <AppStack.Group>
            <AppStack.Screen
                name='Login'
                component={Login}
                options={{
                    headerShown: false,
                    headerTitleAlign: 'center',
                }}
            />

            <AppStack.Screen
                name='Settings'
                component={Menu}
                options={{
                    headerTitle: 'Configurações',
                }}
            />
        </AppStack.Group>
    );
};

export default GuestStack;
