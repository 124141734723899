import { Aqua } from '@/aqua/entities/Aqua';
import AquaDetails from '@/aqua/screens/AquaDetails';
import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import EggsReport from '@/chicken-house/screens/EggsReport';
import PlaceEditor from '@/chicken-house/screens/PlaceEditor';
import PlaceScan from '@/chicken-house/screens/PlaceScan';
import { ReportMetricResult, ReportMetricResultParams } from '@/metrics';
import { SenseReport } from '@/sense/screens/SenseReport';
import { SiloDetails } from '@/warehouse';
import { Silo } from '@/warehouse/entities/Silo';
import FeedManager from '@/warehouse/screens/FeedManager';
import FeedRegister from '@/warehouse/screens/FeedRegister';
import { SiloReport } from '@/warehouse/screens/SiloReport';
import WarehouseDetails from '@/warehouse/screens/WarehouseDetails';
import MaterialIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack } from 'tamagui';
import { createUserNavigator } from '../../helpers/createAppStackNavigator';
import { MainDrawer } from './Main/MainDrawer';
import DialogPageHeader from '@/alltis-ui-v2/navigation/components/WebDialogPage/DialogPageHeader';

export type UserStackParams = {
    Home: undefined;
    PlaceEditor: undefined;

    /**
     * @deprecated
     */
    WarehouseDetails: {
        silo: Pick<Silo, 'id' | 'name'>;
    };

    SiloDetails: {
        id: string;
    };

    WarehouseReport: {
        silo?: Pick<Silo, 'id' | 'name'>;
    };
    EggsReport: {
        chickenHouse?: Pick<ChickenHouseInfo, 'id' | 'name'>;
    };
    SenseReport: {
        senseId: string;
    };
    FeedManager: undefined;
    FeedRegister?: {
        rationId: string;
    };
    PlaceScan?: {
        from?: keyof UserStackParams;
    };
    AquaDetails: {
        aqua: Pick<Aqua, 'id' | 'name'>;
    };
    ReportMetricResult: ReportMetricResultParams;
};

const User = createUserNavigator<UserStackParams>();

const UserStack = () => {
    return (
        <User.Navigator
            screenOptions={() => ({
                header: (props) => <DialogPageHeader descriptor={props} />,
            })}
            initialRouteName='Home'
        >
            <User.Screen name='Home' component={MainDrawer} options={{ headerShown: false }} />

            <User.Screen
                name='WarehouseDetails'
                component={WarehouseDetails}
                options={({ route }) => ({
                    title: route.params.silo.name,
                    headerBackVisible: true,
                    headerShadowVisible: false,
                    presentation: 'modal',
                })}
            />

            <User.Screen
                name='SiloDetails'
                component={SiloDetails}
                options={() => ({
                    title: 'Detalhes do Silo',
                    headerBackVisible: true,
                    headerShadowVisible: false,
                    presentation: 'modal',
                })}
            />

            <User.Screen
                name='FeedManager'
                component={FeedManager}
                options={() => ({
                    title: 'Tipos de ração',
                    presentation: 'modal',
                })}
            />

            <User.Screen
                name='FeedRegister'
                component={FeedRegister}
                options={({ route }) => ({
                    title: route?.params?.rationId ? 'Editar ração' : 'Novo tipo de ração',
                    presentation: 'modal',
                })}
            />

            <User.Screen
                name='PlaceEditor'
                component={PlaceEditor}
                options={({ navigation }) => ({
                    title: 'Configurar aviários',
                    presentation: 'card',
                    headerBackVisible: true,
                    headerRight: () => (
                        <Stack>
                            <TouchableOpacity
                                onPress={() =>
                                    navigation.navigate('PlaceScan', {
                                        from: 'PlaceEditor',
                                    })
                                }
                                accessibilityLabel='Configurar rede'
                            >
                                <MaterialIcons name='cog' size={22} color='#000000' />
                            </TouchableOpacity>
                        </Stack>
                    ),
                })}
            />

            <User.Screen
                name='PlaceScan'
                component={PlaceScan}
                options={() => ({
                    title: 'Configurar rede local',
                    headerBackVisible: true,
                })}
            />

            <User.Screen
                name='WarehouseReport'
                component={SiloReport}
                options={() => ({
                    title: 'Relatório de Silo',
                })}
            />

            <User.Screen
                name='EggsReport'
                component={EggsReport}
                options={() => ({
                    title: 'Relatório de Ovos',
                })}
            />

            <User.Screen
                name='SenseReport'
                component={SenseReport}
                options={() => ({
                    title: 'Relatório de Ambiência',
                    presentation: 'modal',
                })}
            />

            <User.Screen
                name='AquaDetails'
                getComponent={() => AquaDetails}
                options={({ route }) => ({
                    title: route.params.aqua.name,
                    headerBackVisible: true,
                    headerShadowVisible: false,
                    presentation: 'modal',
                })}
            />

            <User.Screen
                name='ReportMetricResult'
                component={ReportMetricResult}
                options={() => ({
                    headerBackVisible: true,
                    headerShadowVisible: false,
                    presentation: 'modal',
                    headerTitle: 'Relatório dos Aviários',
                })}
            />
        </User.Navigator>
    );
};

export default UserStack;
