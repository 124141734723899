import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const User = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props} viewBox='0 0 20 22' fill='none'>
            <Path
                d='M18.5901 21C18.5901 17.13 14.7402 14 10.0002 14C5.26015 14 1.41016 17.13 1.41016 21M15.0002 6C15.0002 8.76142 12.7616 11 10.0002 11C7.23873 11 5.00015 8.76142 5.00015 6C5.00015 3.23858 7.23873 1 10.0002 1C12.7616 1 15.0002 3.23858 15.0002 6Z'
                stroke={svg.color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </BaseSvg>
    );
};

export default { name: 'user', icon: User } as const;
