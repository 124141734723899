import AquaDetailsHeader from '@legacy/aqua/components/AquaDetails/AquaDetailsHeader';
import AquaDetailsInformation from '@legacy/aqua/components/AquaDetails/AquaDetailsInformation';
import AquaViewState from '@legacy/aqua/components/AquaViewState';
import { useAquaById } from '@legacy/aqua/hooks/useAquaById';
import HeavyScreen from '@legacy/shared/components/ui/HeavyScreen';
import LoadingScreen from '@legacy/shared/components/ui/LoadingScreen';
import { useStatusBar } from '@legacy/shared/hooks/useStatusBar';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useAquaId } from '../Aqua/hooks/useAquaID';

const AquaDetails = () => {
    useStatusBar('dark-content');

    const aquaId = useAquaId();

    const { data, isLoading } = useAquaById(aquaId);

    if (isLoading || !aquaId || !data) {
        return <AquaViewState isEmpty={!aquaId || !data} loading={isLoading} />;
    }

    return (
        <HeavyScreen placeholder={<LoadingScreen />}>
            <ScrollView>
                <AquaDetailsHeader aqua={data.aqua} />

                <AquaDetailsInformation aqua={data.aqua} />
            </ScrollView>
        </HeavyScreen>
    );
};

export default AquaDetails;
