import { ChickenHouseInfo } from '@legacy/chicken-house/entities/ChickenHouseInfo';
import { MenuView } from '@legacy/shared/components/ui/MenuView';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Stack, StackProps } from 'tamagui';
import { useLargeLayout } from '../../../../../hooks/useLargeLayout';
import { useEggManager } from '@legacy/chicken-house/store/useEggManagerStore';

enum ChickenHouseAction {
    GenerateReport = 'GenerateReport',
    OpenCamera = 'OpenCamera',
}

type Props = {
    chickenHouse: Pick<ChickenHouseInfo, 'name' | 'id' | 'cameraSource'>;
    onOpenCameraPress?: () => void;
    stackProps?: StackProps;
};

const ChickenHouseCardActions = ({ chickenHouse, stackProps, onOpenCameraPress }: Props) => {
    const navigation = useNavigation();
    const isLargeScreen = useLargeLayout();
    const eggManager = useEggManager();

    function onGenerateReportPress() {
        if (isLargeScreen) {
            eggManager.onSelectChickenHouse(chickenHouse.id);
            eggManager.onTabChanged('reports');

            return;
        }

        return navigation.navigate('EggsReport', { chickenHouse });
    }

    function handleActionPress(event: ChickenHouseAction) {
        if (event === ChickenHouseAction.GenerateReport) {
            return onGenerateReportPress();
        }

        if (event === ChickenHouseAction.OpenCamera) {
            return onOpenCameraPress?.();
        }
    }

    return (
        <Stack {...stackProps}>
            <MenuView
                style={{ alignSelf: 'flex-start' }}
                actions={[
                    {
                        id: ChickenHouseAction.OpenCamera,
                        title: 'Abrir câmera',
                        attributes: {
                            disabled: !chickenHouse.cameraSource?.url,
                        },
                    },
                    {
                        id: ChickenHouseAction.GenerateReport,
                        title: 'Gerar relatório',
                    },
                ]}
                isAnchoredToRight
                title='Opções dos Ovos'
                onPressAction={({ nativeEvent: { event } }) => handleActionPress(event as ChickenHouseAction)}
            >
                <Stack>
                    <MaterialIcons name='more-vert' size={24} />
                </Stack>
            </MenuView>
        </Stack>
    );
};

export default ChickenHouseCardActions;
