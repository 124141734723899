import { create } from 'zustand';

export type SiloTab = 'silos' | 'maps' | 'reports';

type SiloPanelStore = {
    /**
     * Current silo tab
     */
    currentTab: SiloTab;

    /**
     * Selected working silo
     */
    selectedSiloId: string | null;

    /**
     * On select specific silo
     *
     * @param siloId - Silo identification
     */
    onSelectSilo(siloId: string): void;

    /**
     * On request report tab
     *
     * @param siloId - Silo identification
     */
    onReportTabRequested(siloId?: string): void;

    /**
     * On tab change
     *
     * @param tab - New tab
     */
    onTabChanged(tab: SiloTab): void;
};

export const useSiloPanel = create<SiloPanelStore>((set) => ({
    currentTab: 'silos',
    selectedSiloId: null,
    onReportTabRequested(siloId) {
        set({ currentTab: 'reports', selectedSiloId: siloId });
    },
    onSelectSilo(siloId) {
        set({ selectedSiloId: siloId });
    },
    onTabChanged(tab) {
        set({ currentTab: tab });
    },
}));
