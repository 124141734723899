import React from 'react';

import { Icon, Stack, useMedia } from '@alltis/ui';

const DefaultAvatar = () => {
    const media = useMedia();

    if (media.md) {
        return (
            <Stack height={38} width={38} jc='center' ai='center' bg='#F5F5F5' borderRadius={100}>
                <Icon name='user' color='#376A7B' size={20} />
            </Stack>
        );
    }

    return <Icon name='user' color='#588290' size={22} />;
};

export default DefaultAvatar;
