import * as React from 'react';
import { Path } from 'react-native-svg';
import BaseSvg from '../components/BaseSvg';
import { useSvgProps } from '../hooks/useSvgProps';
import { IconProps } from '../types';

const Menu = (props: IconProps) => {
    const svg = useSvgProps(props);

    return (
        <BaseSvg {...props}>
            <Path fill={svg.color} d='M3 18v-2h18v2H3Zm0-5v-2h18v2H3Zm0-5V6h18v2H3Z' />
        </BaseSvg>
    );
};

export default { name: 'menu', icon: Menu } as const;
