import React from 'react';
import { Line, Polygon } from 'react-native-svg';
import { SiloPartProps } from './props';
import { constants } from './constants';

export const SiloCeil = (props: SiloPartProps) => {
    const height = props.height || 50;
    const width = props.width || 105;

    function getPoints() {
        return [
            [width / 2, 0],
            [width, height],
            [0, height],
        ];
    }

    return (
        <>
            {/* Ceil Content */}
            <Polygon
                id='Vector_2'
                points={getPoints()
                    .map(([x, y]) => `${x},${y}`)
                    .join(' ')}
            />

            {/* Ceil Strokes */}
            <Line
                y1={height}
                y2={0}
                x1={0}
                x2={width / 2}
                strokeWidth={constants.silo.stroke.width}
                stroke={constants.silo.stroke.color}
            />

            <Line
                y1={0}
                y2={height}
                x1={width / 2}
                x2={width}
                strokeWidth={constants.silo.stroke.width}
                stroke={constants.silo.stroke.color}
            />
        </>
    );
};
