import { Icon, IconNames, Separator, Stack, Text } from '@alltis/ui';

import React, { PropsWithChildren } from 'react';

type Props = {
    title: string;
    icon: IconNames;
    isLast?: boolean;
};

const SettingSection = (props: PropsWithChildren<Props>) => {
    return (
        <Stack py={12}>
            <Stack>
                <Stack flexDirection='row' alignItems='center' width='100%'>
                    <Icon name={props.icon} size={24} color='#588290' />

                    <Text color='#8CA9B3' fontWeight={500} fontSize={16} ml={16}>
                        {props.title}
                    </Text>
                </Stack>

                <Stack $md={{ pl: 38 }} pt={18} gap={12}>
                    {props.children}
                </Stack>
            </Stack>

            {!props.isLast && <Separator backgroundColor='#EBEBEA' mx={-32} mt={26} />}
        </Stack>
    );
};

export default SettingSection;
