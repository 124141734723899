import { EggsTab, useEggManager } from '@legacy/chicken-house/store/useEggManagerStore';
import { useTabController } from '@legacy/shared/hooks/useTabController';
import { useLargeLayout } from '../../../../../hooks/useLargeLayout';

export function useEggTabs() {
    const isLarge = useLargeLayout();

    const panel = useEggManager();

    const { routes, index, getRoute } = useTabController<EggsTab>(
        [
            { key: 'eggs', title: 'Aviários' },
            isLarge && { key: 'reports', title: 'Relatórios' },
            { key: 'recordings', title: 'Gravações' },
        ],
        {
            activeTab: panel.activeTab,
        }
    );

    const onIndexChange = (index: number) => panel.onTabChanged(getRoute(index));

    return {
        index,
        routes,
        onIndexChange,
    };
}
