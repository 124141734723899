import { useAquaPanel } from '@legacy/aqua/store /useAquaPanelStore';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AppStackParamsList } from '../../../../../navigation/AppStack';

export function useAquaId() {
    const { params } = useRoute<RouteProp<AppStackParamsList, 'AquaDetails'>>();
    const panelAquaId = useAquaPanel((state) => state.aquaId);

    return params?.aqua?.id || panelAquaId;
}
