import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import { Header, Sheet, Stack, useMedia } from '@alltis/ui';
import { Portal } from '@gorhom/portal';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { config } from '../../../../../config/env';
import { StreamPlayer } from '../StreamPlayer';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import { isWebOrTablet } from '../../../../../config/constants/webOrTablet';

type Props = {
    onClose: () => void;
    device: ChickenHouseInfo;
};

const LivePreviewSheet = (props: Props) => {
    const { bottom } = useSafeAreaInsets();

    const media = useMedia();

    const isTablet = Device.deviceType === Device.DeviceType.TABLET && media.md;

    return (
        <Portal>
            <Sheet
                animateOnMount
                snapPoints={[bottom + (isTablet ? 430 : 270)]}
                backgroundStyle={{ backgroundColor: '#000000' }}
                keyboardBlurBehavior='restore'
                handleIndicatorStyle={{
                    backgroundColor: '#282828',
                }}
                keyboardBehavior='extend'
                onClose={props.onClose}
            >
                {Platform.OS === 'web' ||
                    (isTablet && (
                        <Stack>
                            <Header title={props.device.name} onClose={props.onClose} />
                        </Stack>
                    ))}

                <Stack height={Platform.OS === 'web' ? 600 : 230} p={12} bg='#000000' flex={isTablet ? 1 : undefined}>
                    <StreamPlayer
                        source={{
                            uri: `${config.baseUrl}/api/egg-vision/live/${props.device.id}/index.m3u8`,
                        }}
                    />
                </Stack>
            </Sheet>
        </Portal>
    );
};

export default LivePreviewSheet;
