import { useMemo } from 'react';
import { useIsTablet, useLargeLayout } from '../../../../../hooks/useLargeLayout';

export function useDrawerConstants() {
    const isLarge = useLargeLayout();
    const isTablet = useIsTablet();

    const width = {
        collapsed: 64,
        expanded: 300,
    };

    const paddingX = useMemo(() => {
        if (isTablet) return width.collapsed / 4;

        if (isLarge) return 32;

        return 24;
    }, [isLarge, isTablet]);

    return {
        paddingX,
        width,
        isCollapsable: isTablet,
        enableCustomOverlay: isLarge || isTablet,
    };
}
