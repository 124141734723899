import { SiloLevelStatus } from '@legacy/warehouse/entities/WarehouseLevel';
import { useId } from 'react';
import { ClipPath, Defs, G, Rect, Svg } from 'react-native-svg';
import SiloContentPath from './SiloContentPath';
import { getPercentage } from './SiloContentPath/helpers';
import SiloPanels from './SiloPanels';
import { SiloBase } from './SiloParts/SiloBase';
import { SiloBody } from './SiloParts/SiloBody';
import { SiloCeil } from './SiloParts/SiloCeil';
import SiloPercentageFill from './SiloPercentageFill';
import SiloPercentageText from './SiloPercentageText';

type Props = {
    label: string;
    percentage: number;
    status?: SiloLevelStatus;
    numberOfPanels?: number;
    levelPoints: { x: number; y: number }[];
    proportions?: {
        ceil: number;
        body: number;
        base: number;
        diameter: number;
    };
};

const options = {
    svg: {
        height: 190,
    },
};

const DEFAULT_PROPORTIONS = {
    ceil: 20,
    body: 50,
    base: 30,
    diameter: 42,
};

export function WarehouseStateIcon({
    proportions = DEFAULT_PROPORTIONS,
    percentage,
    status,
    label,
    numberOfPanels = 0,
    levelPoints = [],
}: Props) {
    const clipPathId = useId();

    function getFill() {
        if (status === 'ALERT') {
            return '#e95b5b';
        }

        return '#E9AD5B';
    }

    const width = getPercentage(proportions.diameter || DEFAULT_PROPORTIONS.diameter, options.svg.height);

    const ceilHeight = getPercentage(proportions.ceil, options.svg.height);
    const bodyHeight = getPercentage(proportions.body, options.svg.height);
    const baseHeight = getPercentage(proportions.base, options.svg.height);

    const FullSilo = () => {
        return (
            <G>
                <SiloCeil height={ceilHeight} width={width + 4} />

                <G y={ceilHeight}>
                    <SiloBody height={bodyHeight} width={width + 4} />
                </G>

                <G y={ceilHeight + bodyHeight}>
                    <SiloBase height={baseHeight} width={width + 4} />
                </G>
            </G>
        );
    };

    const viewBoxHeight = options.svg.height + 30;

    return (
        <Svg
            viewBox={`0 -15 ${width} ${options.svg.height + 30}`}
            fill='none'
            height={options.svg.height}
            width={width + 30}
            key={percentage}
        >
            <Defs>
                <ClipPath id={clipPathId}>
                    {levelPoints.length >= 3 ? (
                        <SiloContentPath data={levelPoints} height={viewBoxHeight} width={width + 14} />
                    ) : (
                        <SiloPercentageFill percentage={percentage} width={width} />
                    )}
                </ClipPath>
            </Defs>

            <G fill='#cccccc' stroke='#cccccc' strokeWidth={7}>
                <FullSilo />
            </G>

            <G fill={getFill()} clipPath={`url(#${clipPathId})`}>
                <FullSilo />
            </G>

            <SiloPanels numberOfPanels={numberOfPanels} height={bodyHeight - 3} width={width + 4} y={ceilHeight + 2} />

            <SiloPercentageText x={width / 2 + 2} y={ceilHeight + bodyHeight / 1.7} label={label} />
        </Svg>
    );
}
